<footer class="footer-section">
    <section class="">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-xl-3 mx-auto branding-log">
            <img src="./../../../../assets/images/campaign-flow/footer-img.svg" alt="brand-logo" loading="lazy">
          <!-- <p>
            Here you can use rows and columns to organize your footer
            content. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit.
          </p> -->
        </div>

        <hr class="w-100 clearfix d-md-none" />
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
          <h6 class="mb-3 connects">Solutions</h6>
          <p *ngFor="let item of services; let i = index" class="company-services">
            <a class="service-text cursor-pointer" (click)="gotoService(item,i)">{{item?.name | titlecase}}</a>
          </p>
        </div>
        <hr class="w-100 clearfix d-md-none" />
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
          <h6 class="mb-3 connects">
            Company
          </h6>
          <p *ngFor="let item of company" class="company-services">
            <a class="service-text cursor-pointer" (click)="gotoCompany(item)">{{item?.name | titlecase}}</a>
          </p>
        </div>
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
            <h6 class="mb-3 connects">
              Resources
            </h6>
            <p *ngFor="let item of resources" class="company-services">
              <a class="service-text cursor-pointer" (click)="goToResources()">{{item?.name | titlecase}}</a>
            </p>
          </div>

        <hr class="w-100 clearfix d-md-none" />
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
          <h6 class="mb-4 connects">Connect</h6>
          <div class="social-link">
            <!-- <div *ngFor="let item of connect"> -->
                <!-- <a href="" class="connect-img">
                    <img [src]="item?.imgUrl" [alt]="item?.name" loading="lazy">
                </a> -->
                <a href="https://www.linkedin.com/company/startuplogin" class="connect-img" target="_blank">
                  <img src="./../../../../assets/images/campaign-flow/linked-in.svg" alt="linkedIn">
                </a>
                <a href="https://www.instagram.com/startuplogin/" class="connect-img" target="_blank">
                  <!-- <i class="fa fa-instagram fa-2x" aria-hidden="true"></i> -->
                  <img src="./../../../../assets/images/campaign-flow/insta.svg" alt="instagram">
                </a>
                <a href="https://www.facebook.com/startupexits" class="connect-img" target="_blank">
                  <img src="./../../../../assets/images/campaign-flow/facebook.svg" alt="facebook">
                </a>
                <a href="https://twitter.com/mystartuplogin" class="connect-img" target="_blank">
                  <img src="./../../../../assets/images/campaign-flow/fa_twitter.svg" alt="twitter">
                </a>
            <!-- </div> -->
          </div>
          <div class="subscribe-box">
            <div class="subscribe-heading">Join our list for growth insights and deal showcase for new startups every week</div>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Your Mail Here" aria-label="your mail here" aria-describedby="basic-addon2"
                [(ngModel)]="email">
                <div class="input-group-append" (click)="alertMe()">
                  <span class="input-group-text" id="basic-addon2">Subscribe</span>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="all-rights">
        ©2021 Startuplogin. All rights reserved
      </div>
    </section>
</footer>