<!-- <app-top-navbar></app-top-navbar> -->
<div class="main-section">
    <section class="exit-section">
        <div class="carousel-left">
            <div>
                <div class="bold-secondary-colored-text">Deal Feeder Program</div>
                <div class="small-secondary-dark-text">Register, Refer a startup and Earn Deal Feeding (community) by StartupLogin
                    </div>
                <div class="carousel-explore-btn">
                    <button (click)="goToForm(feederForm)">Apply Now</button>
                </div>
            </div>
        </div>
        <div class="carousel-right">
            <img src="./../../../assets/images/campaign-flow/deal_feeders.png" alt="deal feeders title image" loading="lazy">
        </div>
    </section>

    <div class="talk-expert-section">
        <img src="./../../../assets/images/exit/talk-expert-light-yellow.png" alt="talk-our expert" loading="lazy">
        <div class="expert-text-btn">
            <div class="expert-text">Do you know any <br> Start-ups looking for Exit?</div>
            <div class="mini-text">Refer us and get paid</div>
            <div class="expert-btn">
                <button (click)="refereSection()">Refer Now</button>
            </div>
        </div>
    </div>


    <section class="exit-rewarding">
        <div class="exit-rewarding-title">How it works?</div>
        <div class="reward-structure">
            <div class="single-structure" *ngFor="let reward of exitRewarding">
                <div class="structure-number">
                    {{reward?.imgUrl}}
                </div>
                <div class="structure-title">{{reward?.title}}</div>
                <div class="structure-sub-text">{{reward?.subText}}</div>
            </div>
        </div>
    </section>
    <section class="details-form" #feederForm>
        <div class="need-details-title">We just need a few details</div>
        <div class="need-details-form">
            <div class="fields">
                <div class="field">
                    <div class="field-name">Name <sup>*</sup></div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <input matInput placeholder="Enter Your Name" autocomplete="off" [(ngModel)]="name"/>
                    </mat-form-field>
                </div>
                <div class="field">
                    <div class="field-name">Mail Id <sup>*</sup></div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input matInput placeholder="Enter Your Mail" autocomplete="off" [(ngModel)]="email"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="fields">
                <div class="field">
                    <div class="field-name">Phone Number <sup>*</sup></div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <input matInput placeholder="Enter Your Name" autocomplete="off" [(ngModel)]="mobile"
                        type="number"
                        maxlength="10"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </mat-form-field>
                </div>
                <div class="field">
                    <div class="field-name">Message</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input matInput placeholder="Enter Your Mail" autocomplete="off" [(ngModel)]="message"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="check-fields">
                <mat-checkbox class="example-margin" [(ngModel)]="checkBox"
                ></mat-checkbox>
               <div class="check-text">
                When speaking to business owners/founders and/or their advisors as part of the Startup Login Deal Feeder Program, I will not represent that I am an employee, associate, or representative of Startup Login . I acknowledge that I do not have the legal authority to bind Startup Login or its
                 affiliates to contractual agreements of any kind, and will not represent to anyone that I have such authority.
               </div>
            </div>
            <div class="apply-btn">
                <button [disabled]="!checkBox || email === '' || mobile === '' || name === ''"
                (click)="apply()">Apply</button>
            </div>
        </div>
    </section>

</div>
<!-- <app-footer></app-footer> -->