<section class="main-section">
    <div class="choose-startup-section">
        <div class="choose-startup-title">
            <span class="first-colored-text">{{chooseTitle1 | titlecase}}</span>&nbsp;<span class="second-colored-text">{{chooseTitle2 | titlecase}}</span>
        </div>
        <div class="choose-startup-structure">
            <div class="single-structure" *ngFor="let choose of chooseArray">
                <div class="structure-img">
                    <img [src]="choose.imgUrl" alt="">
                </div>
                <div class="structure-title">
                    {{choose?.title}}
                </div>
                <div class="structure-sub-text">
                    {{choose?.subText}}
                </div>
            </div>
        </div>
    </div>
</section>