import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss','./mq-event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  constructor(
    private _router : Router
  ) { }

  blogId;
  ngAfterViewInit(): void {
    
  }

  details: any ;

  ngOnInit(): void {
    this.blogId = this._router.url.split('/')[2]
    // this.details = this.blogDetails[0]
    this.blogDetails.forEach(element => {
      if(element.blogId === this.blogId){
        this.details = element;
      }
    });
  }

  blogDetails = [
    {
      about:'Event',
      date:'Aug 8, 2022',
      readTime:5,
      blogId:'e001',
      title:'Eventbrite Women in Tech event',
      shortnote:"The gender gap in the tech community is a highly relevant topic that has been drawing a lot of rightful attention in the last few years. Startup Login recently got the opportunity to be a part of an insightful discussion in this regard, at the Eventbrite's Female Leadership & Women in Tech community in Hyderabad. The event was attended by our co-founder Sunita Kumari and community manager Amtul Ahad",
      p:[
        {
          btImgs:[
            {
              img:'./../../../assets/images/campaign-flow/women-atire-1.png'
            }
          ]
        },
        {
          a:"Aimed at promoting the equality of the female workforce in tech, the event hosted a detailed talk addressing how women can surpass hurdles to advance in their careers, and men can become active allies in the process.  Anita Alexander and Uma Avantsa were the guest speakers."
        },
        {
          a:"Touching upon the struggles working mothers go through, Anita explained how being a mother of two teenagers made her personal as well as professional life a roller coaster ride. She revealed that the mantra that kept her going was that it's okay to not be a perfectionist and to let go of a few things"
        },
        {
          a:"At Startup Login, we firmly believe that learning is a lifelong process. Hence, when Uma explained how she was still learning and would continue to take up courses to expand her knowledge, we truly resonated with that. She quipped that staying up to date in the field of technology was just as same as keeping up with the trends if one were a fashionista."
        }
      ]
    },
    {
      about:'Event',
      date:'Aug 8, 2022',
      readTime:5,
      blogId:'e002',
      title:'The Art of Business Valuation for Startups and SMEs Event',
      shortnote:"Here’s how ‘The Art of Business Valuation for Startups and SMEs’ hosted by Startup Login, in collaboration with eChai Ventures and IBGrid, went!",
      img:'./../../../assets/images/campaign-flow/art_of_business_4.png',
    },
    {
      about:'Event',
      date:'Aug 8, 2022',
      readTime:5,
      blogId:'e003',
      title:'Launch your startup in the UAE Event',
      shortnote:"Here’s how ‘Launch your startup in the UAE’ hosted by Startup Login, in collaboration with Telebu Communications, went!",
      p:[
        {
          btImgs:[
            {
              img:'./../../../assets/images/campaign-flow/uae_1.png'
            },
            {
              img:'./../../../assets/images/campaign-flow/uae_2.png'
            }
          ]
        },
        {
          a:"Highly engaging and enlightening, the event equipped the participants with the know-how of reaching the correct starting point to set up their business in the UAE. The Telebu team, along with Saeed Al Hamli, assured that they were ready to host the startups in Dubai and Bahrain, to further guide them."
        },
        {
          btImgs:[
            {
              img:'./../../../assets/images/campaign-flow/uae_3.png',
            },
            {
              img:'./../../../assets/images/campaign-flow/uae_4.png'
            }
          ]
        },
        {
          a:"“Instead of having a base in other countries and expanding to Dubai, a company benefits more by having a base in Dubai and expanding to other countries, due to the tax exemptions and network in UAE,” Telebu Group CEO Abdul Aziz Qambar told the participants. "
        }
      ]
    }
  ]

  gotoBlogDetail(item){
    this._router.navigate([`/blog/${item.replaceAll(/[^a-zA-Z ]/g,'').replaceAll(" ","-").toLowerCase()}}`]);
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    });
}
}
