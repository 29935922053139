import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DbServiceService } from '../../global-services/db-service.service';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-acquihire-details',
  templateUrl: './acquihire-details.component.html',
  styleUrls: ['./acquihire-details.component.scss','./mq-acquihire-details.component.scss']
})
export class AcquihireDetailsComponent implements OnInit {

  getAllLoader : boolean = false;

  techStack=[
    'NodeJS','React','Solidity','mongoDB'
  ]
  serviceOffered=[
    'Mobile Application','Web Application','Blockchain Development','API Services','Scaling up Applications','Project Management'
  ];
  teamMemebers=[
    {},{},{},{},{}
  ]
  comments = [
    'Ready to join immediately',
    'Hands-on experience on React Framework at least 10 years.',
    'Ready to join immediately'
  ]
  acquihire = 'updation'
  constructor(
    private _router : Router,
    private _snackBar : MatSnackBar,
    private _route : ActivatedRoute,
    private _globalService : GlobalService,
    private _dbService : DbServiceService
  ) { }

  response:any;
  pageNo : Number;
  ngOnInit(): void {
    this.getParams();
    this._route.queryParams.subscribe(
      (params) => {
        this.pageNo = params['pageNo'];
      }
    )
  }

  getParams(){
    this.getAllLoader = true;
    this._route.params.subscribe(
      (params)=>{
        // console.log(params[`displayId`]);
        this._globalService.getOneAcquihire(params[`displayId`]).subscribe(
          (res:any) => {
            console.log(res.data);   
            res.data.employees.forEach(element => {
              element.currentCtc = element.currentCtc / 100000;
              element.expectedCtc = element.expectedCtc / 100000;
            });
            this.response = res.data;
            this.getAllLoader = false;
          },
          (err) => {
            this._snackBar.open(
              `${err.error.message}`,
              'Close',
              {
                duration:1500
              }
            );
            this.getAllLoader = false;
          }
        )
      }
    )
  }

  scrollToMembers(el : HTMLElement){
    el.scrollIntoView();
  }
  
  backTo(){
    this._router.navigate([`${this._dbService.getPrevUrl()}`],{queryParams:{pageNo:this.pageNo}});
  }
  downloadSingleResume(){
    this._snackBar.open(
      `Please Login First for download resume`,
      'Close',
      {
        duration:1500
      }
    )
  }
  gotoLogin(){
    this._router.navigate(['/authentication'])
  }
  markInterested(){
    this._snackBar.open(
      'Please Login First',
      'Ok',
      {
        duration:1500
      }
    )
  }


}
