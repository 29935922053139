<div>
    <div class="top-navbar" *ngIf="!sidePanelBoolean">
        <app-top-navbar></app-top-navbar>
    </div>
    <div class="main-section">
        <div class="list-section" *ngIf="!getAllLoader">
            <div class="filter-part" *ngIf="!sidePanelBoolean">
                <div class="field">
                    <div class="label-heading">Location</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input type="text"
                            placeholder="Select Location"
                            matInput
                            [formControl]="locationControl"
                            [matAutocomplete]="auto"
                            (keyup)="getLocations()"
                            [(ngModel)]="locationSerach">
                            <mat-icon matSuffix>search</mat-icon>
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of locationOptions | async" [value]="option.cityName" (onSelectionChange)="selectLocation(option)">
                                {{option?.cityName | titlecase}}, {{option?.stateName | titlecase}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- <div class="field">
                    <div class="label-heading">Tech Stack</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <mat-select placeholder="Search for country" [(ngModel)]="technology">
                            <mat-select-filter [placeholder]="'Search For industry'" [displayMember]="'name'" [array]="technologyArray" (filteredReturn)="technologyList = $event"></mat-select-filter>
                            <mat-option *ngFor="let item of technologyList" [value]="item">
                                {{item.name | titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="field">
                    <div class="label-heading">Service Offered</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <mat-select placeholder="Search for country" [(ngModel)]="serviceOffered">
                            <mat-select-filter [placeholder]="'Search For industry'" [displayMember]="'name'" [array]="serviceArray" (filteredReturn)="serviceList = $event"></mat-select-filter>
                            <mat-option *ngFor="let item of serviceList" [value]="item">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div class="field">
                    <div class="label-heading">Team Size</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <mat-select placeholder="Search for country" [(ngModel)]="teamSize">
                            <mat-select-filter [placeholder]="'Search For industry'" [displayMember]="'name'" [array]="startuptypeArray" (filteredReturn)="startuptypeList = $event"></mat-select-filter>
                            <mat-option *ngFor="let item of startuptypeList" [value]="item.value">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="multiple-btns">
                    <div class="clear-btn">
                        <button (click)="clearFilter()">Clear</button>
                    </div>
                    <div class="apply-btn">
                        <button (click)="applyFilters()">
                            <span *ngIf="!filterLoader">Apply</span>
                            <span *ngIf="filterLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
                        </button>
                    </div>
                </div>
            </div>
            <div [ngClass]="!fromAcquihire ? 'list-parts' : 'from-list-part'">
                <div class="action-headertab" *ngIf="!fromAcquihire">
                    <div class="pagination-container">
                        <div class="pagination-count">
                            {{startingLeadNumber}} - {{endingLeadNumber}} of {{totalDbCount}}
                            <!-- 100001 - 110000 of 300000 -->
                        </div>
                        <div class="arrow-btns">
                            <button class="left-arrow" [disabled]="!activeLeftArrow" (click)="previousPage()">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                            </button>
                            <div class="pagination-input">
                              <mat-form-field class="pagination-sfull-width">
                                <input type="number" matInput [(ngModel)]="goPage" (keyup.enter)="goToPageNumber()"/>
                              </mat-form-field>
                            </div>
                            <button class="righti-arrow" [disabled]="!activeRightArrow" (click)="nextPage()">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                        <div class="pages-count">
                            {{presentPageNo}} of {{totalPages}} Pages
                        </div>
                    </div>
                </div>
                <section>
                    <!-- <div class="deal-heading">Best Deals For Your Success</div> -->
                        <div class="deal-cards">
                            <div class="card deal-single-card" *ngFor="let deal of acquihireDeals" (click)="dealDescription(deal)">
                                <div class="card-top-sec">
                                    <div class="card-left-part">
                                        <div class="profile-img">
                                            <div class="company-prof-img" *ngIf="deal?.profileImg === null; else orginalImg">
                                                <img src="./../../../../assets/images/deal-details/team.svg" alt="team-icon" >
                                            </div>
                                            <ng-template #orginalImg>
                                                <div class="origina-prof-img">
                                                    <img [src]="deal.profileImg" alt="">
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="part-description">
                                            <div class="deal-descrip-heading">
                                                <div class="deal">{{deal.displayId}}</div>
                                            </div>
                                            <div class="deal-descrip-heading">
                                                <div class="deal">{{deal.name}}</div>
                                            </div>
                                            <div class="location">
                                                <img src="./../../../assets/images/common/location.svg" alt="" class="sub-icon">
                                                <div class="loc-text">
                                                    {{deal?.location?.city}}<span *ngIf="deal?.location?.locality">,{{deal?.location?.locality}}</span>
                                                </div>
                                            </div>
                                            <div class="location-technology">
                                                <div class="technologies" *ngFor="let item of deal.skills">
                                                    {{item.name | titlecase}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-right-part">
                                        <div class="card-heading">
                                            Acquihire
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="card-text">
                                        {{deal.description.split(" ").splice(0,20).join(" ")}}<span *ngIf="deal.description.split(' ').splice(0,20).join(' ').length === 20">...</span>
                                    </div>
                                </div>
                                <div class="card-bottom">
                                    <div class="bottom-part">
                                        <img src="./../../../assets/images/common/gross-revenue.svg" alt="" class="bottom-img" loading="lazy">
                                        <div class="text-subText">
                                            <div class="bottom-text-heading">Revenue</div>
                                            <div class="bottom-subText-heading">₹ {{revenue}} Lac</div>
                                        </div>
                                    </div>
                                    <div class="bottom-part">
                                        <img src="./../../../assets/images/common/net-profit.svg" alt="startup-stage" class="bottom-img" loading="lazy">
                                        <div class="text-subText">
                                            <div class="bottom-text-heading">EBITDA</div>
                                            <div class="bottom-subText-heading">N/A</div>
                                        </div>
                                    </div>
                                    <div class="bottom-part">
                                        <img src="./../../../assets/images/common/team-size.svg" alt="Experience" class="bottom-img" loading="lazy">
                                        <div class="text-subText">
                                            <div class="bottom-text-heading">Team Size</div>
                                            <div class="bottom-subText-heading">{{deal.teamSize}}</div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                        </div>
                </section>
                <!-- <section>
                    <div class="view-more-btn" *ngIf="currentUrl != ''">
                        <button>View More Deals</button>
                    </div>
                </section> -->
            </div>
        </div>
        <div class="loader" *ngIf="getAllLoader">
            <div id="divSpinner" class="spinner loading">
              <img src="./../../../../../../../assets/images/common/for-loader.svg" alt="">
           </div>
        </div>
    </div>
</div>