import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as SecureLS from 'secure-ls';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbServiceService {

  constructor(
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar
  ) { }
  private _lsService = new SecureLS({encodingType:'rc4', isCompression: false});

  setUserName(body:any){
    this._lsService.set('873766',body);
  }
  getUserName(){
    return this._lsService.get('873766');
  }

  setUserId(body:any){
    this._lsService.set('873743',body)
  }
  getUserId(){
    return this._lsService.get('873743')
  }
  setToken(body:any){
    this._lsService.set('86536',body);
  }
  getToken(){
    return this._lsService.get('86536')
  }
  setPrevUrl(body:any){
    this._lsService.set('7738875',body);
  }
  getPrevUrl(){
    return this._lsService.get('7738875');
  }
  setStoringValue(body:any){
    this._lsService.set('storingValue',body)
  }
  getStoringValue(){
    return this._lsService.get('storingValue');
  }


  removeAll(){
    this._lsService.removeAll();
  }



  //sharing data between the lazy modules
  public data = new BehaviorSubject<String>('')
}
