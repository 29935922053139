import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-startup',
  templateUrl: './choose-startup.component.html',
  styleUrls: ['./choose-startup.component.scss','./mq-choose-startup.component.scss']
})
export class ChooseStartupComponent implements OnInit {

  @Input() chooseArray ;
  @Input() chooseTitle1 ;
  @Input() chooseTitle2 ;

  constructor() { }

  ngOnInit(): void {
  }

}
