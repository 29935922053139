import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { startupStages } from '../../../constants/common-constants';
import { DbServiceService } from '../../global-services/db-service.service';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-list-acquihire',
  templateUrl: './list-acquihire.component.html',
  styleUrls: ['./list-acquihire.component.scss','./mq-list-acquihire.component.scss']
})
export class ListAcquihireComponent implements OnInit {

  @Input() from;
  @Input() fromAcquihire

  locationControl = new FormControl();
  locationSerach = '';
  locationList = [];
  locationOptions : Observable<any> | any;
  currentUrl ;
  location = '';
  technology = '';
  serviceOffered = null;
  teamSize = '';
  tabValue = '';
  acquihireDeals : any [] = [];
  getAllLoader : boolean = false;
  sidePanelBoolean : boolean;
  filterLoader : boolean = false
  totalDbCount : number;
  startingLeadNumber : number = 1;
  endingLeadNumber : number;
  totalPages : number = 1;
  activeLeftArrow : boolean = false;
  activeRightArrow : boolean = true;
  goPage : number = 1;
  pageNo : number ;
  size  = 30;
  presentPageNo  = 1;

  tabs : any [] = []

  // public startuptypeArray = startupStages;
  revenue;
  // public startuptypeList = this.startuptypeArray.slice();
  technologyArray = [];
  public technologyList;
  serviceArray = [];
  public serviceList;

  constructor(
    private _router : Router,
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar,
    private _dbService : DbServiceService,
    private _route : ActivatedRoute
  ) {
    this.currentUrl = this._router.url.split("-")[1];
    this.changeDealType(this.tabs[0])
  }

  ngOnInit(): void {
    // this.getAllAquihire();
    if(this.from === 'ACQUIHIRE_MAIN_PAGE'){
      this.sidePanelBoolean = true;
    }
    else{
      this.sidePanelBoolean = false;
    }
    this.checkParams()
    this.getAllTechnologies();
    this.getAllServices();
  }
  checkParams(){
    this._route.queryParams.subscribe(
      (params) => {
        this.pageNo = params['pageNo']-1;
        this.getDummyAcquihire();
        this.presentPageNo = params['pageNo'];
        this.goPage = params['pageNo'];
      }
    )
  }
  getDummyAcquihire(){
    if(this.fromAcquihire){
      this.getAllLoader = true;
      let data ={
        module : 'ACQUIRE_HIRE',
        page:0,
        size:4
      }
      this._globalService.getAllData(data).subscribe(
        (res:any) => {
          console.log(res.data);
          this.acquihireDeals = res.data.object;
          this.acquihireDeals.forEach(element => {
            this.revenue = Math.max(...element.revenueFY.map(o => o.numValue)) / 100000
          });
          this.getAllLoader = false;
        },
        (err) => {
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          );
          this.getAllLoader = false;
        }
      )  
    }
    else{
      this.getAllLoader = true;
      let data ={
        module : 'ACQUIRE_HIRE',
        page:this.pageNo,
        size:this.size
      }
      this._globalService.getAllData(data).subscribe(
        (res:any) => {
          // console.log(res.data);
          this.acquihireDeals = res.data.object;
          this.acquihireDeals.forEach(element => {
            this.revenue = Math.max(...element.revenueFY.map(o => o.numValue)) / 100000
          });
          this.totalDbCount = res.data.count;
          this.totalPages = Math.ceil(this.totalDbCount/this.size);
          this.checkForArrows();
          this.showLeadNumbers();
          this.getAllLoader = false;
        },
        (err) => {
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          );
          this.getAllLoader = false;
        }
      )
    }
  }
  getLocations(){
    this._globalService.searchCity(this.locationSerach).subscribe(
      (res:any) => {
        this.locationList = res.data.map(element => {
          return element = {id:element.cityId, cityName:element.cityName, stateName:element.stateName}
        });
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
    this.locationOptions = this.locationControl.valueChanges.pipe(
      startWith(''),
      map(value =>this.locationFilter(value))
    )
  }
  private locationFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.locationList.filter((option: any) => option.cityName.toLowerCase().includes(filterValue));
  }

  selectLocation(ev){  
    this.location = ev.cityName;
  }
  changeDealType(item){
    this.tabs.forEach(element => {
      if(element.value === item.value){
        element.status = true;
        this.tabValue = element.value
      }
      else{
        element.status = false;
      }
    });
  }

  clearFilter(){
    this.locationSerach = '';
    this.location = '';
    // this.technology = '';
    this.serviceOffered = null;
    // this.teamSize = '';
    this.getDummyAcquihire();
  }

  dealDescription(item){
    // this.acquihireDeals.forEach(element => {
    //   if(item?.cardHeading === element?.cardHeading){
        this._dbService.setPrevUrl(this._router.url.split('?')[0])
        this._router.navigate([`acquihire-deal-details/${item.displayId}`],{queryParams:{pageNo:this.presentPageNo}});
        window.scroll({
          top:0,
          left:0,
          behavior:'smooth'
        })
  //     }
  //   });
  }

  getAllTechnologies(){
    this._globalService.getAllTechnology().subscribe(
      (res:any) => {
        this.technologyArray = res.data.map(element => {
          return element = {id:element.technologyId,name:element.technologyName}
        });
        this.technologyList = this.technologyArray.slice();
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
  getAllServices(){
    this._globalService.getAllServices().subscribe(
      (res:any) =>{
        // console.log(res.data);
        this.serviceArray = res.data.map(element => {
          return element = {id:element.serviceId,name:element.serviceName}
        });
        this.serviceList = this.serviceArray.slice();
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }

  applyFilters(){
    this.filterLoader = true;
    let data ={
      location: this.location,
      module: "ACQUIRE_HIRE",
      industry : this.serviceOffered,
      page:this.pageNo,
      size:this.size
    }
    this._globalService.getAllData(data).subscribe(
      (res:any) => {
        // console.log(res.data);
        if(res.data.object.length != 0){
          this.acquihireDeals = res.data.object
        }
        else{
          this._snackbar.open(
            `No Data Found To Related Filters`,
            'Ok',
            {
              duration:1500
            }
          )
        }
        this.filterLoader = false;
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
        this.filterLoader = false;
      }
    )
  }

  //pagination

  previousPage(){
    this.pageNo --;
    // this._dbService.setPrevClick(true);
    // this._dbService.setNxtClick(false);
    this._router.navigate([`/list-acquihire`],{queryParams:{pageNo:this.pageNo+1}})
  }
  nextPage(){
    // this._dbService.setNxtClick(true);
    // this._dbService.setPrevClick(false);
    this.pageNo ++;
    this._router.navigate([`/list-acquihire`],{queryParams:{pageNo:this.pageNo+1}})
  }
  
  goToPageNumber(){
    if(this.goPage > this.totalPages || this.goPage === 0){
      this._snackbar.open(
        'Please enter correct page no',
        'Close',
        {
          duration:1500
        }
      )
    }
    else{
      this._router.navigate([`/list-acquihire`],{queryParams:{pageNo:this.goPage}})
    }
  }
  
  checkForArrows(){
    if(this.size == this.totalDbCount || this.size >= this.totalDbCount){
      this.activeRightArrow = false;
      this.activeLeftArrow = false;
    }
    else if(this.size !=  this.totalDbCount){
      if(this.presentPageNo == 1){
        this.activeLeftArrow = false;
        this.activeRightArrow = true;
      }
      else if(this.presentPageNo == this.totalPages){
        this.activeLeftArrow = true;
        this.activeRightArrow = false;
      }
      else{
        this.activeLeftArrow = true;
        this.activeRightArrow = true;
      }
    }
  }

  showLeadNumbers(){
      if(this.presentPageNo == 1){
        this.startingLeadNumber = 1;
        if(this.size > this.totalDbCount){
          this.endingLeadNumber = this.totalDbCount
        }
        else{
          this.endingLeadNumber = this.size; 
        }
      }
      else if (this.presentPageNo == this.totalPages){
        if(this.acquihireDeals.length != this.size){
          this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1
        }
        else{
          this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1
        }
        this.endingLeadNumber = this.totalDbCount;
      }
      else{
        this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1;
        this.endingLeadNumber = this.startingLeadNumber+this.size-1;
      }
  }

}
