import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LetsConnectPopupComponent } from '../../../shared-components/campaign-flow/lets-connect-popup/lets-connect-popup.component';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-acquisition',
  templateUrl: './acquisition.component.html',
  styleUrls: ['./acquisition.component.scss','./mq-acquisition.component.scss']
})
export class AcquisitionComponent implements OnInit {

  chooseTitle1 = 'Why choose'
  secondTitle = 'Startup Login?'
  srcWidth;
  srcHeight;

  constructor(
    private _router : Router,
    private _globalService : GlobalService
  ) { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }

  ngOnInit(): void {
  }

  exitRewarding : any [] = [
    {
      imgUrl:'./../../../../assets/images/exit/strategic.svg',
      title:'It is Strategic',
      subText:'Accelerate growth by an Acquisition through a startup buyout'
    },
    {
      imgUrl:'./../../../../assets/images/exit/smart.svg',
      title:'It is Smart',
      subText:'Investment in acquisition has better and faster ROI. And as well, lends speed to market.'
    },
    {
      imgUrl:'./../../../../assets/images/exit/opportunity.svg',
      title:'There is Opportunity',
      subText:'There are several startups that are looking to become a part of a larger business organisations'
    }
  ];

  chooseStartupArray: any[] =[
    {
      imgUrl:'./../../../../assets/images/exit/startup-oriented.svg',
      title:'Turnkey Analytics',
      subText:'helps you best identify rewarding deals'
    },
    {
      imgUrl:'./../../../../assets/images/exit/acquisition-startup-outreach.svg',
      title:'Coveted Network',
      subText:'Best opportunities through vast networks'
    },
    {
      imgUrl:'./../../../../assets/images/exit/acqusition-diligently-identified.svg',
      title:'Expert Screening',
      subText:'Goals, growth and potential screened by experts who will guide you'
    },
    {
      imgUrl:'./../../../../assets/images/exit/outcome-oriented.svg',
      title:'Outcome Oriented',
      subText:'Win-Win transactions for both ends'
    }
  ];

  successStories : any [] = [
    {
      number:'01',
      title:'Explore & Analyse for right option.',
      // subText : 'Explore & Analyse for right option.'
    },
    {
      number:'02',
      title:'Connect with potential acquirers',
      // subText : 'Connect with potential acquirers'
    },
    {
      number:'03',
      title:'Negotiate Offers and Due Diligence',
      // subText : 'Negotiate Offers and Due Diligence'
    },
    {
      number:'04',
      title:'Sign the deal and complete the transaction',
      // subText : 'Sign the deal and complete the transaction'
    }
  ];

  connectNow(){
    // window.scroll({
    //   top:0,
    //   left:0,
    //   behavior:'smooth'
    // })
    // this._router.navigate(['/contact']);
    if(this.srcWidth > 474){
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        'auto',
        '45%',
        { data:'TALK_TO_EXPERT' }
      )
    }
    else{
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        '99%',
        '99%',
        { data:'TALK_TO_EXPERT' }
      )
    }
  }

}
