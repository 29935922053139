import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LetsConnectPopupComponent } from '../../../shared-components/campaign-flow/lets-connect-popup/lets-connect-popup.component';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-acquihire',
  templateUrl: './acquihire.component.html',
  styleUrls: ['./acquihire.component.scss','./mq-acquihire.component.scss']
})
export class AcquihireComponent implements OnInit {

  chooseTitle1 = 'Why choose'
  secondTitle = 'Startup Login?';
  from = 'ACQUIHIRE_MAIN_PAGE'
  fromAcquihire = true;
  count;
  srcWidth;
  srcHeight;

  constructor(
    private _router: Router,
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar
  ) { 
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }
  acquihireDeals = []

  ngOnInit(): void {
    this.acquihireDeals  = [
      {
        profImg:'',
        cardHeading: 'ACQUIHIRE',
        postTitle:'All Talent-123',
        cardText:'Invest in a garden close by or a small farm and receive fresh produce in return based on your investment size and frequency.Invest in a garden close by or a small farm',
        grossRevenue : '150K',
        netProfit:'150K',
        rating:4,
        revenue:'50',
        ebitda:'20 - 30%',
        teamSize:'10',
        displayId:'Talent-123001'
      },
      {
        profImg:'./../../../assets/images/deal-details/example-img.png',
        cardHeading: 'ACQUIHIRE',
        postTitle:'All Ignyte Tech ',
        cardText:'Invest in a garden close by or a small farm and receive fresh produce in return based on your investment size and frequency.Invest in a garden close by or a small farm',
        grossRevenue : '150K',
        netProfit:'150K',
        rating:3,
        revenue:'100',
        ebitda:'30 - 50%',
        teamSize:'25',
        displayId:'Talent-123001'
      }
    ];

    let data ={
      module : 'ACQUIRE_HIRE',
      page:0,
      size:4
    }
    this._globalService.getAllData(data).subscribe(
      (res:any) => {
        this.acquihireDeals = res.data.object;
        this.count = this.acquihireDeals.length
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
      }
    )
  }

  exitRewarding : any [] = [
    {
      imgUrl:'./../../../../assets/images/exit/strategic.svg',
      title:'It is Strategic',
      subText:'Accelerate organizational growth by Acquihiring.'
    },
    {
      imgUrl:'./../../../../assets/images/exit/smart.svg',
      title:'It is Smart',
      subText:'Acquihiring eliminates cumbersome traditional hiring. It is fast & cost effective.'
    },
    {
      imgUrl:'./../../../../assets/images/exit/opportunity.svg',
      title:'There is Opportunity',
      subText:'There are several startups that are looking to become a part of a larger business ecosystem.'
    }
  ];

  chooseStartupArray: any[] =[
    {
      imgUrl:'./../../../../assets/images/exit/startup-oriented.svg',
      title:'Turnkey Analytics',
      subText:'Helps you best identify rewarding deals'
    },
    {
      imgUrl:'./../../../../assets/images/exit/acquihire-talent-outreach.svg',
      title:'Expert Screening',
      subText:'Goals, growth and potential screened by experts who will guide you'
    },
    {
      imgUrl:'./../../../../assets/images/exit/acquihire-domain-expertise.svg',
      title:'Aligning values',
      subText:'Scouting acquisitions in line with existing mission, belief and core values'
    },
    {
      imgUrl:'./../../../../assets/images/exit/acquihire-target-hiring.svg',
      title:'Opportunities for acquirees',
      subText:'Skills and knowledge focused company targeting'
    }
  ];

  successStories : any [] = [
    {
      number:'01',
      title:'Explore company structure',
      // subText : 'Explore company structure'
    },
    {
      number:'02',
      title:'Assess talent and culture',
      // subText : 'Assess talent and culture'
    },
    {
      number:'03',
      title:'Accept the offer and join the organization.',
      // subText : 'Accept the offer and join the organization.'
    }
  ]

  viewMoreAcquihire(){
    this._router.navigate([`/list-acquihire`],{queryParams:{pageNo:1}})
  }

  connectNow(){
    // window.scroll({
    //   top:0,
    //   left:0,
    //   behavior:'smooth'
    // })
    // this._router.navigate(['/contact'])
    if(this.srcWidth > 474){
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        'auto',
        '45%',
        { data:'TALK_TO_EXPERT' }
      )
    }
    else{
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        '99%',
        '99%',
        { data:'TALK_TO_EXPERT' }
      )
    }
  }
}
