<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand cursor-pointer" href="">
        <img src="./../../../assets/images/top-navbar/branding-logo.svg" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li *ngFor="let navItem of navItems" (click)="navItemRedirect(navItem)" class="cursor-pointer" [ngClass]="navItem?.status ? 'nav-item-active' : 'nav-item'">
            <a class="nav-link">{{navItem?.name | titlecase}}</a>
        </li>
      </ul>
      <div class="login-btn" *ngIf="!displayBoolean">
        <button (click)="goToLoginPage()">Let's Connect</button>
      </div>
    </div>
  </nav>