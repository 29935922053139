import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss','./mq-about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  

  constructor() { }
  
  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":true,
    "autoplay":true,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":false,
    "pauseOnHover":false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  ngOnInit(): void {
  }

  teamMembers : any[] = [
    {
      imgUrl:'./../../../assets/images/about/sunitha.jpeg',
      name:'Sunita Kumari',
      designation:'Co-founder & Head of A&A',
      linkedIn:'https://www.linkedin.com/in/sunita-kumari007/'
    },
    {
      imgUrl:'./../../../assets/images/about/three.png',
      name:'Sanchit Gumidelli',
      designation:'Sr. Associate Sourcing',
      linkedIn:'https://www.linkedin.com/in/gumidelli-sanchit-b7a803169/'
    },
    {
      imgUrl:'./../../../assets/images/about/five.png',
      name:'Amtul Ahad',
      designation:'Community Lead',
      linkedIn:'https://www.linkedin.com/in/amtulbushra/'
    },
    {
      imgUrl:'./../../../assets/images/about/aman.png',
      name:'Aman Priyadharshi',
      designation:'Associate, Incharge of graphic design and branding',
      linkedIn:'https://www.linkedin.com/in/aman-priyadarshi-248908204/'
    },
    {
      imgUrl:'./../../../assets/images/about/sriram_cropped.jpg',
      name:'Siram Sankeerth',
      designation:'Associate Sourcing',
      linkedIn:'https://www.linkedin.com/in/siram-sankeerth/'
    },
    {
      imgUrl:'./../../../assets/images/about/sanjay.jpg',
      name:'Sanjay Enishetty',
      designation:'Board of Director',
      linkedIn:'https://www.linkedin.com/in/sanjayenishetty/'
    },
    {
      imgUrl:'./../../../assets/images/about/four.png',
      name:'Sriram Dhanyamraju',
      designation:'Advisor',
      linkedIn:'https://www.linkedin.com/in/sriramdhanyamraju/'
    },
  ];

  customerReview: any [] =[
    {
      review : 'We use Startup Login to acquire established, high-quality startups that operate in the Java and devOps verticals',
      customerName : '',
      designation:'MD',
      companyName:'Corporate Entity'
    },
    {
      review : 'Ours is an edtech unicorn company. We have acquired 20 companies over the last decade. Startup Login helps us find amazing startups to acquire.',
      customerName : '',
      designation:' CEO',
      companyName:'Unicorn Company'
    },
    {
      review : 'Startup Login easily connects me with startup founders looking to sell their business. Through their advisory and consultative approach I was able to acquire a startup that fit perfectly within our company portfolio. I highly recommend Startup Login to anyone looking to buy a tech startup',
      customerName : '',
      designation:'Founder',
      companyName:' Unicorn Company'
    },
    {
      review:"If you have a startup you're thinking about selling, check out Startup Login. If you're looking to buy a startup, they will have what you're looking for!",
      customerName:'',
      designation:' Founder',
      companyName:'High growth startup'
    }
  ];

}
