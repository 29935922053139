import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DbServiceService } from '../../global-services/db-service.service';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-talenthire-details',
  templateUrl: './talenthire-details.component.html',
  styleUrls: ['./talenthire-details.component.scss','./mq-talenthire-details.component.scss']
})
export class TalenthireDetailsComponent implements OnInit {

  singleTalenthire : any;

  techStack=[
    'NodeJS','React','Solidity','mongoDB'
  ]
  serviceOffered=[
    'Mobile Application','Web Application','Blockchain Development','API Services','Scaling up Applications','Project Management'
  ];
  teamMemebers=[
    {},{},{},{},{}
  ]
  comments = [
    'Ready to join immediately',
    'Hands-on experience on React Framework at least 10 years.',
    'Ready to join immediately'
  ]
  acquihire = 'updation';
  pageNo: number;
  constructor(
    private _router : Router,
    private _snackBar : MatSnackBar,
    private _route : ActivatedRoute,
    private _globalService : GlobalService,
    private _dbService : DbServiceService
  ) { }

  ngOnInit(): void {
    this.getOneDetails();
    this._route.queryParams.subscribe(
      (params) => {
        this.pageNo = params['pageNo']
      }
    )
  }

  getOneDetails(){
    this._route.params.subscribe(
      (params) => {
        // console.log(params['displayId']);
        this._globalService.viewTalenthire(params['displayId']).subscribe(
          (res:any) => {
            console.log(res.data);
            if(res.data.candidatePrefernceInfo != null){
              res.data.candidatePrefernceInfo.annualCompensation = res.data.candidatePrefernceInfo.annualCompensation / 100000
            }
            this.singleTalenthire = res.data
          },
          (err) => {
            this._snackBar.open(
              `${err.error.message}`,
              'Close',
              {
                duration:1500
              }
            )
          }
        )
      }
    )
  }

  scrollToMembers(el : HTMLElement){
    el.scrollIntoView();
  }
  
  backTo(){
    // this._router.navigate([`/list-talenthire`]);
    this._router.navigate([this._dbService.getPrevUrl()],{queryParams:{pageNo:this.pageNo}});
  }
  downloadSingleResume(){
    this._router.navigate(['/authentication'])
  }
  markInterested(){
    this._snackBar.open(
      'Please Login First',
      'Ok',
      {
        duration:1500
      }
    )
  }


}
