import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from '../global-services/global.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss','./mq-contact.component.scss']
})
export class ContactComponent implements OnInit {

  name = '';
  email = '';
  mobile = '';
  service = null;
  message = '';
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  constructor(
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar
  ) { }

  ngOnInit(): void {
  }
  buisinessDomain : any[] = [
    {
      name:'a',
      value:"A"
    },
    {
      name:'b',
      value:'B'
    },
    {
      name:'c',
      value:'C'
    }
  ];

  deals : any [] = [
    {
      name:'exit',
      value:'EXIT'
    },
    {
      name:'acquisition',
      value:'ACQUISITION'
    },
    {
      name:'acquihire',
      value:'ACQUIRE_HIRE'
    },
    {
      name:'startup talent',
      value:'TALENT_HIRE'
    }
  ];

  loader: boolean = false;
  sendMessage(){
    let data = {
      email : this.email,
      message: this.message,
      mobile:{
        countryCode:'91',
        mobile:this.mobile
      },
      name:this.name,
      services : this.service
    }
    this.loader = true;
    if(this.emailRegex.test(this.email)){
      this._globalService.creteContact(data).subscribe(
        (res:any) => {
            this._snackbar.open(
              `Thank you, Our Executive Reach You Soon`,
              'Ok',
              {
                duration:1500
              }
            );
            this.name = '';
            this.email = '';
            this.service = null;
            this.message = '';
            this.mobile = '';
            this.loader = false;
        },
        (err) => {
          this.loader = false;
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
        }
      )
    }
    else{
      this.loader = false;
      this._snackbar.open(
        'Please Provide Valide Email',
        'Ok',
        {
          duration:1500
        }
      )
    }
  }
}
