import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LetsConnectPopupComponent } from '../../shared-components/campaign-flow/lets-connect-popup/lets-connect-popup.component';
import { GlobalService } from '../global-services/global.service';

@Component({
  selector: 'app-deal-feeders',
  templateUrl: './deal-feeders.component.html',
  styleUrls: ['./deal-feeders.component.scss']
})
export class DealFeedersComponent implements OnInit {

  srcWidth;
  srcHeight;

  chooseTitle1 = 'Why choose'
  secondTitle = 'Startup Login?';
  checkBox : boolean;
  name = '';
  email = '';
  mobile = '';
  message = null;
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  constructor(
    private _snackBar : MatSnackBar,
    private _globalService : GlobalService,
    private _router : Router
  ) {
    this.getScreenSize();
   }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }

  ngOnInit(): void {
  }

  exitRewarding : any [] = [
    {
      imgUrl:'01',
      title:'Refer a Startup',
      subText:'If you know of any startup struggling or looking for an early exit, refer them to us.'
    },
    {
      imgUrl:'02',
      title:'What you get?',
      subText:'If the referral classifies as an eligible startup, receive an amazon gift voucher in addition to an incentive on success of deal.'
    },
    {
      imgUrl:'03',
      title:'Get paid',
      subText:"It's simple. Register, receive work and get paid."
    }
  ];

  chooseStartupArray: any[] =[
    {
      imgUrl:'./../../../../assets/images/exit/startup-oriented.svg',
      title:'Startup Oriented',
      subText:'Focused on startups'
    },
    {
      imgUrl:'./../../../../assets/images/exit/acquisition-startup-outreach.svg',
      title:'Startup Outreach',
      subText:'Knowledge of the Ecosystem'
    },
    {
      imgUrl:'./../../../../assets/images/exit/acqusition-diligently-identified.svg',
      title:'Diligently Identified',
      subText:'Curated startups'
    },
    {
      imgUrl:'./../../../../assets/images/exit/outcome-oriented.svg',
      title:'Outcome Oriented',
      subText:'For best possible deals'
    }
  ];

  successStories : any [] = [
    {
      number:'01',
      title:'Deal Sharing and Evaluation',
      subText : 'Explore & Analyse for right option.'
    },
    {
      number:'02',
      title:'Connecting Potential Startups',
      subText : 'Identifying and Contacting Targets.'
    },
    {
      number:'03',
      title:'Offer and Due Diligence',
      subText : 'Evaluate and negotiate offers for the successful outcome'
    },
    {
      number:'04',
      title:'Deal Closure',
      subText : 'Sign the deal and complete the transaction'
    }
  ];

  apply(){
    if(this.emailRegex.test(this.email)){
      let data = {
        email: this.email,
        message : this.message,
        mobile:{
          countryCode:"91",
          mobile:this.mobile
        },
        name:this.name
      }
      this._globalService.createDealFeeder(data).subscribe(
        (res:any) => {
          this.name = '';
          this.message = null;
          this.email = '';
          this.mobile = '';
          this.checkBox = false;
          this._snackBar.open(
            "Thank You for Reaching Us, We'll Contact You Soon",
            'Ok',
            {
              duration:1500
            }
          )
        },
        (err) => {
          this._snackBar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
        }
      )
    }
    else{
      this._snackBar.open(
        'Please enter valid mail id',
        'Ok',
        {
          duration:1500
        }
      )
    }
  }

  goToForm(form : HTMLElement){
    form.scrollIntoView();
  }
  refereSection(){
    if(this.srcWidth > 474){
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        'auto',
        '45%',
        { data:'REFER' }
      )
    }
    else{
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        '99%',
        '99%',
        { data:'REFER' }
      )
    }
  }

}
