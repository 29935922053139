import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbServiceService } from '../../../main-components/global-services/db-service.service';
import { GlobalService } from '../../../main-components/global-services/global.service';
import { LetsConnectPopupComponent } from '../lets-connect-popup/lets-connect-popup.component';

@Component({
  selector: 'app-campaign-top-navbar',
  templateUrl: './campaign-top-navbar.component.html',
  styleUrls: ['./campaign-top-navbar.component.scss','./mq-campaign-top-navbar.component.scss']
})
export class CampaignTopNavbarComponent implements OnInit {

  displayBoolean : boolean = false;
  srcWidth;
  srcHeight;

  constructor(
    private _router : Router,
    private _dbService : DbServiceService,
    private _globalService : GlobalService
  ) { 
    let check = this._router.url.split("/");
    if(check[1] === 'authentication'){
      this.displayBoolean = true
    }
    else{
      this.displayBoolean = false;
    }
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }
  currentUrl = ''

  ngOnInit(): void {
    let currentUrl = window.location.href.split('/')[3];
    this.navItems.forEach(element => {
      if(element.redirectingUrl === currentUrl){
        element.status = true;
      }
      else{
        element.status = false;
      }
    });

    if(this._dbService.getToken() === ''){}
    else{
      this._router.navigate([`/startup-login/customer-portal/talenthire/list-all-deals`])
    }
  }

  service:any []=[
    {
      dropdownText : 'exit',
      redirectingUrl:'solutions-exit'
    },
    {
      dropdownText : 'acquisition',
      redirectingUrl:'solutions-acquisition'
    },
    {
      dropdownText : 'acquihire',
      redirectingUrl:'solutions-acquihire'
    },
    {
      dropdownText : 'startup talent',
      redirectingUrl:'solutions-talenthire'
    }
  ];
  navItems : any [] = [
    {
      name:'home',
      redirectingUrl:'',
      status:false
    },
    {
      name:'about us',
      redirectingUrl : 'about-us',
      status:false
    },
    {
      name:'solutions',
      redirectingUrl:'solutions',
      status:false
    },
    {
      name:'resource',
      redirectingUrl:'resource',
      status:false
    }
  ]
  redirectUrl(item){
    this.service.forEach(element => {
      if(item?.dropdownText === element.dropdownText){
        this._router.navigate([`/${element.redirectingUrl}`])
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      }
    });
  }
  navItemRedirect(item){
    this.currentUrl = this._dbService.getStoringValue();
    if(this.currentUrl === 'home'){
      if(item?.redirectingUrl === 'solutions'){
        this.navItems.forEach(element => {
          if(element.name === item.name){
            element.status = true
            // this._router.navigate(['']);
            // this._dbService.setStoringValue(item.name)
            window.scroll({
              top:1086,
              left:0,
              behavior :'smooth'
            });
            this._dbService.data.next('solution');
          }
          else{
            element.status = false;
          }
        });
      }
      else{
        this.navItems.forEach(element => {
          if(element.name === item.name){
            element.status = true;
            this._dbService.setStoringValue(item.name)
            this._router.navigate([`/${element.redirectingUrl}`]);
            window.scroll({
              top:0,
              left:0,
              behavior :'smooth'
            });
          }
          else{
            element.status = false;
          }
        });
      }
    }
    else{
      if(item?.redirectingUrl === 'solutions'){
        this.navItems.forEach(element => {
          if(element.name === item.name){
            element.status = true
            this._router.navigate(['']);
            this._dbService.setStoringValue(item.name)
            window.scroll({
              top:1086,
              left:0,
              behavior :'smooth'
            });
          }
          else{
            element.status = false;
          }
        });
      }
      else{
        this.navItems.forEach(element => {
          if(element.name === item.name){
            element.status = true;
            this._dbService.setStoringValue(item.name)
            this._router.navigate([`/${element.redirectingUrl}`]);
            window.scroll({
              top:0,
              left:0,
              behavior :'smooth'
            });
          }
          else{
            element.status = false;
          }
        });
      }
    }
  }
  goToLoginPage(){
    if(this.srcWidth > 474){
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        'auto',
        '45%',
        { data:'LETS_CONNECT' }
      )
    }
    else{
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        '99%',
        '99%',
        { data:'LETS_CONNECT' }
      )
    }
  }

}
