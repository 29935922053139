<!-- <section class="topnavbar">
    <app-top-navbar></app-top-navbar>
</section> -->

<section>
    <div class="first-section">
        <div class="career-text">
            <div class="career-heading">Career Opportunities For Laid off Talent</div>
            <div class="career-sub-text">Have you been laid-off recently or are looking for a 
                job change? Startup Login is here to help you out. Fill in the form here and get exposure to multiple companies that are hiring.</div>
            <div class="add-btn">
                <button (click)="addMe()">Add Me To List</button>
            </div>
        </div>
        <div class="career-img">
            <img src="./../../../assets/images/campaign-flow/laid_off_opportunity.jpg" alt="career-image">
        </div>
    </div>
    <div class="category-section">
        <div class="section-heading">Here's list of job categories</div>
        <div class="category-list">
            <div class="single-category" *ngFor="let item of job">
                <div class="top-sector">
                    <img src="./../../../assets/images/laid-off-career/engine-icon.png" alt="engine-icon">
                    <div class="category-title">{{item?.title}}</div>
                </div>
                <div class="available-count">{{item?.count}} Available</div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="footer">
    <app-footer></app-footer>
</section> -->