import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss','./mq-blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit, AfterViewInit {

  constructor(
    private _router : Router
  ) { }

  blogId;
  ngAfterViewInit(): void {
    
  }

  details: any ;

  ngOnInit(): void {
    this.blogId = this._router.url.split('/')[2]
    // this.details = this.blogDetails[0]
    this.blogDetails.forEach(element => {
      if(element.blogId === this.blogId){
        this.details = element;
      }
    });
  }

  blogs : any [] = [
    {
      imgUrl:'./../../../assets/images/blogs/blog4-img.png',
      aboutBlog:'Unicorn',
      date:'19 Nov, 2021',
      readTime:'3',
      blogTitle:'Unicorns beat the lions in acquisition race',
      shortnote:"India's top unicorns seem to have championed the acquisition game. In an attempt to scale up rapidly in a short period of time, they have left traditional tech companies far behind in the game of acquisition."
    },
    {
      imgUrl:'./../../../assets/images/blogs/blog3-img.png',
      aboutBlog:'Acquisition',
      date:'19 Nov, 2021',
      readTime:'4',
      blogTitle:'Raining M & A deals is everyone’s delight',
      shortnote:"​Most founders start their entrepreneurial journey with a dream of building a multi-billion dollar company that will live on long after they are gone. However, most entrepreneurs give up on this dream after years of hard work and struggle without proper compensation. "
    },
    {
      imgUrl:'./../../../assets/images/blogs/blog2-img.png',
      aboutBlog:'Startup',
      date:'19 Nov, 2021',
      readTime:'3',
      blogTitle:'Small or big, your startup can deliver IPO like returns',
      shortnote:"India is gearing up for a tech IPO boom. Zomato’s dream listing on the exchanges marks the start of a new era for India’s tech industry. A number of startups, from Flipkart to PolicyBazaar to Nykaa, are set to come out with their IPO offerings soon.."
    }
  ];

  blogDetails = [
    {
      author:'Sunita Kumari',
      about:'Blog',
      date:'Aug 8, 2022',
      readTime:5,
      blogId:'b001',
      title:'How can big companies take cues from startups to rekindle their inner innovator?',
      shortNote:"When there is an absence of creativity, there is always a lack of energy within any organisation. Creating an environment to awaken the innovative sleeping child within is thus mandatory and startups have been doing this since a while. Now, there are indications that big players in the market too are endorsing these startup practices to oil their wheels.",
      img:'../../../../assets/images/campaign-flow/blog_6.svg',
      p:[
        {
          a:"Being big and settled has always had its advantages. Take software companies like Microsoft and Apple for example. Their huge cash stockpile and brand value ensures that they always remain at the top of their game."
        },
        {
          a:"But being big doesn't ensure excellence in all aspects. Despite having all the money to source the best talents, corporates are often found lagging behind small peers on the innovation front. This chink in their armor has led to downfall of several companies, including the likes of Kodak, Blockbuster and Xerox which were once deemed to be 'too big to fail'."
        },
        {
          betweenTitle:"What is it about mid and large companies that make innovation so difficult?"
        },
        {
          a:"The short answer is culture. Anyone who would have worked long enough in a big company would tell you that there's little scope for innovation in such firms. And it’s not that companies develop such a culture on purpose. In a big enough firm, management has no option but to develop rigid processes to ensure continued production. These processes lead to hierarchy which stifles any scope for innovation."
        },
        {
          a:"On top of that, tight work schedules leave no time or incentive for employees to indulge in creating something new. Moreover, the employee reward model at most places is itself kryptonite to innovation. In some companies, the process is such that it punishes employees who take the risk of indulging in new projects."
        },
        {
          betweenTitle:"Why startups win the creativity race"
        },
        {
          a:"Business startup ideas and startup cultures are characterised as being creative, passionate, innovative, and agile. While some of the characteristics need to be developed with great care, others are present by default. "
        },
        {
          a:"The biggest plus point for startups is the small size of the workforce, which automatically develops a sense of ownership in each player. In such a scenario, an innovation friendly culture is bound to spring up even in an early stage startup."
        },
        {
          a:"Beyond this strong synergy that develops by virtue of small and coherent teams, modern day startups invest a lot of time, energy and resources to create a culture of innovation no matter what the business startup ideas are."
        },
        {
          a:"This starts right from the workspace. Most startups develop their offices in such a way that there's no physical barriers between those who like to bounce ideas off of each other. This is why you are more likely to find bean bags and open areas on startup workspaces as compared to cabins and cubicles in companies."
        },
        {
          a:"Moreover, founders and CEOs make constant efforts to involve all employees in decision making even in an early stage startup. They also push team members to develop their creative thinking by way of competitions and workshops."
        },
        {
          a:"In order to keep reinforcing the sense of ownership, many startups award employees with ESOPs."
        },
        {
          a:"All these efforts and the very nature of startups together play a key role in developing a culture that is pro-innovation."
        },
        {
          betweenTitle:"Do corporates have no solution at all?"
        },
        {
          a:"Companies have long been trying to invest in startups and adopt their culture and some have been quite successful at it. If you follow large companies closely, chances are that you would be aware of the term 'innovation lab'."
        },
        {
          a:"These innovation labs, which are now present in most big companies, are nothing but startups funded by corporates to develop future products for them. The only difference is that innovation labs, unlike startups, do not have to worry about selling the product. The selling is done by the company."
        },
        {
          a:"But this isn't the complete solution. To remain ahead of the tech curve, it's important that all employees are involved in the innovation process. This leads us to the other question…"
        },
        {
          betweenTitle:"Is there a way for companies to completely adopt the startup culture?"
        },
        {
          a:"The answer to it lies with a 2019 report published by Innovation Leader in collaboration with KPMG LLP. The two companies fielded a survey to find out what sets successful innovators apart. Here's what they found out:"
        },
        {
          ulList:[
            {div:"1. Creation of synergies"},
            {b:"Such companies make constant efforts to bring various departments closer. This ensures unhindered flow of ideas and leads to creation of synergies that facilitate innovation."}
          ]
        },
        {
          ulList:[
            {div:"2. Innovation-friendly incentive systems "},
            {
              b:"Companies which were better at innovation were found to be offering incentives to employees who were engaging in innovation activities. The incentives included recognition and awards, bonuses, seed funding and dedicated time to further develop the project."
            }
          ]
        },
        {
          ulList:[
            {
              div:"3. Positive environment"
            },
            {
              b:"The survey found that focus on innovation automatically diluted the negatives of corporate culture like politics, turf wars and the lack of alignment. The report inferred that the longer an innovation program survives, the better the company's work culture becomes."
            }
          ]
        },
        {
          ulList:[
            {
              div:'4. They bet on the big bet'
            },
            {
              b:"Pro-innovation companies spend less time on incremental innovation than on the creation of entirely new products and businesses. "
            },
            {
              b:"The findings ultimately indicate that trying to invest in startups and adopting the culture can lead to the development of an innovation-friendly company. And the fact that some companies have already done that up to some extent shows that it's possible for all corporates to tread on the innovation path."
            }
          ]
        }
      ]
    },
    {
      author:'Sunita Kumari',
      about:'Blog',
      date:'Aug 22, 2022',
      readTime:6,
      blogId:'b002',
      title:'How can startups turn over the economic heatwave?',
      shortNote:"The Indian startup ecosystem is at a bizarre juncture, emerging as the third largest in the world after the US and China, while simultaneously grappling with a funding winter. Can it come out unscathed, holding on to its newfound place in the global startup map?",
      img:'./../../../assets/images/campaign-flow/blog_4.svg',
      p:[
        {
          a:"A journey that began back in 2011, when the Bengaluru-based mobile advertising technology company InMobi became India's first startup unicorn, crossed a momentous milestone in the month of May this year, when the country produced its 100th unicorn - the Neobank startup, OPEN."
        },
        {
          a:"The Indian startup ecosystem has witnessed an impressive growth in the last decade, especially in the last six years. If the number of new, recognised startups were a mere 733 in the year 2016-17, it jumped to a whopping 14,000 in 2021-22. A record 44 startups clinched the unicorn status in 2021 alone. In another one year, in 2022, India made the quantum leap to its 100th unicorn, with a combined valuation of $332,7 bn, becoming the third largest startup ecosystem in the world after The United States of America and China. "
        },
        {
          betweenTitle:"Crisis at the time of a crucial phase of growth"
        },
        {
          a:"At this point, the Indian startup ecosystem might seem to be thriving. Not to take the merit away - it indeed is thriving - but it is also important to be cognizant of what’s on the anvil. The consistent interest and commitment displayed by private equity investors and the government of India has been a great source of momentum for startups until now. However, a concerning slowdown is taking place in terms of funding. It’s high time startups begin to prepare an action plan to overcome this economic heatwave."
        },
        {
          betweenTitle:'Is recession knocking at our doors?'
        },
        {
          a:"The global pandemic of 2020 opened a Pandora's box and the economic crunch that came out of it is something that continues to haunt the markets till date. China’s manufacturing sector has not yet recovered from the lockdown and pandemic. The increased interest rates by the central banks have negatively impacted the startup scene and public investments, both in India and the US. The war crisis in Europe and the subsequent price hike of energy and essentials, too, has left indelible dents on the global market. On the other hand, climate change continues to cause severe and extensive damage to not just markets but quality of life everywhere in general."
        },
        {
          a:"All of these factors together have arguably snowballed into a global recession, that has cut India’s revelry over placing itself on the global startup map, short."
        },
        {
          betweenTitle:"How can Indian startups combat the ‘funding winter’?"
        },
        {
          a:"The news of India becoming the third largest startup ecosystem in the world was followed by news of unicorns and other top startups laying off employees in large numbers. While Cars24, the next generation ecommerce platform for pre owned cars, laid off over 600 employees, online tutoring platform Vedantu fired about 424 employees. Unacademy, Meesho and Furlenco are some of the other big names that are in the news for drastically downsizing. The picture is getting clearer now; apart from layoffs, startups are also reconsidering their expansion strategies."
        },
        {
          betweenTitle:'1. Startup mentors'
        },
        {
          a:"Perhaps now is the time for startups of all sizes to deeply introspect their core values, strengths and offerings to prioritise and build a resilient business, driven by a balance of profit and purpose. Startup mentors can help companies recession-proof themselves, navigate the currently tough and volatile market terrain to adapt and grow. With their years of industry experience, they can shed unique insights into the needs of the hour - be it unit economics, prospects of scaling up in a sustainable manner or strengthening business fundamentals."
        },
        {
          ap:'Startup mentors can help assess the financial standing of companies, by taking stock of expenses against revenue, and channel the resources towards survival effectively and efficiently, until the storm has passed.'
        },
        {
          ap:"Yet another important area that needs focus is keeping the team’s morale up. Be it strengthening organisational trust, building a resilient leadership or employing smart recruitment tactics - nobody can help you like a startup mentor can."
        },
        {
          ap:"Startup mentors can help revamp the company’s business models to identify and sharpen their potential to develop or enter new markets without suffering setbacks. Getting this right, at a crucial time of economic slowdown, can help convince potential investors."
        },
        {
          betweenTitle:"2. Startup M&A"
        },
        {
          a:"Startup mergers and acquisition or Startup M&A is a strategy often adopted by unicorns to withstand the challenges of an economic slowdown. At least that’s a key takeaway from the fateful global recession of 2008. "
        },
        {
          a:"The Harvard Business Reviews points out, “Evidence from the global financial crisis (GFC) from late 2007 through early 2009 shows that companies that made significant acquisitions during an economic downturn outperform those that did not.”"
        },
        {
          a:"Seek the guidance of startup mentors and other experts to acquire market share, increase revenue streams and build inroads to technology and talent irrespective of geography through startup M&A."
        },
        {
          betweenTitle:"3. Startup exit strategies"
        },
        {
          a:"Exits are still seen as an ‘end’ by many entrepreneurs, but a smooth, efficient and profitable exit is just as important a part of the companies’ overall growth as any other factors. Even if an exit is not on the cards in the near future, having an exit strategy in place can help companies save on time, money and efforts. While startup M&A - in the sense of buying an existing company for survival - is a method of survival, mergers and acquisition as a part of exiting - as in, selling your startup - is an exit strategy. Other startup exit strategies include IPO (Initial Public Offering), liquidation and close, and more."
        },
        {
          a:"Especially during the time of a funding winter, a solid exit plan facilitates investors to calculate risks, timeline and ROI better. Exiting the right way is also your doorway to make the re-entry in the most effective manner."
        },
        {
          betweenTitle:"Sailing the storm"
        },
        {
          a:"One of the biggest goals for startups should be adapting to change just as much as making a change. The times ahead or right now might be turbulent, but not without hope. With the right strategies and coping mechanisms in place, startups can hold on just fine until the storm passes and markets are revived, to regain the momentum and have investments flowing right back in."
        }
      ]
    },
    {
      author:'Sunita Kumari',
      about:'Blog',
      date:'Sept 5, 2022',
      readTime:6,
      blogId:'b003',
      title:'Your young startup can be worth a fortune!',
      shortNote:"It is possible that anybody can give life to a well intended idea. But this is, by no means, a guarantee of agility. Hence, it is important to make sure that the foundation is strong with no loopholes, and the market is studied well. Here’s how you can make sure that your startup is always acquisition worthy.",
      img:'./../../../assets/images/campaign-flow/blog_3.svg',
      p:[
        {
          a:"No one launches a startup with the intention of selling it within a few years. But deep within, every founder knows that it’s impossible to stick to a plan or a timeline in the uncertain world of startups. The startups are by nature fragile businesses. They are surrounded by threats of technological advancements, changing business climate and intense competition."
        },
        {
          a:"In this scenario, founders should not hesitate from considering an exit strategy, even if the startup is at an early stage. If you feel that things are not going as hoped, finding a buyer should absolutely be in the list of probable solutions."
        },
        {
          a:"But deciding to sell is one thing, finding a buyer is another. It's a tough thing to do, especially for an early stage startup. The right way to scout for acquirers is to think of them as potential customers. You need to be aware of their:"
        },
        {
          ulList:[
            {
              li:'Needs'
            },
            {
              li:"Expectations"
            },
            {
              li:"Challenges"
            },
            {
              li:"Plans"
            },
            {
              li:"Options"
            },
            {
              li:"Goals"
            }
          ]
        },
        {
          a:"There are two options for doing that: "
        },
        {
          ulList:[
            {
              li:"Engage an expert like a startup consultant "
            },
            {
              li:"Conduct your own research by talking to business insiders "
            }
          ]
        },
        {
          a:"Figuring out these aspects will help you identify opportunities worth pursuing. A deep knowledge of the market will also help you prepare the startup for a better valuation. So, if you are preparing for an early exit, here are five points you need to focus on:"
        },
        {
          betweenTitle:"1. Strength of the product"
        },
        {
          a:"A startup is valuable only if it has a feasible solution for a real world problem. That’s why buyers pay utmost attention to the startup’s offering while taking the acquisition call. To make sure that the product is up to the mark, founders should ensure that their offering scores well on quality, value proposition and safety fronts. On top of that, efforts should be made to enhance the demand for the solution. This may be accomplished through a thoroughly considered startup exit strategy."
        },
        {
          betweenTitle:"2. Competitive advantage "
        },
        {
          a:"“If you don’t have a competitive advantage, don’t compete” — Jack Welch"
        },
        {
          a:"In a world with ever rising competition, having an edge over rivals is key to survival. No one else understands this fact better than the corporates. That's the reason why the cliched question 'How is your product different from others in the market?' feature in all acquisition talks. Startups can build their edge in one or more areas of the operation like: "
        },
        {
          ulList:[
            {li:"Speed of delivery"},
            {li:"Price"},
            {
              li:"Technology"
            },
            {
              li:"Design"
            },
            {
              li:"Design"
            },
            {
              li:"Scale"
            },
            {
              li:"Customer service"
            },
            {
              li:"Personalisation"
            }
          ]
        },
        {
          betweenTitle:"3. Money inflow"
        },
        {
          a:"A startup that's generating a good amount of revenue is an easy sale. Many large businesses and PE firms are always on the lookout for such startups. And why shouldn't they be? An investment that's already delivering returns is always less risky than the one that might generate revenues in the future."
        },
        {
          a:"For the acquirer, strong revenue numbers mean the product has passed the validation stage and is ready to be taken to a bigger market. This saves them from the risk of betting on untested solutions. Moreover, revenues make your startup stand out amid a deluge of early stage startups looking for acquirers."
        },
        {
          betweenTitle:"4. Intellectual property"
        },
        {
          a:"Intellectual property can be far more valuable than any business or physical asset. They enhance a startup’s valuation multiple times. Companies value IP assets for a variety of reasons including possibility of exponential rise in its value and the option to derive revenue through licensing. The IP rights also ensure a competition-free market for the startup's products."
        },
        {
          a:"The same is true for data. Operation of any tech platform is sure to generate a plethora of information including the much sought after customer data. This information can be of a lot of value for a company which is looking to expand to the region where the startup is operational."
        },
        {
          betweenTitle:"5. Quality of talent"
        },
        {
          a:"Talent acquisition is a tough process, especially in the tech world. Companies or even established startups require multiple individuals with varied skills to build cohesive teams. And this is not an easy task as one wrong hiring can bring down the efficiency of the whole unit."
        },
        {
          a:"This is why many companies are now considering acquihire as an option. Acquihire a process where companies buy out a startup for the talent of its members. Such companies mostly target early-stage startups as they are looking for people, not product and market share."
        },
        {
          a:"The benefits of acquihire are not limited to the acquiring party. People behind the acquired startup also gain from the transaction."
        },
        {
          ulList:[
            {li:"Acquihire is the best way to secure the future of employees."},
            {li:"The staff is the main winner in this type of talent acquisition since a more established business provides greater job security."},
            {li:"Staff also get to learn from experienced colleagues."},
            {
              li:"Founders get to make a neat exit without any concerns about lingering obligations and responsibilities. "
            }
          ]
        },
        {
          betweenTitle:"How to prep up in all these departments?"
        },
        {
          a:"An early stage startup can never be good in all the departments. A quick search on the internet on M&A deals would tell you that doing well in one aspect is enough for a startup to get acquired."
        },
        {
          a:"Having one strong point - be it a strong team or a good market reach or a unique product - is enough to attract buyer interest."
        },
        {
          a:"Apart from the above five factors, having a good social media presence (both of the founder and the startup) and building contacts by attending conferences and seminars is important to get noticed. A product, however good it may be, cannot get a buyer unless it's marketed to the right audience."
        },
        {
          a:"Finally, it's important to constantly cover these bases. The time to exit can arrive without notice. Doing all of it, from understanding potential acquirers to marketing the startup, can't be done in a short period. Make sure that you stay connected to a startup consultant,your startup has a solid exit strategy and is always acquisition worthy."
        }
      ]
    },
    {
      author:'Sunita Kumari',
      about:'Blog',
      date:'Sept 19, 2022',
      readTime:5,
      blogId:'b004',
      title:'Build-Scale-Exit: Navigating A Entrepreneur’s Startup Journey Strategically',
      shortNote:"Every entrepreneur has a story to tell, about how much effort it takes to build startups, make sure they’re running well and plan suitable business exit strategies, all by studying what the market requires. Here’s an outline of an entrepreneur's journey, from planting the seed of innovation to achieving profits till business exit. ",
      img:'./../../../assets/images/campaign-flow/blog_2.svg',
      p:[
        {
          a:"Life doesn't just start to end. It has its own stages and that is how we check our growth and maturity. Similarly, in business, we have something called the business lifecycle - a process that involves creating concepts, developing them, getting things going, and, if successful, growing and maturing."
        },
        {
          a:"Business is always challenging. Opportunities are expanding along with the market, but so is competition. Merely having business startup ideas in no way is a guarantee for success. One can only succeed at what they do, if they are ready to adapt and change their techniques over time."
        },
        {
          a:"Let's examine the many phases of a business lifecycle and how one can actually succeed on a long term basis"
        },
        {
          betweenTitle:"1. Seed and development"
        },
        {
          a:"The foundation of any business is its idea. Getting business startup ideas are important, but another factor that plays a crucial role is knowing how viable the ideas are. Talking about your ideas to fellow entrepreneurs, family and friends will get you some reality checks. They will give you their opinions, and that can either make or break the idea. It is important to get opinions so that you can be confident in what you’re doing. In a way, it helps you determine whether you have what it takes to make the business startup idea a success."
        },
        {
          betweenTitle:"2. Startups and the exit strategy"
        },
        {
          a:"You reach this phase after you have carefully examined and executed your business startup ideas. Your business is officially launched when the exit strategy plan is in place. This is considered the riskiest part of the business. It is said that the mistakes made in the exit strategy can affect the business many years down the line. It is also thought to be a major reason why 25% of startups fail before reaching their fifth year."
        },
        {
          a:"One important factor that most entrepreneurs face is adaptability. Your business is relatively new, so the feedback from the first few customers is vital. There is no doubt that you might feel you’re changing the entire concept, and it might leave you a bit confused. However, sticking up to the changes and seeing progress will restore your clarity."
        },
        {
          betweenTitle:"3. Growth and establishment"
        },
        {
          a:"At this stage, the business has been established with the right amount of marketing and an apt exit strategy plan has been created. If startups successfully reach this stage, then it is important for them to be generating a consistent source of income."
        },
        {
          a:"It should also be adding customers to the list. Recurring customers add to the revenue and pay for the expenses. Therefore, profits should be increasing and startups should be growing at this stage."
        },
        {
          betweenTitle:"4. Allowing the expansion to happen"
        },
        {
          a:"When the startups reach a certain level of growth, it becomes a daily routine and continues. But, along with growth, it is important that the startups expand their line of products or services."
        },
        {
          a:"Many entrepreneurs think that if the business is doing well, then why expand and take the risk. But if a company is not going forward, then it is going backward. Since the exit strategy is already running well, it is surely an advantage to expand the lines of products and services. Your loyal customers might market your brand as a word of mouth and this might lead to more profits and revenue."
        },
        {
          betweenTitle:"5. Maturity and business exit planning"
        },
        {
          a:"With the company having grown, your profits should be adequate at this point. It should be quite stable with the revenues and expenses. But not all entrepreneurs prefer to stay in business their whole lives. Many feel that they have run out of different exit strategy plans for expansion and it is important to move to another project and sell this to a more deserving person. Some get bored of the daily routine and want some change, so they sell out their startups."
        },
        {
          a:"But another reason could be that the company might have faced losses and the current CEO may not be well-equipped to handle them."
        },
        {
          a:"Starting up a business is certainly not with the intention of keeping it running for life. In today’s culture, change and growth are highly important. Being in business for more than 5–6 years with a steady income is considered to be regressive. Innovation and coming up with new ideas are the modern rules. Many entrepreneurs have a team with whom they work on different exit strategies, and after the company has reached a certain growth level, they sell out the startups."
        }
      ]
    },
    {
      author:'Sunita Kumari',
      about:'Blog',
      date:'Oct 3, 2022',
      readTime:5,
      blogId:'b005',
      title:'Exit is all about timing',
      shortNote:"Developing your business startup ideas, researching every possibility, making a wise decision on the best exit strategy, and providing your startup with a successful business exit are all important in your startup journey and indeed involves a lot of work. But the most important of all is to identify the right time to let go.",
      img:'./../../../assets/images/campaign-flow/blog_5.svg',
      p:[
        {
          a:'Building your own startup is a thrilling experience. Though the journey is full of difficulties, the satisfaction of being able to solve a real-world problem makes the efforts totally worth it. '
        },
        {
          a:'But unfortunately, setting up a startup is not enough, even if it is deemed successful. Everyone associated with the startup has to continue with the gruelling task of expanding the business, while fending off competition from both emerging and well-established rivals.'
        },
        {
          a:'Startups, as a whole, do not have an escape from this. However, founders always have a choice. They can decide to part ways from the business whenever they plan to. But how rewarding that decision would be, depends a lot on the timing.'
        },
        {
          betweenTitle:'How important is timing?'
        },
        {
          a:'The end goal for every founder is a good exit strategy that benefits everybody, including the startup itself. It is this final decision that determines the ultimate gains of founders, investors, and employees. Failing to identify the right exit time can wipe off thousands or even millions of dollars from stakeholders’ wealth. '
        },
        {
          a:'When push comes to shove, it boils down to the founders to make sure that the exit planning is well-timed. The best time to exit is when you have lucrative options, not when the startup starts to struggle. The exit options for startups should always be strategic decisions and not the last resort.'
        },
        {
          betweenTitle:"How to know if it's exit time?"
        },
        {
          ulList:[
            {
              li:"When the startups are at an inflection point"
            }
          ]
        },
        {
          a:"In a startup's journey, there always comes a time when it requires a huge sum of money to stay in the competition. At this inflection point, startup founders only have two options: "
        },
        {
          ulList:[
            {
              div:"1. To raise a substantial amount"
            },
            {
              div:"2. Sell it to someone who has the resources needed to keep it afloat"
            }
          ]
        },
        {
          a:'Now this choice depends on two important questions:'
        },
        {
          ulList:[
            {
              div:'1. Is it possible to raise the money within the desired time frame?'
            },
            {
              div:"2. Will the financing secure the future of the startup for at least a year or so?"
            }
          ]
        },
        {
          a:"It would be futile to raise large amounts of funding if the startup ended up desiring more very soon. Back-to-back funding is difficult to come by."
        },
        {
          ulList:[
            {
              li:"When the ideal situation presents itself"
            }
          ]
        },
        {
          a:'Exit options are always open. Startup founders should always be on the lookout for the right time. This can be done through a regular assessment of market conditions and the prevalent opportunities. Even if your assessment does not favour an exit, there’s no harm in considering an offer if a good one presents itself. If you feel the price offered is the highest the startup can ever attract in the exit planning, it surely deserves serious consideration.'
        },
        {
          ulList:[
            {
              li:"When your business has matured"
            }
          ]
        },
        {
          a:"The later years of startups are not the same as the first few. The excitement ebbs and the monotony starts to set in. Also, the skill set required or even the capacity to pursue talent acquisition to manage a company, is in many ways different from those needed to set it up. If a founder is not enjoying this change in the scenario, it's better to leave the startup in able hands and focus on what they're best at — ie.e. setting up startups. Such a decision would not just benefit the founder, but also the stakeholders."
        },
        {
          ulList:[
            {
              li:"When you're burned out"
            }
          ]
        },
        {
          a:"Even if you enjoy managing startups after setting it up, it doesn't mean you can do it forever. As humans, we all tend to lose the drive after doing the same thing day after day. If you have reached a point where the work and commitment no longer appeal to you, it's better to find a way out. Staying put even when not wanting to can be counterproductive and hurt the interests of investors, co-founders, employees, the business, and you."
        },
        {
          ulList:[
            {
              li:"It's not necessary to stick to the plan"
            }
          ]
        },
        {
          a:"When one forms startups, there’s a vision of where one wants to go with it. In fact, it’s good practice to start a company with the dream of taking it to great heights. But the mind set needs to change a bit once the startup is up and running. The dreams, plans, and strategies need to reflect the current realities after the startup is past the initial stage. One should always do an honest assessment of the business and the prevailing market conditions and adapt a good exit strategy for your startup accordingly."
        },
        {
          a:"Do any of the above points match with your startup’s condition? If yes, begin the exit conversation with us at exit@stratuplogin.com and get expert guidance to find the best deals for your startup."
        }
      ]
    },
    {
      author:'Sanchit Gumidelli',
      about:'Blog',
      date:'Oct 17, 2022',
      readTime:4,
      blogId:'b006',
      title:'Why is it still a smart idea to sell profitable startups?',
      shortNote:"A startup entrepreneur is aware of what it takes to launch a successful company, be it  developing the greatest business startup ideas or creating a solid market exit strategy. Even if startup entrepreneurs have the ambition to stick to their startups, selling one's creation with the best exit strategy is sometimes a better way out. Here’s why.",
      img:'./../../../assets/images/campaign-flow/blog_1.svg',
      p:[
        {
          a:'Most entrepreneurs might feel that sticking to their startups that are successful is the best idea, given the fact that they have put in a lot of research and money into it. '
        },
        {
          a:'They tend to treat it as pure hard work invested in an early stage startup, which is giving them decent enough returns. To keep the business startup ideas going, many entrepreneurs also do look for an increase in returns in comparison to the early stage startup. '
        },
        {
          a:'Often, when startups come to a halt and the founders see no way to maximise the profits, they decide to sell it off. But there are also various other reasons why one would sell a running business or a successfully launched product.'
        },
        {
          a:'Let’s look at three top reasons why selling successfully running startups makes more sense. '
        },
        {
          betweenTitle:'1. Some startup entrepreneurs establish a business with the primary intention of selling it later'
        },
        {
          a:'Yes, you read that right! There are product or business founders who come up with a unique idea or product, research and work hard on it, invest millions of dollars in it, only to sell it off with the best market exit strategy. Such products are not only launched and produced globally, but also succeed in the market.'
        },
        {
          a:'Many may wonder why you would work so hard, conduct so much research, develop innovative products, form a marketing team, and then sell it. This phase or business pattern works best because it is the ‘mother company’ that has a dedicated team with its expertise in research, creativity, and development. '
        },
        {
          a:"This makes it easier for startups to come up with new and competitive products in the market with the best exit strategy and later sell the company, claiming an amount that includes all the investments, and also huge profits as the product is successful in the market."
        },
        {
          betweenTitle:'2. Some startup entrepreneurs sell successful companies as they see no growth in them'
        },
        {
          a:"This is another common reason for business founders to sell their startups. With no growth comes no profits. For founders, launching startups with just one innovative idea and keeping that idea going, forgoing the fact that all ideas need to have build-ups doesn’t work."
        },
        {
          a:"With changing times and technology, more opportunities need to be discovered and used. When a company can no longer see any way to grow or increase profits, it decides that selling off is a better option. They sell their active business or product to a buyer who can help it grow with their knowledge and team."
        },
        {
          betweenTitle:"3. Dropping profits"
        },
        {
          a:'In today’s world, there is something new being launched every single day. For a startup entrepreneur to be stuck at a particular point, with the same product or service, throughout their accomplishment years is considered a waste'
        },
        {
          a:"Profits tend to drop after being stable for a few years. Therefore, selling a company with the best exit strategy, to see it grow and flourish is more important than being stuck on it just because you have started it."
        },
        {
          a:"Selling an already established company or a product may sound like a bad idea. But it is wise when founders explore the chances of grabbing the profits and leaving the market as a better option than struggling to survive. This option is the best way out for companies struggling to make a stand in the market. "
        }
      ]
    }
  ]

  gotoBlogDetail(item){
    this._router.navigate([`/blog/${item.replaceAll(/[^a-zA-Z ]/g,'').replaceAll(" ","-").toLowerCase()}}`]);
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    });
}

}
