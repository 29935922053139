<!-- <app-top-navbar></app-top-navbar> -->
<div class="main-section">
    <section class="exit-section">
        <div class="carousel-left">
            <div>
                <div class="bold-secondary-colored-text">Exits by StartupLogin</div>
                <div class="small-secondary-dark-text">
                    Adding value to successful transitions Successful exits to new pursuits!  Transitions to the next chapter Supporting entrepreneurs and entrepreneurs in India
                </div>
                <!-- <div class="carousel-explore-btn">
                    <button>Explore</button>
                </div> -->
            </div>
        </div>
        <div class="carousel-right">
            <img src="./../../../../assets/images/carousel/exit.png" alt="Growth Through Rewarding Exits" loading="lazy">
        </div>
    </section>

    <section class="securing-exit">
        <div class="exit-left-sec">
            <img src="./../../../../assets/images/exit/securing-exit.png" alt="securing-exit" loading="lazy">
        </div>
        <div class="exit-right-sec">
            <div class="black-small-text">Why Do Startups Need An EXIT?</div>
            <!-- <div class="exit-colored-text">Securing An Exit Is As Important <br> As Securing An Investment</div> -->
            <div class="exit-lightcolored-text">
                The startup ecosystem in India has witnessed a dynamic change over the past decade and has become more conducive to entrepreneurship. This puts our country at the crux of innovation and growth. 
                With this growth, entrepreneurs are often faced with the question:
                When is the right time to exit? What are the growth prospects with exit and without? Where does an exit leave me?
            </div>
            <div class="exit-lightcolored-text">
                StartupLogin facilitates exit strategies for companies with concrete deals and peaceful exits. Our team of experts have dealt with companies at different stages of the startup chain. We are equipped to make data driven decisions, and tailor strategies to increase the likelihood of an acquisition with speed. 
            </div>
            <div class="exit-lightcolored-text">
                We follow a ‘human first’ putting dreams, growth and prosperity at the centre of everything we do.
            </div>

        </div>
    </section>

    <section class="exit-rewarding">
        <div class="exit-rewarding-title">Why are Exits rewarding?</div>
        <div class="reward-structure">
            <div class="single-structure" *ngFor="let reward of exitRewarding">
                <div class="structure-img">
                    <img [src]="reward?.imgUrl" [alt]="reward?.title" loading="lazy">
                </div>
                <div class="structure-title">{{reward?.title}}</div>
                <div class="structure-sub-text">{{reward?.subText}}</div>
            </div>
        </div>
    </section>

    <!-- <section class="best-deal">
        <app-best-deals></app-best-deals>
    </section> -->

    <section class="choose-startup">
        <app-choose-startup
        [chooseArray]="chooseStartupArray"
        [chooseTitle1]="chooseTitle1"
        [chooseTitle2]="secondTitle"></app-choose-startup>
    </section>

    <section class="solution-proposal">
        <div class="solution-left-sec">
            <div class="black-small-text">How does it work?</div>
            <div class="exit-colored-text">Exit As A Solution Proposal To <br> Closure</div>
            <div class="exit-lightcolored-text">Startup login provides a guided and structured assistance throughout all the processes</div>
            <div class="meet-expert-btn">
                <button class="cursor-pointer" (click)="connectNow()">Meet Our Experts</button>
            </div>
        </div>
        <div class="solution-right-sec">
            <img src="./../../../../assets/images/exit/solution-proposal.png" alt="solution-proposal" loading="lazy">
        </div>
    </section>

    <section class="four-steps">
        <div class="success-title">5 Steps For Your  Exit Success</div>
        <div class="base-structure">
            <div class="success-structure">
                <div class="single-success-structure" *ngFor="let story of successStories">
                    <div class="number">{{story?.number}}</div>
                    <div class="story-title">{{story?.title}}</div>
                    <div class="story-sub-text">{{story?.subText}}</div>
                </div>
            </div>
        </div>
    </section>

    <div class="talk-expert-section">
        <img src="./../../../../assets/images/exit/talk-expert.png" alt="">
        <div class="expert-text-btn">
            <div class="expert-text">Talk To Our Exit Expert.<br> Make Your Move Now!</div>
            <div class="expert-btn">
                <button (click)="connectNow()">Connect Our Experts</button>
            </div>
        </div>
    </div>

    <section class="extra-deal-feeder">
        <div class="deal-feeders">
            <div class="feeder-heading">Looking for other approaches?</div>
            <div class="feeder-sub">
                 Book a slot to know more
            </div>
            <div class="know-more-btn">
                <a href="https://calendly.com/sanchit_sl" target="_blank"><button>Book</button></a>
            </div>
        </div>
    </section>

</div>
<!-- <app-footer></app-footer> -->