<div class="main-section">
    <div class="main-top-section">
        <div class="about-heading">
            Facilitating transitions through dedication! Guided by a team of startup professionals with varying experiences from various stages of startups
        </div>
    </div>

    <section class="securing-exit">
        <div class="exit-left-sec">
            <img src="./../../../assets/images/campaign-flow/our-mission/in-the-office/cuate.svg" alt="our mission" loading="lazy">
        </div>
        <div class="exit-right-sec">
            <div class="black-small-text">Our Mission</div>
            <!-- <div class="exit-colored-text">
                India is witnessing a brand new generation of startups, making their presence felt not just in the domestic sphere but also globally. 2021 was a landmark year for Indian startups going public. A total of 11 startups( including 8 unicorns) raised $7+ billion on Indian bourses through public offerings. However while our startup ecosystem is bustling with new ideas and best of intentions, it doesn’t always guarantee success. 
            </div> -->
            <div class="exit-colored-text">
                StartupLogin was conceived the two year to encourage startups at all phases to prepare an exit strategy from the beginning. Lack of scalability, product-market fit, funds and timing are usually the bane of any business. While it can be a painful pill to swallow, preparing a contingency plan, and allowing this plan to influence business decisions will provide a safety net for all the stakeholders involved
            </div>
            <div class="exit-colored-text">
                Founded by a team of startup specialists and investors, StartupLogin offers a variety of exit options for startup entrepreneurs to consider from the get-go. We believe an exit is not an indication of failure but the best incubator for new ideas and entrepreneurship.
            </div>
        </div>
    </section>
<!-- 
    <section class="do-you-know">
        <div class="know-title">Do You Know?</div>
        <div class="know-sub-text">More than 90% of the startups struggle to succeed in what they set out to do and they don’t have proper platform and guide to strategize their exit so that the founders are always taking the best decision for their company.</div>
        <div class="description-colored-text">Technologies:</div>
             <div class="description-colored-card">
                <div class="description-white-text">
                    <div class="list1">
                        <div class="sub">BI/DA/DS/Big Data Companies</div>
                        <div class="sub">Compter Vistion/AI/ML Companies</div>
                        <div class="sub">IT Services</div>
                        <div class="sub">Block Chain</div>
                        <div class="sub">Fintech</div>
                        <div class="sub">Edtech</div>
                        <div class="sub">Event Services</div>
                        <div class="sub">JAVA</div>
                        <div class="sub">PYTHON</div>
                        <div class="sub">CSS</div>
                        <div class="sub">HTML</div>
                        <div class="sub">Node.js</div>
                        <div class="sub">Angular.js</div>
                        <div class="sub">Express.js</div>
                        <div class="sub">Mongo DB</div>
                        <div class="sub">Ruby</div>
                        <div class="sub">Rust</div>
                        <div class="sub">Lua</div>
                        <div class="sub">Groovy</div>
                        <div class="sub">MATLAB</div>
                        <div class="sub">Delphi</div>
                        <div class="sub">SQL</div>
                        <div class="sub">Visual Basic .NET</div>
                        <div class="sub">Kotlin</div>
                        <div class="sub">Haskell</div>
                        <div class="sub">Perl</div>
                        <div class="sub">Powershell</div>
                        <div class="sub">Shell</div>
                        <div class="sub">Scala</div>
                        <div class="sub">Type Script</div>
                        <div class="sub">PHP</div>
                        <div class="sub">Golang (GO)</div>
                        <div class="sub">R</div>
                        <div class="sub">C#</div>
                        <div class="sub">Swift</div>
                        <div class="sub">Java Script</div>
                    </div>
                </div>
            </div>
    </section> -->

    <!-- <section class="solution-proposal">
        <div class="solution-left-sec">
            <div class="black-small-text">How does it work?</div>
            <div class="solution-colored-text"> We initiated Startup Login so that all the startups and enterprises can benefit from this platform which indirectly pushes the whole ecosystem forward.</div>
            <div class="solution-colored-text">
                    As a team that has been active in the ecosystem for about 10 years now, 
                    having in-depth knowledge of the challenges and opportunities, and with a 
                    huge number of partnerships and connections in the industry, we are sure that 
                    we will be able to be of great support to both startups and enterprises.
            </div>
        </div>
        <div class="solution-right-sec">
            <img src="./../../../../assets/images/exit/aboutus-our-solution.png" alt="working progress" loading="lazy">
        </div>
    </section> -->

    <section class="team">
        <div class="team-small-text">Meet our Team</div>
        <div class="team-colored-text">We combine global expertise and local insight to help you plan and execute the right strategy</div>
        <div class="team-members">
            <div class="single-team-member" *ngFor="let team of teamMembers">
                <div class="member-img">
                    <img [src]="team?.imgUrl" [alt]="team.name" loading="lazy">
                </div>
                <div class="member-info">
                    <div class="member-name-icon">
                        <div class="member-name">{{team?.name | titlecase}}</div>
                        <div class="member-icon">
                            <a [href]="team?.linkedIn" target="_blank">
                                <img src="./../../../assets/images/about/linkedIn.svg" alt="linkedIn" loading="lazy">
                            </a>
                        </div>
                    </div>
                    <div class="member-designation">{{team?.designation }}</div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="customer-review">
        <div class="section-background">
            <div class="section-left">
                Our Success <br> stories
            </div>
            <div class="section-right">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let review of customerReview" class="slide">
                        <div class="review">
                            {{review?.review | titlecase}}
                        </div>
                        <div class="customer-name-des-arrow">
                            <div class="customer-name-des">
                                <div class="customer-name">{{review?.customerName | titlecase}}</div>
                                <div class="customer-description">
                                    <span class="designation">- {{review?.designation | uppercase}}</span><span *ngIf="review?.designation || review?.companyName">,</span>
                                    &nbsp;<span class="company">{{review?.companyName | titlecase}}</span>
                                </div>
                            </div>
                            <div class="arrows">
                                <button class="btn-next" (click)="">next</button>
                                <button class="btn-prev" (click)="prev()">prev</button>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </section> -->
    <div class="final-section">
        <div class="final-text">
            Make your move now! Meet our experts and accelerate your growth
        </div>
        <button class="button-text" (click)="talkToExpert()">Talk to an expert</button>
    </div>
</div>
