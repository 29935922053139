import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-case-study-details',
  templateUrl: './case-study-details.component.html',
  styleUrls: ['./case-study-details.component.scss','./mq-case-study-details.component.scss']
})
export class CaseStudyDetailsComponent implements OnInit {

  blogId;
  details:any;

  constructor(
    private _router : Router
  ) { }

  ngOnInit(): void {
    this.blogId = this._router.url.split('/')[2]
    // this.details = this.blogDetails[0]
    this.blogDetails.forEach(element => {
      if(element.blogId === this.blogId){
        this.details = element;
      }
    });
  }
  
  blogDetails = [
    {
      about:'case_study',
      date:'17 Jun, 2021',
      readTime:3,
      author:'Sunita Kumari',
      blogId:'cs001',
      title:'Acqui-hired 5 valuable resources with size of the deal being INR 80 Lakhs',
      aboutClien:"Our client was a US-based leading provider of IT services suite solutions with advanced and emerging technologies in the field of AI and Deep Tech.",
      img:'./../../../assets/images/campaign-flow/case_studies_1.png',
      productText:'SaaS, Video Surveillance and  analtics using AI',
      foundedIn:'2014',
      fundBases:'Bootstrapped',
      revenue:'Life time revenues of INR 20 Lakhs',
      soldIn:'2021',
      clientBrief:'The Client wanted to Acqui-hire niche start-ups in the areas of AI and Computer vision to augment their growth strategies. The focus was on real hands-on experience in emerging technologies with product developement capabilites.',
      solutionSubTitle:'Acquisition',
      solutionDesc:'We are able to identify a hyderabad based technology solution provider who had developed capablities to provide real-time analysis of the video footage system using AI. the company was evaluated solely on its technical capabilities and ability to build product using similar technologies.',
      extrabxs:[
        {
          title:'Asset Acquisition',
          desc:'Acquihire of the team with option to cash out'
        },
        {
          title:'Special Condition',
          desc:'40% hike for tech team(5 members) for the founder a cash out worth 1-yr CTC with 40% hike heavy bonus (founder did not join the acquiring company)'
        }
      ]
    },
    {
      about:'case_study',
      date:'17 Jun, 2021',
      readTime:3,
      author:'Sunita Kumari',
      blogId:'cs002',
      title:'Acquired at a deal value INR 10 Crores excluding stock.Options for the teams',
      aboutClien:"Our client is an NSE listed group company headquartered in france and is in the business of provider of engineering consulting and training services",
      img:'./../../../assets/images/campaign-flow/case_studies_2.png',
      productText:'Web-based IT services horizontal',
      foundedIn:'2018',
      fundBases:'Bootstrapped',
      revenue:'INR 3,00,00,000',
      soldIn:'2021',
      clientBrief:'To Identify and help acquire a company in the IT services business that will accelerate its expansion of operations and at the same time able to increase its existing bandwidth by acquiring talent pool on new age technologies both in india to overseas markets',
      solutionSubTitle:'Acquisition',
      solutionDesc:'We are able to identify a chennai-based 70 member services company in the business of development of web-based nad mobile applications.They were also an IT Services, horizontal and were catering to the  B2B service market',
      extrabxs:[
        {
          title:'Asset Acquisition',
          desc:'Business + Team(Complete Acquisition)'
        },
        {
          title:'Valuation',
          desc:'NPV-DCF with 5 years of projections underconsideration'
        },
        {
          title:'Methodolgy',
          desc:'DCF on 5 years based on current and forecasted boom in IT/ITES services market.'
        },
        {
          title:'Special Condition',
          desc:'30-40% hike for tech team for the founder 40% hike + heavy bonus + stocks'
        }
      ]
    },
    {
      about:'case_study',
      date:'17 Jun, 2021',
      readTime:3,
      author:'Sunita Kumari',
      blogId:'cs003',
      title:'Acquired plus smooth transfer of customer base size of deal INR 70 Lakhs (Excluding equity offered)',
      aboutClien:"Our client is a reputed fast growing company in the business of offereing online redeemable e-Vouchers for large corporates, small and medium enterprises and start-up companies",
      img:'./../../../assets/images/campaign-flow/case_studies_3.png',
      productText:'On-line digital marketing platform',
      foundedIn:'2018',
      revenue:'Approx. INR 50 Lakhs',
      soldIn:'2021',
      model:'B2B, Service',
      fundBases:'None,Bootstrapped',
      status:'Revenue generating with ongoing clientele',
      market:'Sector Expertise(Confidentail on client request)',
      clientBrief:'The specific brief of the client was to acquire talent (Acqui-Hire) that can help bolster its brand through digital marketing and at the same time significantly improve its online presence. the client also wanted to acquire an ongoing online business that would be accretive to its future product lines. Buys vs Build was the direction given to startup Login',
      solutionSubTitle:'Acqui-Hire and Business Acquisition: Target Market Sectors',
      solutionDesc:'We were able to identify an upcoming start-up that was a provider of digital marketing and application development services with over 30 clients across sectors. They were in the digital Marketing space and specialized in online marketing services tailor-made fotr the target sectors', 
      extrabxs:[
        {
          title:'Asset Acquisition',
          desc:'Team Acquisition + Business transfer'
        },
        {
          title:'Special Condition',
          desc:'For team: 30-40% hike for tech team For the founder: 40% hike + Bonus + Equity in the company. High-value clients transferred to the client.'
        }
      ]
    }
  ]

}
