import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './main-components/about-us/about-us.component';
import { LoginConsoleComponent } from './main-components/after-login-scenario/login-console/login-console.component';
import { SigninSignupComponent } from './main-components/signin-signup/signin-signup.component';
import { BlogDetailComponent } from './main-components/blog-detail/blog-detail.component';
import { BlogsComponent } from './main-components/blogs/blogs.component';
import { ContactComponent } from './main-components/contact/contact.component';
import { DealFeedersComponent } from './main-components/deal-feeders/deal-feeders.component';
import { AcquihireComponent } from './main-components/deals/acquihire/acquihire.component';
import { AcquisitionComponent } from './main-components/deals/acquisition/acquisition.component';
import { ExitComponent } from './main-components/deals/exit/exit.component';
import { TalentHireComponent } from './main-components/deals/talent-hire/talent-hire.component';
import { HomeComponent } from './main-components/home/home.component';
import { ListAcquihireComponent } from './main-components/list-deals/list-acquihire/list-acquihire.component';
import { ListTalenthireComponent } from './main-components/list-deals/list-talenthire/list-talenthire.component';
import { AcquihireDetailsComponent } from './main-components/solution-details/acquihire-details/acquihire-details.component';
import { StartupTechTeamComponent } from './main-components/startup-tech-team/startup-tech-team.component';
import { TalenthireDetailsComponent } from './main-components/solution-details/talenthire-details/talenthire-details.component';
import { AuthenticationGuard } from './main-components/global-services/authentication.guard';
import { LaidOffCareersComponent } from './main-components/laid-off-careers/laid-off-careers.component';
import { LaidOffCandidateComponent } from './main-components/laid-off-candidate/laid-off-candidate.component';

//campaign flow

import { CampaignHomeComponent } from './campaign-flow/campaign-home/campaign-home.component'
import { ResourceComponent } from './campaign-flow/resource/resource.component';
import { SolutionsComponent } from './campaign-flow/solutions/solutions.component';
import { CampaignAboutUsComponent } from './campaign-flow/campaign-about-us/campaign-about-us.component'
import { EventDetailsComponent } from './main-components/event-details/event-details.component';
import { CaseStudyDetailsComponent } from './main-components/case-study-details/case-study-details.component';

const routes: Routes = [
  // {
  //   path:'',
  //   component:HomeComponent
  // },
  {
    path:'solutions-exit',
    component:ExitComponent
  },
  {
    path:'solutions-acquisition',
    component:AcquisitionComponent
  },
  {
    path:'solutions-acquihire',
    component:AcquihireComponent
  },
  {
    path:'solutions-talenthire',
    component:TalentHireComponent
  },
  {
    path:'contact',
    component:ContactComponent
  },
  {
    path:'blog',
    component:BlogsComponent
  },
  // {
  //   path:'about-us',
  //   component:AboutUsComponent
  // },
  {
    path:'blog-details/:blog-name',
    component:BlogDetailComponent
  },
  {
    path:'event-details/:event-name',
    component:EventDetailsComponent
  },
  {
    path:'case-study-details/:case-details',
    component:CaseStudyDetailsComponent
  },
  {
    path:'deal-feeders',
    component:DealFeedersComponent
  },
  // {
  //   path:'startup-tech-talent',
  //   component:StartupTechTeamComponent
  // },
  {
    path:'laidoffcareers',
    component:LaidOffCareersComponent
  },
  {
    path:'laidoff-registration',
    component:LaidOffCandidateComponent
  },

  //separate application view
  {
    path:'authentication',
    component:SigninSignupComponent
  },
  {
    path:'list-acquihire',
    component:ListAcquihireComponent
  },
  {
    path:'list-talenthire',
    component:ListTalenthireComponent
  },
  {
    path:'acquihire-deal-details/:displayId',
    component:AcquihireDetailsComponent
  },
  {
    path:'talenthire-deal-details/:displayId',
    component:TalenthireDetailsComponent
  },
  {
    path:'startup-login',
    component:LoginConsoleComponent,
    children:[
      {
        path:'customer-portal',
        loadChildren:() =>
        import('./main-components/after-login-scenario/login-module/login-module.module').then(
          (m) => {return m.LoginModuleModule}),
          canActivate:[AuthenticationGuard]
      }
    ]
  },
  //campaign flow

  // {
  //   path:'',
  //   component:CampaignHomeComponent
  // },
  // {
  //   path:'resource',
  //   component:ResourceComponent
  // },
  // {
  //   path:'solution',
  //   component:SolutionsComponent
  // },
  {
    path:'about-us',
    component:CampaignAboutUsComponent
  },
  {
    path:'',
    loadChildren : () => import('./campaign-flow/landing-module/landing-module.module').then(m => m.LandingModuleModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
