import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalService } from '../../main-components/global-services/global.service';
import { LetsConnectPopupComponent } from '../../shared-components/campaign-flow/lets-connect-popup/lets-connect-popup.component';

@Component({
  selector: 'app-campaign-about-us',
  templateUrl: './campaign-about-us.component.html',
  styleUrls: ['./campaign-about-us.component.scss','./mq-campaign-about-us.component.scss']
})
export class CampaignAboutUsComponent implements OnInit {

  srcWidth;
  srcHeight;

  constructor(
    private _globalService : GlobalService
  ) { }
  
  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":true,
    "autoplay":true,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":false,
    "pauseOnHover":false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  ngOnInit(): void {
    this.getScreenSize()
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }

  teamMembers : any[] = [
    {
      imgUrl:'./../../../assets/images/campaign-flow/sunitha_photo.svg',
      name:'Sunita Kumari',
      designation:'Co-founder & Head of A&A',
      linkedIn:'https://www.linkedin.com/in/sunita-kumari007/'
    },
    {
      imgUrl:'./../../../assets/images/campaign-flow/sanchit.svg',
      name:'Sanchit Gumidelli',
      designation:'Sr. Associate Sourcing',
      linkedIn:'https://www.linkedin.com/in/gumidelli-sanchit-b7a803169/'
    },
    {
      imgUrl:'./../../../assets/images/campaign-flow/ahad_photo.svg',
      name:'Amtul Ahad',
      designation:'Community Lead',
      linkedIn:'https://www.linkedin.com/in/amtulbushra/'
    },
    {
      imgUrl:'./../../../assets/images/campaign-flow/aman.svg',
      name:'Aman Priyadharshi',
      designation:'Associate - Marketing',
      linkedIn:'https://www.linkedin.com/in/aman-priyadarshi-248908204/'
    },
    {
      imgUrl:'./../../../assets/images/campaign-flow/shreya_photo.svg',
      name:'Shreya Sanchita',
      designation:'Talent Acquisition',
      linkedIn:'https://linkedin.com/in/shreya-sanchita'
    },
    {
      imgUrl:'./../../../assets/images/campaign-flow/advaitha_photo.svg',
      name:'Advaitha Dasari',
      designation:'Intern - Deal Operations',
      linkedIn:'https://linkedin.com/in/dasari-advaitha-06b5a71ba'
    },
    {
      imgUrl:'./../../../assets/images/campaign-flow/tarin.svg',
      name:'Tarin Patel',
      designation:'Intern - Community Outreach',
      linkedIn:'https://www.linkedin.com/in/tarinpatel'
    },
    // {
    //   imgUrl:'./../../../assets/images/about/sriram_cropped.jpg',
    //   name:'Siram Sankeerth',
    //   designation:'Associate Sourcing',
    //   linkedIn:'https://www.linkedin.com/in/siram-sankeerth/'
    // },
    {
      imgUrl:'./../../../assets/images/campaign-flow/sanjay.svg',
      name:'Sanjay Enishetty',
      designation:'Board of Director',
      linkedIn:'https://www.linkedin.com/in/sanjayenishetty/'
    },
    {
      imgUrl:'./../../../assets/images/campaign-flow/sriram.svg',
      name:'Sriram Dhanyamraju',
      designation:'Advisor',
      linkedIn:'https://www.linkedin.com/in/sriramdhanyamraju/'
    },
  ];

  customerReview: any [] =[
    {
      review : 'We use Startup Login to acquire established, high-quality startups that operate in the Java and devOps verticals',
      customerName : '',
      designation:'MD',
      companyName:'Corporate Entity'
    },
    {
      review : 'Ours is an edtech unicorn company. We have acquired 20 companies over the last decade. Startup Login helps us find amazing startups to acquire.',
      customerName : '',
      designation:' CEO',
      companyName:'Unicorn Company'
    },
    {
      review : 'Startup Login easily connects me with startup founders looking to sell their business. Through their advisory and consultative approach I was able to acquire a startup that fit perfectly within our company portfolio. I highly recommend Startup Login to anyone looking to buy a tech startup',
      customerName : '',
      designation:'Founder',
      companyName:' Unicorn Company'
    },
    {
      review:"If you have a startup you're thinking about selling, check out Startup Login. If you're looking to buy a startup, they will have what you're looking for!",
      customerName:'',
      designation:' Founder',
      companyName:'High growth startup'
    }
  ];

  talkToExpert(){
    if(this.srcWidth > 474){
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        'auto',
        '45%',
        { data:'TALK_TO_EXPERT' }
      )
    }
    else{
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        '99%',
        '99%',
        { data:'TALK_TO_EXPERT' }
      )
    }
  }

}

