<!-- <app-top-navbar></app-top-navbar> -->
<div class="main-section">
    <section class="exit-section">
        <div class="carousel-left">
            <div>
                <div class="bold-secondary-colored-text">Hire The Right Tech Talent For Your Growth</div>
                <div class="small-secondary-dark-text">Entrepreneurial talent for your organisation</div>
                <div class="small-secondary-dark-text">
                    Startup Talent by StartupLogin
                </div>
                <!-- <div class="carousel-explore-btn">
                    <button>Explore</button>
                </div> -->
            </div>
        </div>
        <div class="carousel-right">
            <img src="./../../../../assets/images/carousel/talent-hire.png" alt="Growth Through Rewarding Exits" loading="lazy">
        </div>
    </section>

    <section class="securing-exit">
        <div class="exit-left-sec">
            <img src="./../../../../assets/images/exit/hiring-talent.png" alt="hiring-talent" loading="lazy">
        </div>
        <div class="exit-right-sec">
            <div class="black-small-text">Discover Full Potential</div>
            <!-- <div class="exit-colored-text">Hiring The Right Talent For Your <br> Company Is Faster And Cost Efficient <br> Than Ever Before</div> -->
            <div class="exit-lightcolored-text">
                Among 23% of failed startups cited an inadequate team as a causative factor to their failure! Everyone in the startup ecosystem is jockeying for position, for funding, and for people. The need for talent acquisition means the HR has to be able to scale rapidly as the company grows, and scout tech and non tech related talent. 
            </div>
            <div class="exit-lightcolored-text">
                StartupLogin stands apart in this field with unique talent acquisition processes and access to networks and potential candidates with similar growth goals. Led by entrepreneurs and talent experts/scouts, our system draws upon aspirants from the technical and creative space. Our access extends to the best ‘Startup’ talent, and not just the best industry talent for the role and assists in quick closure of the recruitment process.
            </div>
            <div class="exit-lightcolored-text">
                Our service offering of acquihire as a solution automatically provides access to experienced and qualified candidates
            </div>
            <div class="exit-lightcolored-text">
                With our ‘human first’ approach, we customise talent solutions for startups in need to make a positive impact
            </div>
        </div>
    </section>

    <section class="exit-rewarding">
        <div class="exit-rewarding-title">Why do you need our specialised <span style="color:#ffdb54">Talent Hire</span> services?</div>
        <div class="reward-structure">
            <div class="single-structure" *ngFor="let reward of exitRewarding">
                <div class="structure-img">
                    <img [src]="reward?.imgUrl" [alt]="reward?.title">
                </div>
                <div class="structure-title">{{reward?.title}}</div>
                <div class="structure-sub-text">{{reward?.subText}}</div>
            </div>
        </div>
    </section>

    <!-- <section class="best-deal" *ngIf="count != 0">
        <app-list-talenthire
        [fromTalenthire]="fromTalenthire"></app-list-talenthire>
        <div class="view-more-btn">
            <button (click)="viewMoreTalenthire()">View More Deals</button>
        </div>
    </section> -->

    <section class="choose-startup">
        <app-choose-startup
        [chooseArray]="chooseStartupArray"
        [chooseTitle1]="chooseTitle1"
        [chooseTitle2]="secondTitle"></app-choose-startup>
    </section>

    <section class="solution-proposal">
        <div class="solution-left-sec">
            <div class="black-small-text">How does it work?</div>
            <div class="exit-colored-text">Acquisition As A Solution Proposal To Closure</div>
            <div class="exit-lightcolored-text">Startup login provides a guided and structured assistance throughout all the processes</div>
            <div class="meet-expert-btn">
                <button class="cursor-pointer" (click)="connectNow()">Meet Our Experts</button>
            </div>
        </div>
        <div class="solution-right-sec">
            <img src="./../../../../assets/images/exit/talent-hire-closure.png" alt="talent-hire" loading="lazy">
        </div>
    </section>

    <section class="four-steps">
        <div class="success-title">4 Steps For Your  Exit Success</div>
        <div class="base-structure">
            <div class="success-structure">
                <div class="single-success-structure" *ngFor="let story of successStories">
                    <div class="number">{{story?.number}}</div>
                    <div class="story-title">{{story?.title}}</div>
                    <div class="story-sub-text">{{story?.subText}}</div>
                </div>
            </div>
        </div>
    </section>

    <div class="talk-expert-section">
        <img src="./../../../../assets/images/exit/talk-expert-light-yellow.png" alt="">
        <div class="expert-text-btn">
            <div class="expert-text">Talk To Our Exit Expert.<br> Make Your Move Now!</div>
            <div class="expert-btn">
                <button (click)="connectNow()">Connect Our Experts</button>
            </div>
        </div>
    </div>

    <section class="extra-deal-feeder">
        <div class="deal-feeders">
            <div class="feeder-heading">Looking for other approaches?</div>
            <div class="feeder-sub">
                 Book a slot to know more
            </div>
            <div class="know-more-btn">
                <button (click)="connectNow()">Know More</button>
            </div>
        </div>
    </section>

</div>
<!-- <app-footer></app-footer> -->