<section class="main_section">
    <div class="details-title-section">
        <div class="left-details">
            <div class="single-row-blog">
                <div class="about-current-blog">{{details?.about.split('_').join(' ')}}</div>
                <div class="single-time-read">
                    <div class="time">{{details?.date}}</div>
                    <div class="vl-line"></div>
                    <div class="read-time">{{details?.readTime}} Mins Read</div>
                </div>
            </div>
            <div class="author">
                Author : {{details?.author}}
            </div>
            <div class="blog-title">
                {{details?.title}}
            </div>
            <div class="short_note_title">About The Client</div>
            <div class="short-note">
                {{details?.aboutClien}}
            </div>
        </div>
        <div class="right-img">
            <img [src]="details?.img" alt="case-study-title" loading="lazy"/>
        </div>
    </div>
    <div class="full_detailed_section">
        <div class="details">
            <div class="all_details">
                <div class="single_details">
                    <div class="product">
                        <div class="product_title">Product : </div> &nbsp;
                        <div class="product_text">{{details?.productText}}</div>
                    </div> 
                    <div class="product">
                        <div class="product_title">Founded In : </div> &nbsp;
                        <div class="product_text">{{details?.foundedIn}}</div>
                    </div>
                    <div class="product">
                        <div class="product_title">Fund Raise : </div> &nbsp;
                        <div class="product_text">{{details?.fundBases}}</div>
                    </div>
                    <div class="product">
                        <div class="product_title">Revenue : </div> &nbsp;
                        <div class="product_text">{{details?.revenue}}</div>
                    </div>
                    <div class="product">
                        <div class="product_title">Sold In :</div> &nbsp;
                        <div class="product_text">{{details?.soldIn}}</div>
                    </div>
                    <div class="product" *ngIf="details?.model">
                        <div class="product_title">Model :</div> &nbsp;
                        <div class="product_text">{{details?.model}}</div>
                    </div>
                    <div class="product" *ngIf="details?.status">
                        <div class="product_title">Status :</div> &nbsp;
                        <div class="product_text">{{details?.status}}</div>
                    </div>
                    <div class="product" *ngIf="details?.market">
                        <div class="product_title">Market :</div> &nbsp;
                        <div class="product_text">{{details?.market}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="client_brief">
            <div class="client_brief_title">
                Client Brief
            </div>
            <div class="client_brief_text">
                {{details?.clientBrief}}
            </div>
        </div>
        <div class="offered_solution">
            <div class="solution_title">
                Solution Offered
            </div>
            <div class="solution_sub_title">
                {{details?.solutionSubTitle}}
            </div>
            <div class="solution_text">
                {{details?.solutionDesc}}
            </div>
        </div>
        <div class="related_boxes">
            <div class="single_box" *ngFor="let item of details?.extrabxs">
                <div class="box_title">{{item?.title}}</div>
                <div class="box_desc">{{item?.desc}}</div>
            </div>
        </div>
    </div>
</section>