import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThankYouComponent } from '../../../campaign-flow/home-module/thank-you/thank-you.component';
import { GlobalService } from '../../../main-components/global-services/global.service';

@Component({
  selector: 'app-lets-connect-popup',
  templateUrl: './lets-connect-popup.component.html',
  styleUrls: ['./lets-connect-popup.component.scss']
})
export class LetsConnectPopupComponent implements OnInit {
  srcWidth;
  srcHeight;
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  loader : boolean = false;

  name = '';
  companyName = '';
  mailId = '';
  phoneNumber = '';
  extra = '';
  newsAlert ;

  constructor(
    public dialogRef: MatDialogRef<LetsConnectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _snackbar : MatSnackBar,
    private _homeService : GlobalService
  ) { 
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  companyDetails = [
    {
      name:'Startup',
      value:'STARTUP'
    },
    {
      name:'Company',
      value:'COMPANY'
    }
  ]

  close(){
    this.dialogRef.close({data:null})
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }

  submit(){
    if(this.emailRegex.test(this.mailId)){
      let data = {
        company:this.companyName,
        desc:this.extra,
        email:this.mailId,
        mobile:this.phoneNumber,
        name:this.name,
        subscribed:this.newsAlert,
        type:this.data.data
      }
      this.loader = true;
      this._homeService.campaignContact(data).subscribe(
        (res:any) => {
          if(this.srcWidth > 475){
            this.dialogRef.close();
            let openPopup = this._homeService.openPopup(
              ThankYouComponent,
              'auto',
              '30%',
              {
                data:''
              }
            );
          }
          else{
            this.dialogRef.close();
            let openPopup = this._homeService.openPopup(
              ThankYouComponent,
              '55%',
              '99%',
              {
                data:''
              }
            )
          }
          this.loader = false;
        },
        (err) => {
          this.loader = false;
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
        }
      )
    }
    else{
      this._snackbar.open(
        'Please Provide valid mailId',
        'Ok',
        {
          duration:1500
        }
      )
    }
  }

}
