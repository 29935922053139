import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { startupStages } from '../../../constants/common-constants';
import { DbServiceService } from '../../global-services/db-service.service';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-list-talenthire',
  templateUrl: './list-talenthire.component.html',
  styleUrls: ['./list-talenthire.component.scss','./mq-list-talenthire.component.scss']
})
export class ListTalenthireComponent implements OnInit {

  @Input() fromTalenthire;
  filterPartBoolean : boolean

  locationControl = new FormControl();
  locationSerach = '';
  locationList = [];
  locationOptions : Observable<any> | any;
  currentUrl ;
  location = '';
  industry = null;
  startupType = '';
  startupStage = null;
  role =  null;
  teamSize = '';
  tabValue = '';
  acquihireDeals : any [] = [];
  dealName = 'TALENTHIRE';
  getAllLoader : boolean = false;
  searchItem = '';
  totalDbCount : number;
  startingLeadNumber : number = 1;
  endingLeadNumber : number;
  totalPages : number;
  activeLeftArrow : boolean = false;
  activeRightArrow : boolean = true;
  goPage : number = 1;
  pageNo = 0;
  size  = 30;
  presentPageNo  = 1;

  tabs : any [] = [
    {
      name:'all deals',
      value:'ALL_DEALS',
      status: false
    },
    {
      name:'saved deals',
      value:'SAVED_DEALS',
      status: false
    },
    {
      name:'interested deals',
      value:'INTERESTED_DEALS',
      status: false
    }
  ]

  suitableRoleArray = [ ];
  industriesArray = [];
  public industryList;
  public suitableRoleList;
  public startuptypeList = startupStages;

  constructor(
    private _router : Router,
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar,
    private _dbService : DbServiceService,
    private _route : ActivatedRoute
  ) {
    this.currentUrl = this._router.url.split("-")[1];
  }

  ngOnInit(): void {
    // this.getAllTalenthire();
    this.getAllIndustries();
    this.checkParams();
    this.getAllRoles();
    if(this.fromTalenthire === undefined){
      this.fromTalenthire = false
    }
    else{

    }
  }
  getAllIndustries(){
    this._globalService.getAllMarket().subscribe(
      (res:any) => {
        this.industriesArray = res.data.map(element => {
          return element = {id:element.marketId,name:element.marketsName}
        })
        this.industryList = this.industriesArray.slice();
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
  checkParams(){
    this._route.queryParams.subscribe(
      (params) => {
        this.pageNo = params['pageNo']-1;
        this.getAllTalenthire();
        this.presentPageNo = params['pageNo'];
        this.goPage = params['pageNo'];
      }
    )
  }
  getAllTalenthire(){
    // console.log(this.fromTalenthire);
    if(this.fromTalenthire){
      this.getAllLoader = true;
      let data={
        module: "TALENT_HIRE",
        page:0,
        size:4
      }
      this._globalService.getAllData(data).subscribe(
        (res:any) => {
          // console.log(res.data);
          this.acquihireDeals = res.data.object;
          this.getAllLoader = false;
        },
        (err) => {
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          );
          this.getAllLoader = false
        }
      )
    }
    else{
      this.getAllLoader = true;
      let data={
        module: "TALENT_HIRE",
        page:this.pageNo,
        size:this.size
      }
      this._globalService.getAllData(data).subscribe(
        (res:any) => {
          // console.log(res.data);
          this.acquihireDeals = res.data.object;
          this.totalDbCount = res.data.count;
          this.totalPages = Math.ceil(this.totalDbCount/this.size);
          this.checkForArrows();
          this.showLeadNumbers();
          this.getAllLoader = false;
        },
        (err) => {
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          );
          this.getAllLoader = false
        }
      )
    }
  }
  getAllRoles(){
    this._globalService.getAllDesination().subscribe(
      (res:any) => {
        this.suitableRoleArray = res.data.map(element => {
          return element = {id:element.designationId,name:element.designationName}
        })
        this.suitableRoleList = this.suitableRoleArray.slice();
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
  getLocations(){
    this._globalService.searchCity(this.locationSerach).subscribe(
      (res:any) => {
        this.locationList = res.data.map(element => {
          return element = {id:element.cityId, cityName:element.cityName, stateName:element.stateName}
        })
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
    this.locationOptions = this.locationControl.valueChanges.pipe(
      startWith(''),
      map(value =>this.locationFilter(value))
    )
  }
  private locationFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.locationList.filter((option: any) => option.cityName.toLowerCase().includes(filterValue));
  }

  selectLocation(ev){
    this.location = ev.cityName;
  }

  clearFilter(){
    this.locationSerach = '';
    this.location = '';
    this.industry = null;
    this.startupStage = '';
    this.role = null;
    this.getAllTalenthire()
  }
  dealDescription(item){
    this._dbService.setPrevUrl(this._router.url.split('?')[0]);
    this._router.navigate([`${(this.dealName).toLowerCase()}-deal-details/${item.displayId}`],{queryParams:{pageNo:this.presentPageNo}});
        window.scroll({
          top:0,
          left:0,
          behavior:'smooth'
        })
  }
  filterLoader : boolean = false;
  applyFilter(){
    this.filterLoader = true;
    let data ={
      location: this.location,
      module: "TALENT_HIRE",
      industry : this.industry,
      stage: this.startupStage,
      role : this.role,
      page:this.pageNo,
      size:this.size
    }
    this._globalService.getAllData(data).subscribe(
      (res:any) => {
        // console.log(res.data);
        if(res.data.object.length != 0){
          this.acquihireDeals = res.data.object
        }
        else{
          this._snackbar.open(
            `No Data Found To Related Filters`,
            'Ok',
            {
              duration:1500
            }
          )
        }
        this.filterLoader = false;
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
        this.filterLoader = false;
      }
    )
  }


  //pagination

  previousPage(){
    this.pageNo --;
    // this._dbService.setPrevClick(true);
    // this._dbService.setNxtClick(false);
    this._router.navigate([`/list-talenthire`],{queryParams:{pageNo:this.pageNo+1}})
  }
  nextPage(){
    // this._dbService.setNxtClick(true);
    // this._dbService.setPrevClick(false);
    this.pageNo ++;
    this._router.navigate([`/list-talenthire`],{queryParams:{pageNo:this.pageNo+1}})
  }
  goToPageNumber(){
    if(this.goPage > this.totalPages || this.goPage === 0){
      this._snackbar.open(
        'Please enter correct page no',
        'Close',
        {
          duration:1500
        }
      )
    }
    else{
      this._router.navigate([`/list-talenthire`],{queryParams:{pageNo:this.goPage}})
    }
  }
  
  checkForArrows(){
    if(this.size == this.totalDbCount || this.size >= this.totalDbCount){
      this.activeRightArrow = false;
      this.activeLeftArrow = false;
    }
    else if(this.size !=  this.totalDbCount){
      if(this.presentPageNo == 1){
        this.activeLeftArrow = false;
        this.activeRightArrow = true;
      }
      else if(this.presentPageNo == this.totalPages){
        this.activeLeftArrow = true;
        this.activeRightArrow = false;
      }
      else{
        this.activeLeftArrow = true;
        this.activeRightArrow = true;
      }
    }
  }

  showLeadNumbers(){
    // if(this._dbService.getNxtClick()){
      //start = ((pageno-1)*pageSize)+1
      if(this.presentPageNo == 1){
        this.startingLeadNumber = 1;
        if(this.size > this.totalDbCount){
          this.endingLeadNumber = this.totalDbCount
        }
        else{
          this.endingLeadNumber = this.size; 
        }
      }
      else if (this.presentPageNo == this.totalPages){
        if(this.acquihireDeals.length != this.size){
          this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1
        }
        else{
          this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1
        }
        this.endingLeadNumber = this.totalDbCount;
      }
      else{
        this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1;
        this.endingLeadNumber = this.startingLeadNumber+this.size-1;
      }
    // }
    // else{
    //   if(this.presentPageNo == 1){
    //     this.startingLeadNumber = 1;
    //     this.endingLeadNumber = this.size;
    //   }
    //   else if (this.presentPageNo == this.totalPages){
    //     if(this.talenthireList.length != this.size){
    //       this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1
    //     }
    //     else{
    //       this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1
    //     }
    //     this.endingLeadNumber = this.totalDbCount;
    //   }
    //   else{
    //     this.startingLeadNumber = ((this.presentPageNo-1)*this.size)+1;
    //     this.endingLeadNumber = this.startingLeadNumber+this.size-1;
    //   }
    // }
  }

}
