<div>
    <div class="top-navbar">
        <app-top-navbar></app-top-navbar>
    </div>
    <section>
        <div class="top-section">
            <div class="back-btn cursor-pointer" (click)="backTo()"><mat-icon>keyboard_backspace</mat-icon>&nbsp;Back</div>
            <!-- <div class="edit-delete cursor-pointer">
                <img src="./../../../../../assets/images/acquihire-deal-details/unsaved-flag.svg" alt="save-talenthire"> save 
            </div> -->
        </div>
        <div class="main-section">
            <div class="bit-details-section">
                <div class="img-company-details">
                    <div class="company-img" *ngIf="singleTalenthire?.candidateProfileInfo.profileImg != null && singleTalenthire?.candidateProfileInfo.profileImg != 'null' ; else noImg">
                        <img [src]="singleTalenthire?.candidateProfileInfo.profileImg" alt="person-logo">
                    </div>
                    <ng-template #noImg>
                        <div class="no-company-img">
                            <div class="no-img">
                                {{singleTalenthire?.candidateProfileInfo.name.slice('')[0] | uppercase}}{{singleTalenthire?.candidateProfileInfo.name.slice('')[1] | uppercase}}
                            </div>
                        </div>
                    </ng-template>
                    <div class="company-details">
                        <div class="company-left-part">
                            <div class="company-title">
                                <img src="./../../../../../../assets/images/talenthire/avatar.svg" alt="avatar-icon">
                                <div class="company-text">
                                    <!-- {{response?.candidateProfileInfo.name}} -->
                                    {{singleTalenthire?.candidateProfileInfo.name ? singleTalenthire?.candidateProfileInfo.name : 'N/A' | titlecase}}
                                </div>
                            </div>
                            <div class="company-mobile">
                                <img src="./../../../../../../assets/images/acquihire/mobile.svg" alt="phone-icon">
                                <div class="company-text">
                                    {{singleTalenthire?.candidateProfileInfo.mobile.mobile}}
                                </div>
                            </div>
                            <div class="company-location">
                                <img src="./../../../../../../assets/images/acquihire/location.svg" alt="location-icon">
                                <div class="location-text">
                                    {{singleTalenthire?.candidateProfileInfo?.addressDetails?.city}}
                                    <span *ngIf="singleTalenthire?.candidateProfileInfo.addressDetails.locality">,
                                        {{singleTalenthire?.candidateProfileInfo?.addressDetails?.locality | titlecase}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="company-right-part">
                            <!-- <div class="dealid-resume"> -->
                                <div class="card-heading cursor-pointer" (click)="markInterested()">
                                    Mark Interested
                                </div>
                                <div class="download-resume-btn cursor-pointer" (click)="markInterested()">
                                    <button>Download Resume</button>
                                </div>
                                <div class="save-flag cursor-pointer" (click)="markInterested()">
                                    <img src="./../../../../../assets/images/acquihire-deal-details/unsaved-flag.svg" alt="save-talenthire">
                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
                <div class="small-description">
                    {{singleTalenthire?.candidateProfileInfo?.desc}}
                </div>
            </div>
            <div class="full-details">
                <div class="left-part">
                    <div class="basic-info-section">
                        <div class="section-title">Work Information</div>
                        <div class="work-info-details">
                            <div class="work-info-left">
                                <div class="light-text-value">
                                    <div class="light-text-heading">Suitable For</div>
                                    <div class="dark-text-value-array" *ngIf="singleTalenthire?.candidateWorkInfo?.roles != null; else nothing">
                                        <div class="items" *ngFor="let item of singleTalenthire?.candidateWorkInfo.roles">
                                            {{item.name}}
                                        </div>
                                    </div>
                                    <ng-template #nothing>
                                        <div class="dark-text-value-array">
                                            N/A
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Years of full-time founder experience</div>
                                    <div class="dark-text-value">
                                        {{singleTalenthire?.candidateWorkInfo?.fullTimeFounderExperience ? singleTalenthire?.candidateWorkInfo?.fullTimeFounderExperience : 'N/A'}}
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Years of non-founder experience</div>
                                    <div class="dark-text-value">
                                        {{singleTalenthire?.candidateWorkInfo?.nonFounderExperience ? singleTalenthire?.candidateWorkInfo?.nonFounderExperience : 'N/A'}}
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Previous startup stage</div>
                                    <div class="dark-text-value">
                                        {{singleTalenthire?.candidateWorkInfo?.previousStartupStage ? (singleTalenthire?.candidateWorkInfo?.previousStartupStage.split('_').join(' ') | titlecase) : "N/A"}}
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Previous startup market</div>
                                    <div class="dark-text-value skills-array" *ngIf="singleTalenthire?.candidateWorkInfo?.previousStartupMarket != null; else nothing">
                                        <div *ngFor="let item of singleTalenthire?.candidateWorkInfo.previousStartupMarket; last as isLast">
                                            {{item.name}}<span *ngIf="!isLast">, &nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="work-info-right">
                                <div class="light-text-value">
                                    <div class="light-text-heading">Skills</div>
                                    <div class="dark-text-value skills-array" *ngIf="singleTalenthire?.candidateWorkInfo?.skills != null; else nothing">
                                       <div *ngFor="let item of singleTalenthire?.candidateWorkInfo.skills; last as isLast">
                                           {{item.name}}<span *ngIf="!isLast">, &nbsp;</span></div>
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Interested Markts</div>
                                    <div class="dark-text-value skills-array" *ngIf="singleTalenthire?.candidateWorkInfo?.interestedMarkets != null; else nothing">
                                        <div *ngFor="let item of singleTalenthire?.candidateWorkInfo.interestedMarkets; last as isLast">
                                            {{item.name}} <span *ngIf="!isLast">, &nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="team-info-section">
                        <div class="section-title">Preferred Information</div>
                        <div class="work-info-details">
                            <div class="work-info-left">
                                <div class="light-text-value">
                                    <div class="light-text-heading">Preferred teams to work in</div>
                                    <div class="dark-text-value skills-array" *ngIf="singleTalenthire?.candidatePrefernceInfo?.preferredTeams != null; else nothing">
                                        <div  *ngFor="let item of singleTalenthire?.candidatePrefernceInfo.preferredTeams; last as isLast">
                                            {{item.split('_').join(' ') | titlecase}} <span *ngIf="!isLast">, &nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Preferred stage of company</div>
                                    <div class="dark-text-value skills-array" *ngIf="singleTalenthire?.candidatePrefernceInfo?.preferredStageOfCompany != null; else nothing">
                                        <div *ngFor="let item of singleTalenthire?.candidatePrefernceInfo.preferredStageOfCompany; last as isLast">
                                            {{item.split("_").join(' ') | titlecase}} <span *ngIf="!isLast">, &nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Preferred anual compensation &#8377;(INR)</div>
                                    <div class="dark-text-value">
                                        {{singleTalenthire?.candidatePrefernceInfo?.annualCompensation ? singleTalenthire?.candidatePrefernceInfo?.annualCompensation+' L' : 'N/A'}}
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Preferred work space</div>
                                    <div class="dark-text-value" *ngIf="singleTalenthire?.candidatePrefernceInfo?.workspace != null; else nothing">
                                        {{singleTalenthire?.candidatePrefernceInfo?.workspace.split('_').join(' ')}}
                                    </div>
                                </div>
                                <div class="light-text-value">
                                    <div class="light-text-heading">Preferred Location</div>
                                    <div class="dark-text-value">
                                        <div *ngIf="singleTalenthire?.candidatePrefernceInfo?.location != null || singleTalenthire?.candidatePrefernceInfo?.location.city != ''">
                                            {{singleTalenthire?.candidatePrefernceInfo?.location.city}} 
                                            <span *ngIf="singleTalenthire?.candidatePrefernceInfo?.location.locality">, &nbsp;{{singleTalenthire?.candidatePrefernceInfo?.location.locality | titlecase}}</span>
                                        </div>
                                        <div *ngIf="singleTalenthire?.candidatePrefernceInfo?.location === null || singleTalenthire?.candidatePrefernceInfo?.location.city === ''">
                                            N/A
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="work-info-right">
                                <div class="light-text-value">
                                    <div class="light-text-heading">Willing to relocate</div>
                                    <div class="dark-text-value">
                                        <div *ngIf="singleTalenthire?.candidatePrefernceInfo?.willingToLocate">Yes</div>
                                        <div *ngIf="!singleTalenthire?.candidatePrefernceInfo?.willingToLocate">No</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-part">
                    <div class="comments-section">
                        <div class="section-title">Comments</div>
                        <div class="comments">
                            <div class="img-comment" *ngFor="let item of singleTalenthire?.candidateProfileInfo.comments">
                                <img src="./../../../../../../assets/images/acquihire/colored-circle.svg" alt="colored-circle">
                                <div class="comment">{{item}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info">
                        <div class="section-title">Contact Information</div>
                        <div class="contact-details">
                            <div class="social-icons">
                                <img src="./../../../../assets/images/acquihire/disabled-twitter.svg" alt="twitter">
                                <img src="./../../../../assets/images/acquihire/disabled-mail.svg" alt="mail">
                                <img src="./../../../../assets/images/acquihire/disabled-linkedin.svg" alt="linkedin">
                                <img src="./../../../../assets/images/acquihire/disabled-call.svg" alt="mobile">
                            </div>
                            <div class="hr-line"></div>
                            <div class="login-scenario">
                                <img src="./../../../../assets/images/acquihire/lock-icon.svg" alt="lock-login">
                                <div class="desc-section">
                                    Login to know more information
                                </div>
                            </div>
                            <div class="login-btn" (click)="downloadSingleResume()">
                                <button>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>