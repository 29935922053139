<!-- <app-top-navbar></app-top-navbar> -->
<div class="main-section">
    <section class="exit-section">
        <div class="carousel-left">
            <div>
                <div class="bold-secondary-colored-text">Acquisitions by StartupLogin</div>
                <div class="small-secondary-dark-text">
                    Delivering growth and realising potentials 
                </div>
                <!-- <div class="carousel-explore-btn">
                    <button>Explore</button>
                </div> -->
            </div>
        </div>
        <div class="carousel-right">
            <img src="./../../../../assets/images/carousel/acquisition.png" alt="Growth Through Rewarding Exits" loading="lazy">
        </div>
    </section>

    <section class="securing-exit">
        <div class="exit-left-sec">
            <img src="./../../../../assets/images/exit/profit.png" alt="securing-exit" loading="lazy">
        </div>
        <div class="exit-right-sec">
            <div class="black-small-text">Build Vs Acquire</div>
            <!-- <div class="exit-colored-text">"Buy Rather Than Build” Is A Smart <br> Strategy For Business That Are <br> Targeting Exponential Growth</div> -->
            <div class="exit-lightcolored-text">
                Buy, rather than build is a strategy that's globally accepted as the route to exponential growth. StartupLogin guides and navigates companies through all the stages of the process, whether they are cross-industry or cross-border deals.
            </div>
            <div class="exit-lightcolored-text">
                With our resources in analytics, trends and market insights, we scout the best route forward for startups and large entities alike. Our turnaround time from onboarding to closure is around 4-6 weeks. Backed by a team of experts, specialists and serial entrepreneurs, StartupLogin serves as an ecosystem to realise dreams and negotiate valuations to allow for lucrative deals keeping growth at its centre!
            </div>
        </div>
    </section>

    <section class="exit-rewarding">
        <div class="exit-rewarding-title">Why does it make sense to <span style="color:#ffdb54">Acquisition?</span></div>
        <div class="reward-structure">
            <div class="single-structure" *ngFor="let reward of exitRewarding">
                <div class="structure-img">
                    <img [src]="reward?.imgUrl" [alt]="reward?.title" loading="lazy">
                </div>
                <div class="structure-title">{{reward?.title | titlecase}}</div>
                <div class="structure-sub-text">{{reward?.subText}}</div>
            </div>
        </div>
    </section>

    <!-- <section class="best-deal">
        <app-best-deals></app-best-deals>
    </section> -->

    <section class="choose-startup">
        <app-choose-startup
        [chooseArray]="chooseStartupArray"
        [chooseTitle1]="chooseTitle1"
        [chooseTitle2]="secondTitle"></app-choose-startup>
    </section>

    <section class="solution-proposal">
        <div class="solution-left-sec">
            <div class="black-small-text">How does it work?</div>
            <div class="exit-colored-text">Acquisition As A Solution Proposal To Closure</div>
            <div class="exit-lightcolored-text">Startup login provides a guided and structured assistance throughout all the processes</div>
            <div class="meet-expert-btn">
                <button class="cursor-pointer" (click)="connectNow()">Meet Our Experts</button>
            </div>
        </div>
        <div class="solution-right-sec">
            <img src="./../../../../assets/images/exit/acquisition-clouser.png" alt="solution-proposal" loading="lazy">
        </div>
    </section>

    <section class="four-steps">
        <div class="success-title">4 Steps For Your  Acquisition Success</div>
        <div class="base-structure">
            <div class="success-structure">
                <div class="single-success-structure" *ngFor="let story of successStories">
                    <div class="number">{{story?.number}}</div>
                    <div class="story-title">{{story?.title}}</div>
                    <div class="story-sub-text">{{story?.subText}}</div>
                </div>
            </div>
        </div>
    </section>

    <div class="talk-expert-section">
        <img src="./../../../../assets/images/exit/talk-expert-light-yellow.png" alt="">
        <div class="expert-text-btn">
            <div class="expert-text">Talk To Our Exit Expert.<br> Make Your Move Now!</div>
            <div class="expert-btn">
                <button (click)="connectNow()">Connect Our Experts</button>
            </div>
        </div>
    </div>

    <section class="extra-deal-feeder">
        <div class="deal-feeders">
            <div class="feeder-heading">Looking for other approaches?</div>
            <div class="feeder-sub">
                 Book a slot to know more
            </div>
            <div class="know-more-btn">
                <button (click)="connectNow()">Know More</button>
            </div>
        </div>
    </section>

</div>
<!-- <app-footer></app-footer> -->