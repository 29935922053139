import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { TopNavbarComponent } from './shared-components/top-navbar/top-navbar.component';
import { FooterComponent } from './shared-components/footer/footer.component';
import { HomeComponent } from './main-components/home/home.component';
import { ImageCarouselComponent } from './shared-components/image-carousel/image-carousel.component';
import { BestDealsComponent } from './shared-components/best-deals/best-deals.component';
import { ExitComponent } from './main-components/deals/exit/exit.component';
import { AcquisitionComponent } from './main-components/deals/acquisition/acquisition.component';
import { AcquihireComponent } from './main-components/deals/acquihire/acquihire.component';
import { TalentHireComponent } from './main-components/deals/talent-hire/talent-hire.component';
import { ChooseStartupComponent } from './shared-components/choose-startup/choose-startup.component';
import { AboutUsComponent } from './main-components/about-us/about-us.component';
import { BlogsComponent } from './main-components/blogs/blogs.component';
import { ContactComponent } from './main-components/contact/contact.component';
import { BlogDetailComponent } from './main-components/blog-detail/blog-detail.component';
import { DealFeedersComponent } from './main-components/deal-feeders/deal-feeders.component';
import { StartupTechTeamComponent } from './main-components/startup-tech-team/startup-tech-team.component';
import { SigninSignupComponent } from './main-components/signin-signup/signin-signup.component';
import { TalenthireDetailsComponent } from './main-components/solution-details/talenthire-details/talenthire-details.component';
import { AcquihireDetailsComponent } from './main-components/solution-details/acquihire-details/acquihire-details.component';
import { ListAcquihireComponent } from './main-components/list-deals/list-acquihire/list-acquihire.component';
import { ListTalenthireComponent } from './main-components/list-deals/list-talenthire/list-talenthire.component';
import { LoginConsoleComponent } from './main-components/after-login-scenario/login-console/login-console.component';
import { SidePanelComponent } from './main-components/after-login-scenario/side-panel/side-panel.component';
import { GlobalInterceptor } from './main-components/global-services/global.interceptor';
import { LaidOffCareersComponent } from './main-components/laid-off-careers/laid-off-careers.component';
import { LaidOffCandidateComponent } from './main-components/laid-off-candidate/laid-off-candidate.component';
import { CampaignTopNavbarComponent } from './shared-components/campaign-flow/campaign-top-navbar/campaign-top-navbar.component';
import { CampaignFooterComponent } from './shared-components/campaign-flow/campaign-footer/campaign-footer.component'
import { CampaignAboutUsComponent } from './campaign-flow/campaign-about-us/campaign-about-us.component';
import { SolutionsComponent } from './campaign-flow/solutions/solutions.component';
import { LetsConnectPopupComponent } from './shared-components/campaign-flow/lets-connect-popup/lets-connect-popup.component';
import { EventDetailsComponent } from './main-components/event-details/event-details.component';
import { CaseStudyDetailsComponent } from './main-components/case-study-details/case-study-details.component';
// import { ResourceComponent } from './campaign-flow/resource/resource.component';

@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    FooterComponent,
    HomeComponent,
    ImageCarouselComponent,
    BestDealsComponent,
    ExitComponent,
    AcquisitionComponent,
    AcquihireComponent,
    TalentHireComponent,
    ChooseStartupComponent,
    AboutUsComponent,
    BlogsComponent,
    ContactComponent,
    BlogDetailComponent,
    DealFeedersComponent,
    StartupTechTeamComponent,
    SigninSignupComponent,
    TalenthireDetailsComponent,
    AcquihireDetailsComponent,
    ListAcquihireComponent,
    ListTalenthireComponent,
    LoginConsoleComponent,
    SidePanelComponent,
    LaidOffCareersComponent,
    LaidOffCandidateComponent,
    CampaignTopNavbarComponent,
    CampaignFooterComponent,
    CampaignAboutUsComponent,
    SolutionsComponent,
    LetsConnectPopupComponent,
    EventDetailsComponent,
    CaseStudyDetailsComponent,
    // ResourceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlickCarouselModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
