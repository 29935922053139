<!-- <app-top-navbar></app-top-navbar> -->
<section class="main-section">
    <div class="details-title-section">
        <div class="left-details">
            <div class="single-row-blog">
                <div class="about-current-blog">{{details?.about}}</div>
                <div class="single-time-read">
                    <div class="time">{{details?.date}}</div>
                    <div class="vl-line"></div>
                    <div class="read-time">{{details?.readTime}} Mins Read</div>
                </div>
            </div>
            <div class="author-details">
                <span class="author-title">Author : </span>
                <span class="author">{{details?.author}}</span>
            </div>
            <div class="blog-title">
                {{details?.blogTitle}}
            </div>
            <div class="short-note">
                {{details?.shortNote}}
            </div>
        </div>
        <div class="right-img">
            <img [src]="details?.img" alt="blog-title" loading="lazy"/>
        </div>
    </div>
    <div class="blog-detail-text-part">
        <div class="details-note">
            <div *ngFor="let item of details?.p">
                <p *ngIf="item?.a">{{item?.a}}</p>
                <div class="between-title" *ngIf="item?.betweenTitle">{{item?.betweenTitle}}</div>
                <div *ngIf="item?.ulList">
                    <div class="ul-list" *ngFor="let list of item?.ulList">
                        <ul>
                            <li *ngIf="list?.li">{{list?.li}}</li>
                            <div *ngIf="list?.div" class="sub-title">{{list?.div}}</div>
                            <div *ngIf="list?.b">{{list?.b}}</div>
                        </ul>
                    </div>
                </div>
                <div class="arrow-points" *ngIf="item?.ap">
                    &#8594; &nbsp;&nbsp; {{item?.ap}}
                </div>
                <!-- <div *ngIf="item?.subt">
                    <p *ngFor="let st of item?.subt">
                        <span class="sub-title">{{item?.subtitle}}</span> <br>
                        <span>{{item?.sd}}</span>
                    </p>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <div class="prev-blogs">
        <div class="remaining-blogs">
            <div class="single-remaining-blog" *ngFor="let item of blogs">
                <div class="single-blog-image">
                    <img [src]="item?.imgUrl" [alt]="item?.aboutBlog" loading="lazy"/>
                </div>
                <div class="single-row-blog">
                    <div class="single-about-current-blog">{{item?.aboutBlog}}</div>
                    <div class="single-time-read">
                        <div class="time">{{item?.date}}</div>
                        <div class="vl-line"></div>
                        <div class="read-time">{{item?.readTime}} Mins Read</div>
                    </div>
                </div>
                <div class="single-blog-title">
                    {{item?.blogTitle | titlecase}}
                </div>
                <div class="single-blog-shortNote">
                    {{item?.shortnote | titlecase}}
                </div>
                <div class="read-more-single" (click)="gotoBlogDetail(item.blogTitle)">Read More &rarr;</div>
            </div>
        </div>
    </div> -->
</section>
<!-- <app-footer></app-footer> -->