import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LetsConnectPopupComponent } from '../../../shared-components/campaign-flow/lets-connect-popup/lets-connect-popup.component';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-talent-hire',
  templateUrl: './talent-hire.component.html',
  styleUrls: ['./talent-hire.component.scss','./mq-talent-hire.component.scss']
})
export class TalentHireComponent implements OnInit {

  chooseTitle1 = 'Why choose'
  secondTitle = 'Startup Login?'
  fromTalenthire = true;
  count;
  srcWidth;
  srcHeight;

  constructor(
    private _router : Router,
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar,
  ) { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }

  ngOnInit(): void {
    let data={
      module: "TALENT_HIRE",
      page:0,
      size:4
    }
    this._globalService.getAllData(data).subscribe(
      (res:any) => {
        // console.log(res.data);
        this.count = res.data.object.length;
      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
      }
    )
  }

  exitRewarding : any [] = [
    {
      imgUrl:'./../../../../assets/images/exit/strategic.svg',
      title:'It is Strategic',
      subText:'Tying up with a partner who understands the technology hiring landscape is critical to identify the right candidates'
    },
    {
      imgUrl:'./../../../../assets/images/exit/smart.svg',
      title:'It is Smart',
      subText:'Selecting from a refined set of candidates rather than sifting through massive databases makes your hiring more time and cost efficient'
    },
    {
      imgUrl:'./../../../../assets/images/exit/opportunity.svg',
      title:'There is Opportunity',
      subText:'Our Acquisition and Acquihire services give us ongoing visibility to ready-to -join candidates across functional domains and skillsets'
    }
  ];

  chooseStartupArray: any[] =[
    {
      imgUrl:'./../../../../assets/images/exit/incredible-outreach.svg',
      title:'Incredible Network',
      subText:'Best candidate pool through vast networks'
    },
    {
      imgUrl:'./../../../../assets/images/exit/find-niche-talent.svg',
      title:'Expert Screening',
      subText:'Attract and assemble high quality talent'
    },
    {
      imgUrl:'./../../../../assets/images/exit/cxo-level-hiring.svg',
      title:'Tailored processes',
      subText:'Assistance from posting to hiring'
    },
    {
      imgUrl:'./../../../../assets/images/exit/accelerate-your-growth.svg',
      title:'Accelerate growth',
      subText:'Through speedy processes and cost efficient solutions '
    }
  ];

  successStories : any [] = [
    {
      number:'01',
      title:'A good job description is the key for expectation setting',
      // subText : 'A good job description is the key for expectation setting'
    },
    {
      number:'02',
      title:'Get profiles of the candidates that are a right match for the JD',
      // subText : 'Get profiles of the candidates that are a right match for the JD'
    },
    {
      number:'03',
      title:'Interview, Test and interact with the shortlisted candidates',
      // subText : 'Interview, Test and interact with the shortlisted candidates'
    },
    {
      number:'04',
      title:'Send the offer letter to the selected candidates',
      // subText : 'Send the offer letter to the selected candidates'
    }
  ];

  viewMoreTalenthire(){
    this._router.navigate(['/list-talenthire'],{queryParams:{pageNo:1}});
  }

  connectNow(){
    // window.scroll({
    //   top:0,
    //   left:0,
    //   behavior:'smooth'
    // })
    // this._router.navigate(['/contact'])
    if(this.srcWidth > 474){
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        'auto',
        '45%',
        { data:'TALK_TO_EXPERT' }
      )
    }
    else{
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        '99%',
        '99%',
        { data:'TALK_TO_EXPERT' }
      )
    }
  }

}
