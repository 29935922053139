<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand cursor-pointer" href="">
        <img src="./../../../assets/images/top-navbar/branding-logo.svg" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a href="" class="nav-link">Home</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Solutions
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item cursor-pointer" *ngFor="let service of service" (click)="redirectUrl(service)">
                <div>
                    <span class="dropdown-text">{{service?.dropdownText | titlecase}}</span>
                </div>
            </a>
          </div>
        </li>
        <li *ngFor="let navItem of navItems" (click)="navItemRedirect(navItem)" class="cursor-pointer" [ngClass]="navItem?.status ? 'nav-item-active' : 'nav-item'">
            <a class="nav-link">{{navItem?.name | titlecase}}</a>
        </li>
      </ul>
      <div class="login-btn" *ngIf="!displayBoolean">
        <button (click)="goToLoginPage()">Login</button>
      </div>
    </div>
  </nav>