<!-- <section class="topnavbar">
    <app-top-navbar></app-top-navbar>
</section> -->

<!-- <section> -->
    <div class="registration-form">
        <div class="form-heading">Laid off Registrations</div>
        <div class="form-sub-text">Fill this form to register your profile to showcased for rehiring opportunities.</div>
        <div class="register-form">
            <div class="actual-form">
                <div class="full-field">
                    <div class="field">
                        <div class="field-heading">Name<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter Your Name" [(ngModel)]="name"/>
                        </mat-form-field>
                    </div>
                    <div class="field">
                        <div class="field-heading">Mail ID<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter Your Email ID" [(ngModel)]="email"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="full-field">
                    <div class="field">
                        <div class="field-heading">Role<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter Your Role" [(ngModel)]="role"/>
                        </mat-form-field>
                    </div>
                    <div class="field">
                        <div class="field-heading">Current CTC<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter Your Current CTC" [(ngModel)]="currentCtc" type="number"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="full-field">
                    <div class="field">
                        <div class="field-heading">Current Location<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter Your Current Location" [(ngModel)]="currentLocation"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="check-box" *ngIf="srcWidth > 475">
                    <mat-checkbox class="example-margin" (change)="checkMe($event)" [(ngModel)]="checked">I'm agreeing to share my profile with other companies for career opportunities.</mat-checkbox>
                </div>
                <div class="check-box-small" *ngIf="srcWidth < 475">
                    <!-- <mat-checkbox class="example-margin" (change)="checkMe($event)" [(ngModel)]="checked">I'm agreeing to share my profile with <br> other companies for career opportunities.</mat-checkbox> -->
                    <input type="checkbox" (change)="checksMe($event)"><span class="text">I'm agreeing to share my profile with other companies for career opportunities.</span>
                </div>
                <div class="send-message">
                    <button [disabled]="name === '' || email === '' || role === '' || currentCtc === '' || currentLocation === '' || !checked"
                    (click)="sendMessage()">
                        <span *ngIf="!loader">Send Message</span>
                        <span *ngIf="loader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
                    </button>
                </div>
            </div>
            <div class="related-img">
                <img src="./../../../assets/images/laid-off-career/registration-form-image.jpg" alt="example-image" loading="lazy">
            </div>
        </div>
    </div>
<!-- </section> -->

<!-- <section class="footer">
    <app-footer></app-footer>
</section> -->