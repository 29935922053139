import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DbServiceService } from '../global-services/db-service.service';
import { GlobalService } from '../global-services/global.service';

@Component({
  selector: 'app-signin-signup',
  templateUrl: './signin-signup.component.html',
  styleUrls: ['./signin-signup.component.scss','./mq-signin-signup.component.scss']
})
export class SigninSignupComponent implements OnInit {
  viewMode = "signIn";
  hide : boolean = true;
  email  = "";
  password = "";
  company  = "";
  fullName  = "";
  srcHeight ;
  srcWidth ;
  signInLoader: boolean = false;
  signUpLoader : boolean = false;

  countryName = '';
  stateName = '';
  buyerType = '';
  checkBox : boolean;
  phoneNumber = '';
  legalEntityName = '';

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  countryList = [];
  stateList = [];
  typeOfBuyersList = [
    {
      name:'Talenthire'
    },
    {
      name:'Acquihire'
    }
  ]

  constructor(
    private _router : Router,
    private _dbService : DbServiceService,
    private _globalService : GlobalService,
    private _snackBar : MatSnackBar
  ) { 
    this.getScreenSize();
  }

  ngOnInit(): void {
    if(this._dbService.getUserId() === null || this._dbService.getUserId() === ''){}
    else{
      this._router.navigate([`/startup-login/customer-portal/talenthire/list-all-deals`]);
    }
    this._globalService.getAllCountryStates().subscribe(
      (res:any) => {
        // console.log(res.data);
        this.countryList = res.data
      }
    );    
  }
  selectCountry(ev){
    if(ev.states.length != 0){
      this.stateList = ev.states;
    }
    else{
      this.stateList = [];
      this._snackBar.open(
        `No states found to related country`,
        'Close',
        {
          duration:1500
        }
      )
    }
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  changeViewMode(value){
    this.viewMode = value;
  }
  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3) ])
  });
  get passwordInput() { return this.signin.get('password'); }

  signIn(){
    this.signInLoader = true;
    let data = {
      userName : this.email,
      password : this.password
    }
    this._globalService.signIn(data).subscribe(
      (res:any) => {
        // console.log(res.data);
        this._dbService.setToken(res.data.token);
        this._dbService.setUserId(res.data.user.userId);
        this._dbService.setUserName(res.data.user.userName);
        // if(this._dbService.getPrevUrl()){
        //   this._router.navigate([`${this._dbService.getPrevUrl()}`])
        // }
        // else{
          this._router.navigate([`/startup-login/customer-portal/talenthire/list-all-deals`],{queryParams:{pageNo:1}});
        // }
        this.signInLoader = false;
      },
      (err) => {
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
        this.signInLoader = false;
      }
    )
  }
  signUp(){
    this.signInLoader = true;
    let data = {
      buyerType: this.buyerType,
      country : this.countryName,
      email : this.email,
      entityName : this.legalEntityName,
      mobile:{
        countryCode:'91',
        mobile:this.phoneNumber
      },
      password:this.password,
      state: this.stateName,
      usertype : 'USER',
      userName : this.fullName
    }
    this._globalService.signUp(data).subscribe(
      (res:any) => {
        // console.log(res);
        let signInData = {
          userName : this.email,
          password : this.password
        }
        this._globalService.signIn(signInData).subscribe(
          (res:any) => {
            this._dbService.setToken(res.data.token);
            this._dbService.setUserId(res.data.user.userId);
            this._dbService.setUserName(res.data.user.userName);
            // if(this._dbService.getPrevUrl()){
            //   this._router.navigate([`${this._dbService.getPrevUrl()}`])
            // }
            // else{
              this._router.navigate([`/startup-login/customer-portal/talenthire/list-all-deals`],{queryParams:{pageNo:1}});
            // }
            this.signInLoader = false;
          },
          (err) => {
            this._snackBar.open(
              `${err.error.message}`,
              'Close',
              {
                duration:1500
              }
            );
            this.signInLoader = false;
          }
        )
      },
      (err) => {
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }

}
