export const relocate = [
    {
        name:'yes',
        value:true
    },
    {
        name:'no',
        value:false
    }
];

export const gender = [
    {
        name:'male',
        value:'MALE'
    },
    {
        name:'female',
        value:'FEMALE'
    }
];

export const employmentType =[
    {name:'full time',value:'FULL_TIME'},
    {name:'part time',value:'PART_TIME'}
];
export const staffRoles = [
    {name:'staff', value:'STAFF'},
    {name:'admin', value:'ADMIN'}
]
export const startupStages = [
    {name:'pre seed funding',value:'PRE_SEED_FUNDING'},
    {name:'seed funding', value:'SEED_FUNDING'},
    {name:'series a funding', value :'SERIES_A_FUNDING'},
    {name:'series b funding', value:'SERIES_B_FUNDING'},
    {name:'series c funding', value : 'SERIES_C_FUNDING'}
]
export const preferredTeam = [
    {name:'growth',value:'GROWTH'},
    {name:'product',value:'PRODUCT'},
    {name:'marketing',value:'MARKETING'},
    {name:'talent management',value:'TALENT_MANAGEMENT'},
    {name:'business pl',value:'BUSINESS_PL '}
]
export const workSpace = [
    {name:'on site',value:'ON_SITE'},
    {name:'remote location',value:'REMOTE_LOCATION'}
]
export const keyValues = [
    {name:'linkedIn', value:'LINKEDIN'},
    {name:'git',value:'GIT'}
]