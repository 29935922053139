<section>
    <div class="heading-cross">
        <div class="sec-heading"></div>
        <div class="cross-icon">
            <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="main-section">
        <img src="./../../../../assets/images/campaign-flow/check-mark.svg" alt="">
        <div class="big_text">
            Thank you for reaching out to us
        </div>
        <div class="small_text">
            One of our team member will get back to you soon
        </div>
    </div>
</section>