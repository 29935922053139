import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-laid-off-careers',
  templateUrl: './laid-off-careers.component.html',
  styleUrls: ['./laid-off-careers.component.scss']
})
export class LaidOffCareersComponent implements OnInit {

  constructor(
    private _snackbar: MatSnackBar,
    private _router : Router
  ) { }

  ngOnInit(): void {
  }

  job= [
    {
      title:'Engineering',
      count:120
    },
    {
      title:'Business Opration',
      count:20
    },
    {
      title:'CX & Support',
      count:90
    },
    {
      title:'Design',
      count:87
    },
    {
      title:'Marketing',
      count:21
    }
  ];

  addMe(){
    this._router.navigate(['/laidoff-registration']);
  }

}
