<!-- <app-top-navbar></app-top-navbar> -->
<div class="main-section">
    <section class="exit-section">
        <div class="carousel-left">
            <div>
                <div class="bold-secondary-colored-text">Acquihire by StartupLogin</div>
                <div class="small-secondary-dark-text">Growth defined by people</div>
                <!-- <div class="carousel-explore-btn">
                    <button>Explore</button>
                </div> -->
            </div>
        </div>
        <div class="carousel-right">
            <img src="./../../../../assets/images/carousel/acquihiring.png" alt="Growth Through Rewarding Exits" loading="lazy">
        </div>
    </section>

    <section class="securing-exit">
        <div class="exit-left-sec">
            <img src="./../../../../assets/images/exit/acquihiring-talent-include.png" alt="securing-exit" loading="lazy">
        </div>
        <div class="exit-right-sec">
            <div class="black-small-text">Plug-N-Play Talent Vs An Elongated Hiring Process</div>
            <!-- <div class="exit-colored-text">Acquihiring Talent Including Cutting <br> Edge & Niche Skills Is A Smart Way Of <br>
                 Building Organizational Capability</div> -->
            <div class="exit-lightcolored-text">
                Brands are built by people. Their skills, dreams and potential propels a company to new heights. With the changing tech landscape, more companies are looking for an agile technically equipped workforce. 
                By acquiring tried and tested talent with this model, enterprises save time in upskilling, training and onboarding.
            </div>
            <div class="exit-lightcolored-text">
                Acquiring is an opportunity for company growth. It also helps enter a new market or develop something new, using the expertise of acquired employees.
            </div>
            <div class="exit-lightcolored-text">
                StartupLogin facilitates deals which ensure success ranging from personal growth for founders and teams to negotiating deals.
            </div>
        </div>
    </section>

    <section class="exit-rewarding">
        <div class="exit-rewarding-title">Why does it make sense to <span style="color:#ffdb54">Acquihire?</span></div>
        <div class="reward-structure">
            <div class="single-structure" *ngFor="let reward of exitRewarding">
                <div class="structure-img">
                    <img [src]="reward?.imgUrl" [alt]="reward?.title">
                </div>
                <div class="structure-title">{{reward?.title}}</div>
                <div class="structure-sub-text">{{reward?.subText}}</div>
            </div>
        </div>
    </section>

    <!-- <section class="best-deal" *ngIf="count != 0">
        <app-list-acquihire
        [from]="from"
        [fromAcquihire]="fromAcquihire"></app-list-acquihire>
        <div class="view-more-btn">
            <button (click)="viewMoreAcquihire()">View More Deals</button>
        </div>
    </section> -->

    <section class="choose-startup">
        <app-choose-startup
        [chooseArray]="chooseStartupArray"
        [chooseTitle1]="chooseTitle1"
        [chooseTitle2]="secondTitle"></app-choose-startup>
    </section>

    <section class="solution-proposal">
        <div class="solution-left-sec">
            <div class="black-small-text">How does it work?</div>
            <div class="exit-colored-text">Acquihire Is A Solution Proposal To Acquirees</div>
            <div class="exit-lightcolored-text">Startup login provides a guided and structured assistance throughout all the processes</div>
            <div class="meet-expert-btn">
                <button class="cursor-pointer" (click)="connectNow()">Meet Our Experts</button>
            </div>
        </div>
        <div class="solution-right-sec">
            <img src="./../../../../assets/images/exit/acquihire-closure.png" alt="solution-proposal" loading="lazy">
        </div>
    </section>

    <section class="four-steps">
        <div class="success-title">3 Steps For Your  Acquihire Success</div>
        <div class="base-structure">
            <div class="success-structure">
                <div class="single-success-structure" *ngFor="let story of successStories">
                    <div class="number">{{story?.number}}</div>
                    <div class="story-title">{{story?.title}}</div>
                    <div class="story-sub-text">{{story?.subText}}</div>
                </div>
            </div>
        </div>
    </section>

    <div class="talk-expert-section">
        <img src="./../../../../assets/images/exit/talk-expert-light-yellow.png" alt="">
        <div class="expert-text-btn">
            <div class="expert-text">Talk To Our Exit Expert.<br> Make Your Move Now!</div>
            <div class="expert-btn">
                <button (click)="connectNow()">Connect Our Experts</button>
            </div>
        </div>
    </div>

    <section class="extra-deal-feeder">
        <div class="deal-feeders">
            <div class="feeder-heading">Looking for other approaches?</div>
            <div class="feeder-sub">
                 Book a slot to know more
            </div>
            <div class="know-more-btn">
                <button (click)="connectNow()">Know More</button>
            </div>
        </div>
    </section>

</div>
<!-- <app-footer></app-footer> -->