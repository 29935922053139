import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalService } from '../../../main-components/global-services/global.service';

@Component({
  selector: 'app-campaign-footer',
  templateUrl: './campaign-footer.component.html',
  styleUrls: ['./campaign-footer.component.scss','./mq-campaign-footer.component.scss']
})
export class CampaignFooterComponent implements OnInit {

  email:'';
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  constructor(
    private _router : Router,
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar
  ) { }

  ngOnInit(): void {
  }
  services : any [] = [
    {
      name:'exit',
      redirectingUrl:'/solutions-exit'
    },
    {
      name:'Acquisition',
      redirectingUrl:'/solutions-acquisition'
    },
    {
      name:'Startup Talent',
      redirectingUrl:'/solutions-talenthire'
    },
    {
      name:'Acqui-Hire',
      redirectingUrl : '/solutions-acquihire'
    },
    {
      name:'Deal Feeders',
      redirectingUrl:'/deal-feeders'
    },
    {
      name:'Lay Off Opportunities',
      redirectingUrl:'/laidoffcareers'
    }
  ];
  company : any [] = [
    {
      name:'About'
    },
    // {
    //   name:'Contact'
    // },
    // {
    //   name:'Blog'
    // }
  ];
  connect : any[] =[
    {
      name:'linkedin',
      imgUrl:'./../../../assets/images/footer/linkedin.svg'
    },
    {
      name:'facebook',
      imgUrl:'./../../../assets/images/footer/facebook.svg'
    },
    {
      name:'twitter',
      imgUrl:'./../../../assets/images/footer/twitter.svg'
    }
  ];
  resources=[
    {
      name:'blogs',
    },
    {
      name:'case studies'
    },
    {
      name:'events'
    }
  ]

  gotoService(item,i){
    this.services.forEach((element,index) => {
      if(i === index){
        this._router.navigate([`${element.redirectingUrl}`]);
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      }
    });
  }
  gotoCompany(item){
    // this._router.navigate([`/`])
    console.log(item);
    if(item.name === 'About'){
      this._router.navigate([`about-us`])
    }
    else{
      this._router.navigate([`${item.name.toLowerCase()}`])
    }
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });   
  }

  alertMe(){
    if(this.emailRegex.test(this.email)){
      let data ={
        email : this.email
      }
      this._globalService.createNewAlert(data).subscribe(
        (res:any) => {
          this.email = '';
          this._snackbar.open(
            `Thank You For Subscribe`,
            'Ok',
            {
              duration:1500
            }
          )
        },
        (err) => {
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
        }
      )
    }
    else{
      this._snackbar.open(
        `Please Provide Proper Mail Id`,
        'Ok',
        {
          duration:1500
        }
      )
    }
  }
  goToResources(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });   
    this._router.navigate(['/resource'])
  }

}
