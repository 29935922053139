import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-best-deals',
  templateUrl: './best-deals.component.html',
  styleUrls: ['./best-deals.component.scss','./mq-best-deals.component.scss']
})
export class BestDealsComponent implements OnInit {
  currentUrl = '';
  @Input() acquihireDeals;

  constructor(
    private _router : Router
  ) {}

  technologies = ['mobile application','web application','blockchain development']

  ngOnInit(): void {
    this.currentUrl = this._router.url.split("/")[1];
    this.bestDeals = this.acquihireDeals;
    // console.log(this.acquihireDeals);
  }

  bestDeals : any [] = [];

  dealDescription(item){
    this.bestDeals.forEach(element => {
      if(item?.cardHeading === element?.cardHeading){
        this._router.navigate([`${element.cardHeading}-deal-details/${element.displayId}`.toLowerCase()]);
        window.scroll({
          top:0,
          left:0,
          behavior:'smooth'
        })
      }
    });
  }

}
