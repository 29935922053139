import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  getAllCountryStates(){
    return this.http.get(
      `./../../assets/local_jsons/contry_states.json`
    )
  }

  signUp(data){
    return this.http.post(
      environment.baseUrl+`/user/sign-up`,data
    )
  }
  signIn(data){
    return this.http.post(
      environment.baseUrl+`/login/`,data
    )
  }
  getAllData(data){
    return this.http.post(
      environment.baseUrl+`/user/data`,data
    )
  }
  viewTalenthire(displayId){
    return this.http.get(
      environment.baseUrl+`/talenthire/get/candidate?displayId=${displayId}`
    )
  }

  getAllMarket(){
    return this.http.get(
      environment.baseUrl+`/master/getAll/markets`
    )
  }
  searchCity(searchItem){
    return this.http.get(
      environment.baseUrl+`/master/getAll/city?name=${searchItem}`
    )
  }
  getAllDesination(){
    return this.http.get(
      environment.baseUrl+`/master/getAll/designations`
    )
  }
  getDummyAcquihire(){
    return this.http.get(
      environment.baseUrl+`/acquihire/company/search`
    )
  }
  getOneAcquihire(id){
    return this.http.get(
      environment.baseUrl+`/acquihire/company?displayId=${id}`
    )
  }
  getAllTechnology(){
    return this.http.get(
      environment.baseUrl+`/master/getAll/technology`
    )
  }
  getAllServices(){
    return this.http.get(
      environment.baseUrl+'/master/getAll/services'
    )
  }

  creteContact(data){
    return this.http.post(
      environment.baseUrl+`/website/createContact`,data
    )
  }

  candidateRegistrationForm(data){
    return this.http.post(
      environment.baseUrl+`/website/candidateForm`,data
    )
  }

  createNewAlert(data){
    return this.http.post(
      environment.baseUrl+`/website/createNewAlert`,data
    )
  }

  createDealFeeder(data){
    return this.http.post(
      environment.baseUrl+`/website/createDealFeeders`,data
    )
  }

  getDealFeederOnDate(date){
    return this.http.get(
      environment.baseUrl+`/website/getListOnDate?createdTimeStamp=${date}`
    )
  }
  openSnack(message, action) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openPopup(componentName, height, width, dataModel) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vh'
    });
  }


  campaignContact(data){
    return this.http.post(
      environment.baseUrl+`/website/campaign`,data
    )
  }
}
