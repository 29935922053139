<!-- <section class="signin-signup">
    <div class="topnavbar">
        <app-top-navbar></app-top-navbar>
    </div>
    <div class="main-section">
        <div class="right-section">
            <div [ngSwitch]="viewMode" [ngClass]="viewMode === 'signIn' ? 'view-mode' : 'signup-view-mode'">
                <div *ngSwitchCase="'signIn'">
                    <div class="flow-heading">Welcome Back</div>
                    <div class="flow-sub-heading">Please enter your login credentials</div>
                    <div >
                        <div class="field">
                            <div class="label">Email</div>
                            <mat-form-field class="sfull-width" appearance="outline">
                                <input matInput  autocomplete="off" placeholder="Enter your Mail Id"/>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <div class="label">Password</div>
                            <mat-form-field class="sfull-width" appearance="outline">
                                <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password" (keyup.enter)="signIn()"
                                placeholder="Enter your password"/>
                                <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="recovery cursor-pointer">Forgot Password?</div>
                    <div class="field-btn">
                        <button class="signin-btn" [disabled]="email === '' || password === ''" 
                        (click)="signIn()"><span *ngIf="!signInLoader">Sign In</span>
                    <span *ngIf="signInLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span></button>
                    </div>
                    <div class="ask-signup" *ngIf="viewMode==='signIn'">If you don't have an account ? <span class="register"
                        (click)="changeViewMode('signUp')">Register here</span></div>
                </div>
                <div *ngSwitchCase="'signUp'">  
                    <div class="signup-section">
                        <div class="flow-heading">Sign Up</div>
                        <hr class="hr-line" />
                        <div class="signup-text">Create account now and get 5 contact details free</div>
                        <div class="signup-form">
                            <mat-form-field class="sfull-width" appearance="outline">
                                <mat-label>Full Name</mat-label>
                                <input matInput [(ngModel)]="fullName" autocomplete="off" required/>
                            </mat-form-field>
                            <mat-form-field class="sfull-width" appearance="outline">
                                <mat-label>Enter Email Id</mat-label>
                                <input matInput [(ngModel)]="email" [pattern]="emailPattern" autocomplete="off" #emailCheck="ngModel" required/>
                            </mat-form-field>
                            <mat-form-field class="sfull-width" appearance="outline">
                                <mat-label>Company</mat-label>
                                <input matInput [(ngModel)]="company"autocomplete="off" required/>
                            </mat-form-field>
                            <mat-form-field class="sfull-width" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput name="password" [(ngModel)]="password" [type]="hide ? 'password' : 'text'" required/>
                                <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-submit-btn">
                        <button class="signin-btn" [disabled]="fullName === '' || email === '' || company === '' || password === ''"
                        (click)="signUp()">
                       <span *ngIf="!signUpLoader">Sign Up</span>
                    <span *ngIf="signUpLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span></button>
                    </div>
                    <div class="ask-signin" *ngIf="viewMode === 'signUp'">Already a member of Aquire Lead ? <span class="register" (click)="changeViewMode('signIn')">Sign-In here!</span></div>
                </div>
                <div *ngIf="srcWidth < 475 && viewMode === 'signUp'" class="sm-ask-signup"
                    (click)="changeViewMode('signIn')">Already Member of LeadGen? <span class="register">Sign In here</span></div>
            </div>
        </div>
    </div>
</section> -->
<section>
    <div class="top-navbar">
        <app-top-navbar></app-top-navbar>
    </div>
    <div class="signin-signup-flow">
        <!-- durga prasad -->
        <div [ngSwitch]="viewMode" [ngClass]="viewMode === 'signIn' ? 'view-mode' : 'signup-view-mode'">
            <div *ngSwitchCase="'signIn'">
                <div class="flow-heading">Welcome Back</div>
                <div class="flow-sub-heading">Please enter your login credentials</div>
                <div class="signin-form">
                    <div class="field">
                        <div class="label">Email</div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput  autocomplete="off" placeholder="Enter your Mail Id" [(ngModel)]="email"/>
                        </mat-form-field>
                    </div>
                    <div class="field">
                        <div class="label">Password</div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password" (keyup.enter)="signIn()"
                            placeholder="Enter your password"/>
                            <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="recovery cursor-pointer">Forgot Password?</div>
                <div class="field-btn">
                    <button class="signin-btn" [disabled]="email === '' && password === ''" 
                    (click)="signIn()"><span *ngIf="!signInLoader">Sign In</span>
                <span *ngIf="signInLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span></button>
                </div>
                <div class="ask-signup" *ngIf="viewMode==='signIn'">Don't have account ? <span class="register"
                    (click)="changeViewMode('signUp')">Sign up now</span></div>
            </div>
            <div *ngSwitchCase="'signUp'">  
                <div class="signup-section">
                    <div class="flow-heading">Create Account</div>
                    <div class="flow-sub-heading">Please enter your details to create new account</div>
                    <div class="signup-form">
                        <div class="super-field">
                            <div class="sub-field">
                                <div class="label">Name</div>
                                <mat-form-field class="sfull-width" appearance="outline">
                                    <input matInput  autocomplete="off" placeholder="Enter your name" [(ngModel)]="fullName"/>
                                </mat-form-field>
                            </div>
                            <div class="sub-field">
                                <div class="label">Email</div>
                                <mat-form-field class="sfull-width" appearance="outline">
                                    <input matInput  autocomplete="off" placeholder="Enter Mail Id" [(ngModel)]="email"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="super-field">
                            <div class="sub-field">
                                <div class="label">Phone No.</div>
                                <mat-form-field class="sfull-width" appearance="outline">
                                    <input matInput  autocomplete="off" placeholder="Enter Phone No." [(ngModel)]="phoneNumber"/>
                                </mat-form-field>
                            </div>
                            <div class="sub-field">
                                <div class="label">Password</div>
                                <mat-form-field class="sfull-width" appearance="outline">
                                    <input matInput name="password" [(ngModel)]="password" [type]="hide ? 'password' : 'text'" required
                                    placeholder="Enter your password"/>
                                    <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    <mat-hint>Must Include atleast 8 characters</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="super-field">
                            <div class="super-sub-field">
                                <div class="label">Legal Entity Name</div>
                                <mat-form-field class="sfull-width" appearance="outline">
                                    <input matInput  autocomplete="off" placeholder="Enter Legal Entity Name" [(ngModel)]="legalEntityName"/>
                                </mat-form-field>
                            </div>
                            <div class="sub-field">
                                <div class="label">Registered Country</div>
                                <mat-form-field class="sfull-width-dropdown" appearance="outline">
                                    <mat-select [(ngModel)]="countryName">
                                        <mat-option value="">--Select--</mat-option>
                                        <ng-container *ngFor="let country of countryList">
                                            <mat-option [value]="country.name" (onSelectionChange)="selectCountry(country)">
                                                {{country.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="super-field">
                            <div class="sub-field">
                                <div class="label">Registered State</div>
                                <mat-form-field class="sfull-width-dropdown" appearance="outline">
                                    <mat-select [(ngModel)]="stateName" [disabled]="countryName === ''">
                                        <mat-option value="">--Select--</mat-option>
                                        <ng-container *ngFor="let state of stateList">
                                            <mat-option [value]="state.name">
                                                {{state.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="sub-field">
                                <div class="label">Type of Buyer</div>
                                <mat-form-field class="sfull-width-dropdown" appearance="outline">
                                    <mat-select [(ngModel)]="buyerType">
                                        <mat-option value="">--Select--</mat-option>
                                        <ng-container *ngFor="let buyer of typeOfBuyersList">
                                            <mat-option [value]="buyer.name">
                                                {{buyer.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="check-fields">
                        <mat-checkbox class="example-margin" [(ngModel)]="checkBox"
                        ></mat-checkbox>
                       <div class="check-text">
                        I Agree to <span style="color:#FDD959">Non-Disclosure Agreement</span>
                       </div>
                    </div>
                    <div class="field-signup-btn">
                        <button class="signup-btn" [disabled]="!checkBox || 
                        fullName === '' || email === '' || phoneNumber === '' || password === '' || countryName === ''
                        || legalEntityName === '' || stateName === '' || buyerType === ''" (click)="signUp()">
                            <span *ngIf="!signInLoader">Sign Up</span>
                            <span *ngIf="signInLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
                        </button>
                    </div>
                    <div class="ask-signin" *ngIf="viewMode === 'signUp'">Already have account? <span class="register" (click)="changeViewMode('signIn')">Sign In now</span></div>
                </div>
            </div>
        </div>
    </div>
</section>