<!-- <app-top-navbar></app-top-navbar> -->
<section class="main-section">
    <div class="details-title-section">
        <div class="left-details">
            <div class="single-row-blog">
                <div class="about-current-blog">{{details?.about}}</div>
                <div class="single-time-read">
                    <div class="time">{{details?.date}}</div>
                    <div class="vl-line"></div>
                    <div class="read-time">{{details?.readTime}} Mins Read</div>
                </div>
            </div>
            <!-- <div class="author-blog">
                <span class="author-title">Author - </span> 
                <span class="author">{{details?.author}}</span>
            </div> -->
            <div class="blog-title">
                {{details?.title}}
            </div>
            <div class="short-note" *ngIf="blogId != 'e001' && blogId != 'e002'">
                {{details?.shortnote}}
            </div>
            <div class="short-note" *ngIf="blogId === 'e001'">
                The gender gap in the tech community is a highly relevant topic that has been drawing a lot of rightful attention in the last few years. 
                Startup Login recently got the opportunity to be a part of an insightful discussion in this regard, at the Eventbrite's Female Leadership & 
                Women in Tech community in Hyderabad. The event was attended by our co-founder <a href="https://www.linkedin.com/in/sunita-kumari007/" target="_blank">Sunita Kumari</a>
                and community manager <a href="https://www.linkedin.com/in/amtulbushra/" target="_blank">Amtul Ahad</a> 
            </div>
            <div class="short-note" *ngIf="blogId === 'e002'">
                Here’s how ‘The Art of Business Valuation for Startups and SMEs’ hosted by <span style="color:blue">Startup Login</span>, in collaboration with eChai Ventures and IBGrid, went!
            </div>
        </div>
        <!-- <div class="right-img">
            <img [src]="details?.img" alt="blog-title" loading="lazy"/>
        </div> -->
    </div>
    <div class="blog-detail-text-part">
        <div class="details-note">
            <div *ngIf="blogId === 'e002'">
                <div class="between-images">
                    <div class="single-image">
                        <img src="./../../../assets/images/campaign-flow/art_of_business_4.png" alt="">
                    </div>
                </div>
                <p>
                    Highly engaging and enlightening, the event equipped the participants with the know-how of reaching the correct evaluating point to assess their business at various stages. 
                    Our panellists Bhavin S Bhagat, Prashanth Gowriraju, Rakesh Gupta and <a href="https://www.linkedin.com/in/sanjayenishetty/" target="_blank">Sanjay Enishetty</a>, delivered a very informative session to guide them.
                </p>
                <div class="between-images">
                    <div class="images">
                        <img src="./../../../assets/images/campaign-flow/art_of_business_1.png" alt="">
                    </div>
                    <div class="images">
                        <img src="./../../../assets/images/campaign-flow/art_of_business_2.png" alt="">
                    </div>
                    <div class="images">
                        <img src="./../../../assets/images/campaign-flow/art_of_business_3.png" alt="">
                    </div>
                </div>
                <p>
                    “We’re the aeroplane with parachute for your growth,” Startup Login, Co-Founder and CEO <a href="https://www.linkedin.com/in/sunita-kumari007/" target="_blank">Sunita Kumari</a> told the participants in regards to Startup Login, an exit-as-a-service platform.
                </p>
            </div>
            <div *ngIf="blogId != 'e002'">
                <div *ngFor="let item of details?.p">
                    <p *ngIf="item?.a">{{item?.a}}</p>
                    <div class="between-title" *ngIf="item?.betweenTitle">{{item?.betweenTitle}}</div>
                    <div *ngIf="item?.ulList">
                        <div class="ul-list" *ngFor="let list of item?.ulList">
                            <ul>
                                <li *ngIf="list?.li">{{list?.li}}</li>
                                <div *ngIf="list?.div" class="sub-title">{{list?.div}}</div>
                                <div *ngIf="list?.b">{{list?.b}}</div>
                            </ul>
                        </div>
                    </div>
                    <div class="arrow-points" *ngIf="item?.ap">
                        &#8594; &nbsp;&nbsp; {{item?.ap}}
                    </div>
                    <div class="between-images" *ngIf="item?.btImgs">
                        <div class="images" *ngFor="let imgs of item?.btImgs"
                        [ngClass]="item?.btImgs.length > 1 ? '' : 'single-image'">
                            <img [src]="imgs?.img" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="main-section">
    
</section>