<app-top-navbar></app-top-navbar>
<div class="main-section">
    <!-- <div class="theme-background-image">
        <img src="./../../../assets/images/blogs/blog-theme-background.png" alt="theme-background" loading="lazy" />
        <div class="theme-text">
            All the important insights, guidance and news you need to know.
        </div>
    </div> -->
    <div class="top-section">
        <img src="./../../../assets/images/blogs/top-section.png" alt="blog-top-section-image" loading="lazy">
    </div>
    <div class="blogs-section">
        <div class="current-blog">
            <div class="current-blog-img">
                <img [src]="latestBlog.imgUrl" [alt]="latestBlog.aboutBlog" loading="lazy"/>
            </div>
            <div class="current-blog-details">
                <div class="details">
                    <div class="about-current-blog">{{latestBlog?.aboutBlog}}</div>
                    <div class="time-read">
                        <div class="time">{{latestBlog?.date}}</div>
                        <div class="vl-line"></div>
                        <div class="read-time">{{latestBlog?.readTime}} Mins Read</div>
                    </div>
                    <div class="blog-title">
                        {{latestBlog?.blogTitle}}
                    </div>
                    <div class="blog-description">
                        {{latestBlog?.shortnote}}
                    </div>
                    <div class="read-more" (click)="gotoBlogDetail(latestBlog.displayId)">
                        Read More &rarr;
                    </div>
                </div>
            </div>
        </div>
        <div class="remaining-blogs">
            <div class="single-remaining-blog" *ngFor="let item of blogs">
                <div class="single-blog-image">
                    <img [src]="item?.imgUrl" [alt]="item?.aboutBlog" loading="lazy"/>
                </div>
                <div class="single-row-blog">
                    <div class="single-about-current-blog">{{item?.aboutBlog}}</div>
                    <div class="single-time-read">
                        <div class="time">{{item?.date}}</div>
                        <div class="vl-line"></div>
                        <div class="read-time">{{item?.readTime}} Mins Read</div>
                    </div>
                </div>
                <div class="single-blog-title">
                    {{item?.blogTitle | titlecase}}
                </div>
                <div class="single-blog-shortNote">
                    {{item?.shortnote | titlecase}}
                </div>
                <div class="read-more-single" (click)="gotoBlogDetail(item.displayId)">Read More &rarr;</div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>