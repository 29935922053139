<ngx-slick-carousel class="carousel" 
#slickModal="slick-carousel" 
[config]="slideConfig" >
<div ngxSlickItem *ngFor="let slide of homeCarousel" class="slide">
    <div class="story" [ngStyle]="{'background-color':slide.backgroundColor}">
        <div class="carousel-left">
            <div *ngIf="slide.status === 1">
                <div class="bold-white-text">{{slide.bigText1}}</div>
                <div class="bold-colored-text">{{slide.bigText2}}</div>
                <div class="small-colored-text">{{slide.subText}}</div>
            </div>
            <div *ngIf="slide.status === 2">
                <div class="bold-secondary-colored-text">{{slide.bigText1}}</div>
                <div class="small-secondary-dark-text">{{slide.subText}}</div>
            </div>
        </div>
        <div class="carousel-right">
            <img [src]="slide.imgUrl" [alt]="slide.bigText1">
        </div>
    </div>
</div>
</ngx-slick-carousel>