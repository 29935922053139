import { DbServiceService } from './db-service.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {

  constructor(
    private dbStorageService: DbServiceService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private router : Router
  ) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const API_KEY = this.dbStorageService.getToken();
      if (API_KEY) {
        return next
          .handle(
            httpRequest.clone({
              setHeaders: { Authorization: `${API_KEY}` },
            })
          )
          .pipe(
            catchError((error: HttpErrorResponse) => {
              let errorMsg = '';
              if (error.error instanceof ErrorEvent) {
                console.log('this is client side error');
                errorMsg = `Error: ${error.error.message}`;
                console.log(
                  `Error: ${error.error.message}, request: ${httpRequest}`
                );
              } else {
                // console.log('this is server side error');
                errorMsg = `Error Code: ${error.status},  Message: ${error.message}, request: ${httpRequest}`;
                // console.log(
                //   `Error Code: ${error.status},  Message: ${error.message}, request: ${httpRequest}`
                // );
                if (error.status === 401) {
                  // this.handle401Error(httpRequest, next);
                  console.log('this is server side error');
                  // alert('Inernal Server Error!');
                  localStorage.clear();
                  this.router.navigate(['/auth']);
                }
                else if(error.status === 403){
                  this._snackBar.open(
                    "You don't have permission to perform this operation",
                    'OK',
                    {
                      duration: 3000,
                    }
                  );
                  // console.log("You don't have permission to perform this operation");
                  // this._snackBar.open(
                  //   "You don't have permission to perform this operation",
                  //   'OK',
                  //   {
                  //     duration: 3000,
                  //   }
                  // );
                }
                else if(error.status === 409){
                  this._snackBar.open(
                    error.error.message,
                    'OK',
                    {
                      duration: 3000,
                    }
                  );
                }
              }
              // console.log('this is server side error');
              // alert('Inernal Server Error!');
              // this._snackBar.open(
              //   'Something Went Wrong, Please Try Again!',
              //   'Close',
              //   {
              //     duration: 3000,
              //   }
              // );
              return throwError(errorMsg);
            })
          );
      } else {
        return next.handle(httpRequest).pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMsg = '';
            if (error.error instanceof ErrorEvent) {
              console.log('this is client side error');
              errorMsg = `Error: ${error.error.message}`;
            } else {
              console.log('this is server side error');
              errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            }
            console.log('this is server side error');
  
            console.log(errorMsg);
            // alert('Inernal Server Error!');
            this._snackBar.open(
              error.error.message,
              'Close',
              {
                duration: 3000,
              }
            );
            return throwError(errorMsg);
          })
        );
      }
  }
}
