<section *ngIf="!getAllLoader">
    <div class="top-navbar" *ngIf="!fromTalenthire">
        <app-top-navbar></app-top-navbar>
    </div>
    <div class="main-section">
        <div class="list-section">
            <div class="filter-part" *ngIf="!fromTalenthire">
                <div class="field">
                    <div class="label-heading">Location</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input type="text"
                            placeholder="Select Location"
                            matInput
                            [formControl]="locationControl"
                            [matAutocomplete]="auto"
                            (keyup)="getLocations()"
                            [(ngModel)]="locationSerach">
                            <mat-icon matSuffix>search</mat-icon>
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of locationOptions | async" [value]="option.cityName" (onSelectionChange)="selectLocation(option)">
                                {{option?.cityName | titlecase}}, {{option?.stateName | titlecase}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="field">
                    <div class="label-heading">Industry</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <mat-select placeholder="Search for industry" [(ngModel)]="industry">
                            <mat-select-filter [placeholder]="'Search For industry'" [displayMember]="'name'" [array]="industriesArray" (filteredReturn)="industryList = $event"></mat-select-filter>
                            <mat-option *ngFor="let item of industryList" [value]="item">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="field">
                    <div class="label-heading">Startup Stage</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <mat-select placeholder="Search for startup stage" [(ngModel)]="startupStage">
                            <mat-option *ngFor="let item of startuptypeList" [value]="item.value">
                                {{item.name | titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="field">
                    <div class="label-heading">Suitable Role</div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <mat-select placeholder="Search for sutable role" [(ngModel)]="role">
                            <mat-select-filter [placeholder]="'Search For suitable role'" [displayMember]="'name'" [array]="suitableRoleArray" (filteredReturn)="suitableRoleList = $event"></mat-select-filter>
                            <mat-option *ngFor="let item of suitableRoleList" [value]="item">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="multiple-btns">
                    <div class="clear-btn">
                        <button (click)="clearFilter()">Clear</button>
                    </div>
                    <div class="apply-btn">
                        <button (click)="applyFilter()"
                        [disabled]="location === '' && industry === null &&  startupStage === null && role === null">
                            <span *ngIf="!filterLoader">Apply</span>
                            <span *ngIf="filterLoader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="list-parts" [ngClass]="!fromTalenthire ? '' : 'get-list-part'">
                <div class="action-headertab" *ngIf="!fromTalenthire">
                    <div class="pagination-container">
                        <div class="pagination-count">
                            {{startingLeadNumber}} - {{endingLeadNumber}} of {{totalDbCount}}
                            <!-- 100001 - 110000 of 300000 -->
                        </div>
                        <div class="arrow-btns">
                            <button class="left-arrow" [disabled]="!activeLeftArrow" (click)="previousPage()">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                            </button>
                            <div class="pagination-input">
                              <mat-form-field class="pagination-sfull-width">
                                <input type="number" matInput [(ngModel)]="goPage" (keyup.enter)="goToPageNumber()"/>
                              </mat-form-field>
                            </div>
                            <button class="righti-arrow" [disabled]="!activeRightArrow" (click)="nextPage()">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                        <div class="pages-count">
                            {{presentPageNo}} of {{totalPages}} Pages
                        </div>
                    </div>
                </div>
                <div class="list-part">
                    <div class="deal-cards" *ngIf="acquihireDeals.length != 0">
                        <div class="card deal-single-card" *ngFor="let deal of acquihireDeals" (click)="dealDescription(deal)">
                            <div class="card-top-sec">
                                <div class="card-left-part">
                                    <div class="profile-img">
                                        <div class="company-prof-img" *ngIf="(deal?.candidateProfileInfo?.profileImg === '' || deal?.candidateProfileInfo?.profileImg ===  null); else orginalImg">
                                            <img src="./../../../assets/images/deal-details/avatar-icon.svg" alt="" >
                                        </div>
                                        <ng-template #orginalImg>
                                            <div class="origina-prof-img">
                                                <img [src]="deal?.candidateProfileInfo?.profileImg" alt="">
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="part-description">
                                        <div class="deal-descrip-heading">
                                            <div class="deal">{{deal.displayId}}</div>
                                        </div>
                                        <div class="location">
                                            <img src="./../../../assets/images/common/web.svg" alt="" class="sub-icon">
                                            <div class="loc-text">{{deal?.candidateProfileInfo?.mobile.mobile}}</div>
                                        </div>
                                        <div class="location">
                                            <img src="./../../../assets/images/common/location.svg" alt="" class="sub-icon">
                                            <div class="loc-text">{{deal?.candidateProfileInfo.addressDetails.city}}
                                                <span *ngIf="deal?.candidateProfileInfo.addressDetails.locality">, {{deal?.candidateProfileInfo.addressDetails.locality | titlecase}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-right-part">
                                    <div class="card-heading">
                                        {{dealName | titlecase}}
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="card-text">
                                    {{deal?.candidateProfileInfo.desc.split(" ").splice(0,40).join(" ")}}
                                    <span *ngIf="deal?.candidateProfileInfo.desc.split(' ').splice(0,40).join(' ').length >= 40">...</span>
                                </div>
                            </div>
                            <div class="card-bottom">
                                <div class="bottom-part">
                                    <img src="./../../../assets/images/common/industry.svg" alt="" class="bottom-img" loading="lazy">
                                    <div class="text-subText">
                                        <div class="bottom-text-heading">Industry</div>
                                        <div class="bottom-subText-heading" *ngIf="deal?.candidateWorkInfo?.previousStartupMarket; else nothing">
                                            {{deal?.candidateWorkInfo?.previousStartupMarket[0]?.name.split('_').join(' ') | titlecase}} ,
                                            <span *ngIf="deal?.candidateWorkInfo?.previousStartupMarket[1]">
                                            {{deal?.candidateWorkInfo?.previousStartupMarket[1]?.name.split('_').join(' ') | titlecase}} ...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-part">
                                    <img src="./../../../assets/images/common/startup-stage.svg" alt="startup-stage" class="bottom-img" loading="lazy">
                                    <div class="text-subText">
                                        <div class="bottom-text-heading">Startup Stage</div>
                                        <div class="bottom-subText-heading">
                                            {{deal?.candidateWorkInfo?.previousStartupStage ? (deal?.candidateWorkInfo?.previousStartupStage.split("_").join(' ') | titlecase) : "N/A"}}
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-part">
                                    <img src="./../../../assets/images/common/experience.svg" alt="Experience" class="bottom-img" loading="lazy">
                                    <div class="text-subText">
                                        <div class="bottom-text-heading">Experience</div>
                                        <div class="bottom-subText-heading">
                                            {{(deal.candidateWorkInfo?.fullTimeFounderExperience)+(deal.candidateWorkInfo?.nonFounderExperience) ? (deal.candidateWorkInfo?.fullTimeFounderExperience)+(deal.candidateWorkInfo?.nonFounderExperience) : 'N/A'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-part">
                                    <img src="./../../../assets/images/common/suitable-industry.svg" alt="Suitable Industry" class="bottom-img" loading="lazy">
                                    <div class="text-subText">
                                        <div class="bottom-text-heading">Suitable Industry</div>
                                        <div class="bottom-subText-heading" *ngIf="deal?.candidateWorkInfo?.interestedMarkets; else nothing">
                                            {{deal?.candidateWorkInfo?.interestedMarkets[0]?.name.split('_').join(' ') | titlecase}} ,
                                            <span *ngIf="deal?.candidateWorkInfo?.interestedMarkets[1]">
                                            {{deal?.candidateWorkInfo.interestedMarkets[1]?.name.split('_').join(' ') | titlecase}} ...</span>
                                        </div>
                                        <ng-template #nothing>
                                            <div class="bottom-subText-heading">
                                                N/A
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="no-data" *ngIf="acquihireDeals.length === 0">
                        <!-- durga.prasad -->
                        <img src="./../../../../assets/images/common/empty-state.svg" alt="empty-state">
                        <div class="no-data-big-text">
                            No Candidates
                        </div>
                        <div class="no-data-small-text">
                            Not created any Candidates yet
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="loader" *ngIf="getAllLoader">
    <div id="divSpinner" class="spinner loading">
      <img src="./../../../../../../../assets/images/common/for-loader.svg" alt="">
   </div>
</div>