import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LetsConnectPopupComponent } from '../../../shared-components/campaign-flow/lets-connect-popup/lets-connect-popup.component';
import { GlobalService } from '../../global-services/global.service';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss','./mq-exit.component.scss']
})
export class ExitComponent implements OnInit {

  chooseTitle1 = 'Why choose'
  secondTitle = 'Startup Login?'
  srcWidth;
  srcHeight;

  constructor(
    private _router : Router,
    private _globalService : GlobalService
  ) { 
    this.getScreenSize()
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.srcWidth);
  }

  ngOnInit(): void {
  }

  exitRewarding : any [] = [
    {
      imgUrl:'./../../../../assets/images/exit/strategic.svg',
      title:'It is Strategic',
      subText:'When business model is failing and product-market fit becomes a concern, a strategic exit can be your next move.'
    },
    {
      imgUrl:'./../../../../assets/images/exit/smart.svg',
      title:'It is Smart',
      subText:'When cash burn continues while there is a significant fund raise fatigue, an exit minimizes risk and loss.'
    },
    {
      imgUrl:'./../../../../assets/images/exit/opportunity.svg',
      title:'There is Opportunity',
      subText:'There are several startups and large corporates ready to acquire good startups.'
    }
  ];

  chooseStartupArray: any[] =[
    {
      imgUrl:'./../../../../assets/images/exit/startup-oriented.svg',
      title:'Coveted Network ',
      subText:'Best opportunities through vast networks'
    },
    {
      imgUrl:'./../../../../assets/images/exit/buyer-outrich.svg',
      title:'Evaluate options',
      subText:'Presented with Data driven options with future action defined'
    },
    {
      imgUrl:'./../../../../assets/images/exit/well-strucured.svg',
      title:'Expert Screening',
      subText:'Goals, Growth and Potential screened by experts who will guide you'
    },
    {
      imgUrl:'./../../../../assets/images/exit/outcome-oriented.svg',
      title:'Outcome Oriented',
      subText:'Win-Win Transactions for both ends'
    }
  ];

  successStories : any [] = [
    {
      number:'01',
      title:'Evaluate and formulate exit plan',
      // subText : 'Evaluate and formulate exit plan'
    },
    {
      number:'02',
      title:'Find the right buyer',
      // subText : 'Find the right buyer'
    },
    {
      number:'03',
      title:'Offers and negotiations',
      // subText : 'Offers and negotiations And Due Diligence'
    },
    {
      number:'04',
      title:'Due Diligence',
      // subText : 'Seal the deal'
    },
    {
      number:'05',
      title:'Seal the deal'
    }
  ];
  
  connectNow(){
    // window.scroll({
    //   top:0,
    //   left:0,
    //   behavior:'smooth'
    // })
    // this._router.navigate(['/contact'])
    if(this.srcWidth > 474){
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        'auto',
        '45%',
        { data:'TALK_TO_EXPERT' }
      )
    }
    else{
      let openPopup = this._globalService.openPopup(
        LetsConnectPopupComponent,
        '99%',
        '99%',
        { data:'TALK_TO_EXPERT' }
      )
    }
  }

}
