import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-startup-tech-team',
  templateUrl: './startup-tech-team.component.html',
  styleUrls: ['./startup-tech-team.component.scss']
})
export class StartupTechTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
