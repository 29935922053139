<div>
    <div class="top-navbar">
        <app-top-navbar></app-top-navbar>
    </div>
    <section>
        <div class="top-section">
            <div class="back-btn cursor-pointer" (click)="backTo()"><mat-icon>keyboard_backspace</mat-icon>&nbsp;Back</div>
            <!-- <div class="edit-delete cursor-pointer">
                <img src="./../../../../../assets/images/acquihire-deal-details/unsaved-flag.svg" alt="save-talenthire"> save 
            </div> -->
        </div>
        <div class="main-section" *ngIf="!getAllLoader">
            <div class="bit-details-section">
                <div class="img-company-details">
                    <div class="company-img" *ngIf="response?.company.profileImg != null && response?.company.profileImg != 'null' ; else noImg">
                        <img [src]="response?.company.profileImg" alt="person-logo">
                    </div>
                    <ng-template #noImg>
                        <div class="no-company-img">
                            <div class="no-img">
                                {{response?.company.name.slice('')[0] | uppercase}}{{response?.company.name.slice('')[1] | uppercase}}
                            </div>
                        </div>
                    </ng-template>
                    <div class="company-details">
                        <div class="company-left-part">
                            <div class="company-title">
                                <img src="./../../../../../../assets/images/acquihire/company-icon.svg" alt="company-icon">
                                <div class="company-text">{{response?.company.name}}</div>
                            </div>
                            <div class="company-mobile">
                                <img src="./../../../../../../assets/images/acquihire/mobile.svg" alt="phone-icon">
                                <div class="company-text">{{response?.company.mobile.mobile}}</div>
                            </div>
                            <div class="company-location">
                                <img src="./../../../../../../assets/images/acquihire/location.svg" alt="location-icon">
                                <div class="location-text">
                                    {{response?.company.location.city}}<span *ngIf="response?.company.location.locality">,{{response?.company.location.locality}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="company-right-part">
                            <!-- <div class="dealid-resume"> -->
                                <div class="card-heading cursor-pointer" (click)="markInterested()">
                                    Mark Interested
                                </div>
                                <div class="download-resume-btn cursor-pointer" (click)="scrollToMembers(teamMemeber)">
                                    <button>Download Resume</button>
                                </div>
                                <div class="save-flag cursor-pointer" (click)="markInterested()">
                                    <img src="./../../../../../assets/images/acquihire-deal-details/unsaved-flag.svg" alt="save-talenthire">
                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
                <div class="founded-size">
                    <div class="year-founded">
                        <img src="./../../../../../../assets/images/acquihire/year-founded.svg" alt="year-founded">
                        <div class="text-value">
                            <div class="founded-text">Year Founded</div>
                            <div class="founded-year">{{response?.company.foundedYear}}</div>
                        </div>
                    </div>
                    <div class="year-founded">
                        <img src="./../../../../../../assets/images/acquihire/team-size.svg" alt="team-size">
                        <div class="text-value">
                            <div class="founded-text">Team Size</div>
                            <div class="founded-year">{{response?.company.teamSize}}</div>
                        </div>
                    </div>
                    <div class="year-founded">
                        <img src="./../../../../../../assets/images/acquihire/founded-by.svg" alt="founded-by">
                        <div class="text-value">
                            <div class="founded-text">Founded by</div>
                            <div class="founded-year">{{response?.company.foundersNames}}</div>
                        </div>
                    </div>
                </div>
                <div class="small-description">
                    {{response?.company.description}}
                </div>
            </div>
            <div class="full-details">
                <div class="left-part">
                    <div class="basic-info-section">
                        <div class="section-title">Basic Information</div>
                        <div class="light-text-value">
                            <div class="light-text-heading">EBITDA</div>
                            <div class="dark-text-value">N/A</div>
                        </div>
                        <!-- <div class="light-text-value">
                            <div class="light-text-heading">Revenue</div>
                            <div class="dark-text-value">FY20-21- 50 Lac</div>
                        </div> -->
                        <div class="light-text-value">
                            <div class="light-text-heading">Deal Type</div>
                            <div class="dark-text-value">{{response?.company.dealType[0].name}}</div>
                        </div>
                        <div class="light-text-value">
                            <div class="light-text-heading">Tech Stack</div>
                            <div class="dark-text-value-array">
                                <div class="items" *ngFor="let item of response?.company.skills">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                        <div class="light-text-value">
                            <div class="light-text-heading">Service Offered</div>
                            <div class="dark-text-value-array">
                                <div class="items" *ngFor="let item of response?.company.services">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="team-info-section" #teamMemeber>
                        <div class="section-title">Team Information</div>
                        <div class="team-members-section" *ngFor="let item of response?.employees; let i = index">
                            <div class="title-download">
                                <div class="source-title">Resource {{i+1}}</div>
                                <div class="download cursor-pointer">
                                    <img src="./../../../../../../assets/images/acquihire/download.svg" alt="down_arrow">
                                    <div class="download-text" (click)="downloadSingleResume()">Download Resume</div>
                                </div>
                            </div>
                            <div class="member-details">
                                <div class="table-row">
                                    <div class="light-text">Name</div>
                                    <div class="dots">:</div>
                                    <div class="dark-text">{{item?.name}}</div>
                                </div>
                                <div class="table-row">
                                    <div class="light-text">Designation</div>
                                    <div class="dots">:</div>
                                    <div class="dark-text">{{item?.designation[0].name}}</div>
                                </div>
                                <div class="table-row">
                                    <div class="light-text">Experience</div>
                                    <div class="dots">:</div>
                                    <div class="dark-text">{{item?.experience}} Years</div>
                                </div>
                                <div class="table-row">
                                    <div class="light-text">Current CTC</div>
                                    <div class="dots">:</div>
                                    <div class="dark-text">{{item?.currentCtc}} LPA</div>
                                </div>
                                <div class="table-row">
                                    <div class="light-text">Expected CTC</div>
                                    <div class="dots">:</div>
                                    <div class="dark-text">{{item?.expectedCtc}} LPA</div>
                                </div>
                                <div class="table-row">
                                    <div class="light-text">Summary</div>
                                    <div class="dots">:</div>
                                    <div class="summary-text">{{item?.summary ? item?.summary : "N/A"}}</div>
                                </div>
                                <div class="table-row-skills">
                                    <div class="light-text">Skills</div>
                                    <div class="dots">:</div>
                                    <div class="dark-text-value-array">
                                        <div class="items" *ngFor="let skill of item?.skills">
                                            {{skill.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-part">
                    <div class="contact-info">
                        <div class="section-title">Contact Information</div>
                        <div class="contact-details">
                            <div class="social-icons">
                                <img src="./../../../../assets/images/acquihire/disabled-twitter.svg" alt="twitter">
                                <img src="./../../../../assets/images/acquihire/disabled-mail.svg" alt="mail">
                                <img src="./../../../../assets/images/acquihire/disabled-linkedin.svg" alt="linkedin">
                                <img src="./../../../../assets/images/acquihire/disabled-call.svg" alt="mobile">
                            </div>
                            <div class="hr-line"></div>
                            <div class="login-scenario">
                                <img src="./../../../../assets/images/acquihire/lock-icon.svg" alt="lock-login">
                                <div class="desc-section">
                                    Login to know more information
                                </div>
                            </div>
                            <div class="login-btn" (click)="gotoLogin()">
                                <button>Login</button>
                            </div>
                        </div>
                    </div>
                    <div class="comments-section">
                        <div class="section-title">Comments</div>
                        <div class="comments">
                            <div class="img-comment" *ngFor="let item of response?.company.comments">
                                <img src="./../../../../../../assets/images/acquihire/colored-circle.svg" alt="colored-circle">
                                <div class="comment">{{item}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loader" *ngIf="getAllLoader">
            <div id="divSpinner" class="spinner loading">
              <img src="./../../../../../../../assets/images/common/for-loader.svg" alt="">
           </div>
        </div>
    </section>
</div>