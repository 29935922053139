import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbServiceService } from '../../main-components/global-services/db-service.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss','./mq-top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  displayBoolean : boolean = false;

  constructor(
    private _router : Router,
    private _dbService : DbServiceService
  ) { 
    let check = this._router.url.split("/");
    if(check[1] === 'authentication'){
      this.displayBoolean = true
    }
    else{
      this.displayBoolean = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

  ngOnInit(): void {
    let currentUrl = this._router.url.split('/').join('');
    this.navItems.forEach(element => {
      if(element.redirectingUrl === currentUrl){
        element.status = true;
      }
      else{
        element.status = false;
      }
    });

    if(this._dbService.getToken() === ''){}
    else{
      this._router.navigate([`/startup-login/customer-portal/talenthire/list-all-deals`])
    }
  }

  service:any []=[
    {
      dropdownText : 'exit',
      redirectingUrl:'solutions-exit'
    },
    {
      dropdownText : 'acquisition',
      redirectingUrl:'solutions-acquisition'
    },
    {
      dropdownText : 'acquihire',
      redirectingUrl:'solutions-acquihire'
    },
    {
      dropdownText : 'startup talent',
      redirectingUrl:'solutions-talenthire'
    }
  ];
  navItems : any [] = [
    {
      name:'about us',
      redirectingUrl : 'about-us',
      status:false
    },
    {
      name:'blog',
      redirectingUrl:'blog',
      status:false
    },
    {
      name:'contact',
      redirectingUrl:'contact',
      status:false
    }
  ]
  redirectUrl(item){
    this.service.forEach(element => {
      if(item?.dropdownText === element.dropdownText){
        this._router.navigate([`/${element.redirectingUrl}`])
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      }
    });
  }
  navItemRedirect(item){
    this.navItems.forEach(element => {
      if(element.name === item.name){
        element.status = true
        this._router.navigate([`/${element.redirectingUrl}`]);
        window.scroll({
          top:0,
          left:0,
          behavior :'smooth'
        });
      }
      else{
        element.status = false;
      }
    });
  }
  goToLoginPage(){
    // this._dbService.setPrevUrl(this._router.url);
    this._router.navigate(['/authentication'])
  }

}
