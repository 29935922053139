<app-top-navbar></app-top-navbar>
<div class="main-section">
    <div class="contact-text-img">
        <img src="./../../../assets/images/contact/contact-home-img.png" alt="">
        <div class="contact-text">
            We are currently engaged with large corporates, <br> listed entities, funded startups and unicorns in <br> the making.
        </div>
    </div>
    <div class="contact-form-text">
        <div class="contact-form-heading">Make your move, and we'll be in touch.</div>
        <div class="contact-form">
            <div class="fields">
                <div class="field">
                    <div class="field-name">Name <sup>*</sup></div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <input matInput placeholder="Enter Your Name" autocomplete="off" [(ngModel)]="name"/>
                    </mat-form-field>
                </div>
                <div class="field">
                    <div class="field-name">Mail Id <sup>*</sup></div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input matInput placeholder="Enter Your Mail" autocomplete="off" [(ngModel)]="email"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="fields">
                <div class="field">
                    <div class="field-name">Phone No.</div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <input matInput placeholder="Enter Phone Number" autocomplete="off" [(ngModel)]="mobile"
                        type="number" maxlength="10"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </mat-form-field>
                </div>
                <div class="field">
                    <div class="field-name">Service you are looking for* <sup>*</sup></div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <mat-label>--Select--</mat-label>
                        <mat-select [(ngModel)]="service">
                          <mat-option *ngFor="let deal of deals" [value]="deal.value">
                            {{deal.name | titlecase}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <!-- <div class="field">
                    <div class="field-name">Business Domain <sup>*</sup></div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <mat-label>--Select--</mat-label>
                        <mat-select>
                          <mat-option *ngFor="let bd of buisinessDomain" [value]="bd.value">
                            {{bd.name | titlecase}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div> -->
            </div>
            <div class="fields">
                <div class="field">
                    <div class="field-name">Message <sup>*</sup></div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <input matInput placeholder="Enter Your Message" autocomplete="off" [(ngModel)]="message"/>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="sendmsg-btn">
            <button [disabled]="name === '' || service === null || email === ''" (click)="sendMessage()">
                <span *ngIf="!loader">Send a message</span>
                <span *ngIf="loader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
            </button>
        </div>
        <div class="reachout-text">Reach out to us on our Social Media as well!</div>
        <div class="social-media">
            <a href="https://www.linkedin.com/company/startuplogin" target="_blank">
                <img src="./../../../assets/images/contact/linked-in.svg" alt="linkedIn">
            </a>
            <a href="https://www.facebook.com/startupexits" target="_blank">
                <img src="./../../../assets/images/contact/facebook.svg" alt="facebook">
            </a>
            <a href="https://www.instagram.com/startuplogin/" class="insta" target="_blank">
                <i class="fa fa-instagram fa-3x" aria-hidden="true"></i>
            </a>
            <a href="https://twitter.com/mystartuplogin" target="_blank">
                <img src="./../../../assets/images/contact/twitter.svg" alt="twitter">
            </a>
        </div>
    </div>
</div>
<app-footer></app-footer>