import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DbServiceService } from '../../global-services/db-service.service';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit {

  @Output() panelValueSelection = new EventEmitter<any>();
  fetchingUrl = ''
  @Output() masterPanelClose = new EventEmitter<any>();

  srcWidth : number;
  srcHeight : number;

  constructor(
    private _router : Router,
    private _dbService : DbServiceService
  ) {  
    this.getScreenSize();
  }
  mainPanel: any =[
    {
      name:'talent hire',
      status : false,
      routingUrl:'talenthire',
      arrowVisibility:true,
      subList:[
        {
          name:'all deals',
          status:false,
          routingUrl:'list-all-deals'
        },
        {
          name:'saved deals',
          status:false,
          routingUrl : 'list-saveddeals'
        },
        {
          name:'interested deals',
          status:false,
          routingUrl : 'list-interestedeals'
        },
        {
          name:'resumes downloaded',
          routingUrl:'list-resumedownloaded',
          status:false
        }
      ]   
    },
    {
      name:'acquihire',
      status:false,
      routingUrl:'acquihire',
      arrowVisibility : true,
      subList:[
        {
          name:'all deals',
          status:false,
          routingUrl:'list-all-deals'
        },
        {
          name:'saved deals',
          status:false,
          routingUrl : 'list-saveddeals'
        },
        {
          name:'interested deals',
          status:false,
          routingUrl : 'list-interestedeals'
        },
        {
          name:'resumes downloaded',
          routingUrl:'list-resumedownloaded',
          status:false
        }
      ]
    }
  ];
  


  ngOnInit(): void {
    let Url = this._router.url;
    let parts = Url.split('/');
    this.mainPanel.forEach(mainList => {
      if(parts[parts.length-2] === mainList.routingUrl || parts[parts.length-4] === mainList.routingUrl){
        mainList.status = true;
        if(mainList.arrowVisibility){
          if(mainList.status){
            mainList.subList.forEach(subList => {
              if(parts[parts.length-1].split('?')[0] === subList.routingUrl || parts[parts.length-3] === subList.routingUrl.replace('list-','')){
                subList.status = true;
              }
              else{
                subList.status = false;
              }
            });
          }
        }
        else{
          // console.log('durga prasad');
          
        }
      }
      else{
        mainList.status = false;
      }
    });
    // this.mainPanel.forEach(list => {
    //   if(parts[parts.length-2] === list.name){
    //     list.status = true
    //     if(list.arrowVisibility){
    //       list?.subList.forEach(element => {
    //         if(parts[parts.length-1].split("-")[1] === element.name){
    //           element.status = true;
    //         }
    //         else{
    //           element.status = false;
    //         }
    //       });
    //     }
    //   }
    // });
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  openList(item,status){
    this.panelValueSelection.emit({value:'master'});
    this.mainPanel.forEach(element => {
      if(element.subList){
        element.subList.forEach(sList => {
          sList.status = false;
        });
      }
      else{
        element.status = false;
      }
    });
    this.mainPanel.forEach(element => {
      if(element?.name === item?.name){
        element.status = true;
        if(element.arrowVisibility){
          if(element.status){
            this._router.navigate([`/startup-login/customer-portal/${element.routingUrl}/${element.subList[0].routingUrl}`],{queryParams:{pageNo:1}});
            element.subList[0].status = true;
          }
        }
        else{
          this._router.navigate([`/startup-login/customer-portal/${element.name}/${element.routingUrl}`],{queryParams:{pageNo:1}})
        }
      }
      else{
        element.status = false
      }
    });
    // console.log(this.mainPanel);
  }
  subListSelection(subList,subListStatus,mainIndex,subIndex){
    this.panelValueSelection.emit({value:'master'});
    this.mainPanel[mainIndex].subList.forEach(element => {
      if(element.name === subList.name){
        element.status = true;
        this._router.navigate([`/startup-login/customer-portal/${this.mainPanel[mainIndex].routingUrl}/${element.routingUrl}`],{queryParams:{pageNo:1}})
      }
      else{
        element.status = false;
      }
    });
  }
  logout(){
    // this._dbService.removeAll();
    // localStorage.clear();
    // this._router.navigate([''])
    window.location.reload();
  }

  goBack(){
    // let url = this._dbService.getPrevUrl();
    // let pathName = new URL(url).pathname;
    // this._router.navigate([`${pathName}`]);
    // this._dbService.setPrevUrl(window.location.href);
  }
  close(){
    this.masterPanelClose.emit(false)
  }
}
