import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalService } from '../global-services/global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss','./mq-home.component.scss']
})
export class HomeComponent implements OnInit {

  panelOpenState : boolean = false;
  email:'';
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  constructor(
    private _router : Router,
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  solutionsArray : any [] = [
    {
      imgUrl:'./../../../assets/images/home/exit-img.svg',
      srNo:1,
      name:'Exit',
      subText:'Roadmap for the best strategy and transition for your business',
      redirectingUrl:'exit'
    },
    {
      srNo:2,
      imgUrl:'./../../../assets/images/home/aquisition-img.svg',
      name:'Acquisition',
      subText:'We identify the right match for your business and strategic plans',
      redirectingUrl:'acquisition'
    },
    {
      srNo:3,
      imgUrl:'./../../../assets/images/home/aquihire-img.svg',
      name:'Acquihire',
      subText:'Leverage from a proven pool of talent through acquiring startup teams',
      redirectingUrl:'acquihire'
    },
    {
      srNo:4,
      imgUrl:'./../../../assets/images/home/talent-hire-img.svg',
      name:'Startup Talent',
      subText:'Talent Acquisition to hire individuals from the startup ecosystem',
      redirectingUrl:'talent-hire'
    }
  ];

  descriptionValues:any[]=[
    {
      value:'Enterprises & Startups are discovering accelerated growth through the startup ecosystem'
    },
    {
      value:'For hypergrowth, large companies are choosing unconventional strategies like acquisitions and acquihire. Startups are the reason for such opportunities.'
    },
    {
      value:'Startups are also using this phenomenal leverage to become a part of a growing company by choosing well timed and rewarding exits.'
    }
  ];

  faq : any [] = [
    {
      question:'What is Startup Exit?',
      answer:[
        {
          heding:'',
          text:'Exit is a strategic plan to sell your entire company including your ownership, product, people and assets to another company.'
        }
      ]
    },
    {
      question:'How long does it take to complete an acquisition?',
      answer:[
        {
          heading:'',
          text:'On an average the complete acquisition process takes from 4-8 weeks of time.'
        }
      ]
    },
    {
      question:'What are the different exit options (available)?',
      answer:[
        {
          heading:'Acquisition',
          text:'Facilitates complete business acquisition, assets & IP and Talent of Startups and Small & Medium Tech enterprises.'
        },
        {
          heading:'Acquihire',
          text:'Acquiring from a startup or IT SME Primarily for its tech team.'
        },
        {
          heading:'Talent Hire',
          text:'Focus on hiring senior level talent for the Position: CTO, CXO, Tech Leadership roles.'
        }
      ]
    },
    {
      question:'How Startup Login experts help in an Exit?',
      answer:[
        {
          heading:'',
          text:'Startup login provides a guided and structured assistance throughout all the Processes.'
        }
      ]
    },
    {
      question:'Why are companies looking to acquire a Startup?',
      answer:[
        {
          heading:'',
          text:'To enhance efficiency by expanding workforce.'
        },
        {
          heading:'',
          text:'To intensify their customer base.'
        },
        {
          heading:'',
          text:'To reduce competition.'
        },
        {
          heading:'',
          text:'To save money, resources and time.'
        }
      ]
    }
  ];

  blogs : any[] = [
    {
      imgUrl:'./../../../assets/images/blogs/blog3-img.png',
      title:'Acquisition',
      date : '09 June 2022',
      readTime : 5,
      blogHeading:'Build-Scale-Exit: Navigating A Entrepreneur’s Startup Journey Strategically',
      displayId:'004'
    },
    {
      imgUrl:'./../../../assets/images/blogs/blog2-img.png',
      title:'Startup',
      date : '21 May 2022',
      readTime : 6,
      blogHeading:'How can startups turn over the economic heatwave?',
      displayId:'003'
    },
    {
      imgUrl:'./../../../assets/images/blogs/blog1-img.png',
      title:'StartUp exits',
      date : '07 July 2022',
      readTime : 6,
      blogHeading:'Your young startup can be worth a fortune!',
      displayId:'002'
    }
  ]

  open(item,i){
    this.faq.forEach((element,index) => {
      if( i === index){
        this.panelOpenState = true;
      }
    });
  }
  close(item,i){
    this.faq.forEach((element,index) => {
      if(i === index){
        this.panelOpenState = false;
      }
    });
  }

  gotoDeal(item){
    this.solutionsArray.forEach(element => {
      if(item?.srNo === element.srNo){
        this._router.navigate([`/solutions-${element?.redirectingUrl.split('-').join('')}`]);
        window.scroll({
          top:0,
          left:0,
          behavior:'smooth'
        })
      }
    });
  }

  gotoBlogDetail(item){
    this._router.navigate([`/blog/b${item}`]);
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    });
  }

  connectNow(){
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    })
    this._router.navigate(['/contact'])
  }
  redirectDealFeeders(){
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    });
    this._router.navigate(['/deal-feeders'])
  }
  redirectLayOff(){
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    });
    this._router.navigate(['/laidoffcareers'])    
  }


  alertMe(){
    if(this.emailRegex.test(this.email)){
      let data ={
        email : this.email
      }
      this._globalService.createNewAlert(data).subscribe(
        (res:any) => {
          this.email = '';
          this._snackbar.open(
            `Thank You For Subscribe`,
            'Ok',
            {
              duration:1500
            }
          )
        },
        (err) => {
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
        }
      )
    }
    else{
      this._snackbar.open(
        `Please Provide Proper Mail Id`,
        'Ok',
        {
          duration:1500
        }
      )
    }
  }

}
