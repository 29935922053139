import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalService } from '../global-services/global.service';

@Component({
  selector: 'app-laid-off-candidate',
  templateUrl: './laid-off-candidate.component.html',
  styleUrls: ['./laid-off-candidate.component.scss']
})
export class LaidOffCandidateComponent implements OnInit {

  name = '';
  email = '';
  role = '';
  currentCtc = '';
  currentLocation = '';
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  checked : boolean = false;
  loader : boolean = false;
  srcHeight : number;
  srcWidth : number;

  constructor(
    private _globalService : GlobalService,
    private _snackbar : MatSnackBar,
    private _router : Router
  ) { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
  }

  checkMe(ev){
    this.checked = ev.checked;    
  }

  sendMessage(){
    if(this.emailRegex.test(this.email)){
      let data ={
        currentCTC : this.currentCtc,
        currentLocation : this.currentLocation,
        email : this.email,
        name : this.name,
        role : this.role
      }
      this.loader = true;
      this._globalService.candidateRegistrationForm(data).subscribe(
        (res:any) => {
          this._snackbar.open(
            `Thank You, Our Team Member Contact You Soon`,
            'Ok',
            {
              duration:1500
            }
          );
          this.email = '';
          this.name = '';
          this.currentCtc = '';
          this.checked = false;
          this.role = '';
          this.currentLocation = '';
          this.loader = false;
          this._router.navigate([''])
        },
        (err) => {
          this.loader = false;
          this._snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          )
        }
      )
    }
    else{
      this._snackbar.open(
        `Please Provide Valid Email Id`,
        'Ok',
        {
          duration:1500
        }
      )
    }
  }

  checksMe(ev){
    this.checked = ev.target.checked
  }

}
