import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DbServiceService } from './db-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
 
  isLoggedIn(){
    return !localStorage.getItem('873743');
  }

  //here temporarly setting staffid for login

  constructor(
    private _router : Router,
    private _dbService : DbServiceService
  ){}

  canActivate(){
    if(this._dbService.getUserId()){
      return true;
    }
    // let redirectUrl = this._dbService.getPrevUrl();
    // this._router.navigate([`list-${redirectUrl[redirectUrl.length-2]}`]);
    this._router.navigate([``]);
    return false;
  }
}
