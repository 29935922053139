import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss','./mq-image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":true,
    "autoplay":true,
    "autoplaySpeed":4000,
    "dots":false,
    "arrows":false,
    "pauseOnHover":false
  };
  homeCarousel : any[] = [
    {
      backgroundColor:'#024751',
      bigText1:'Recognizing best business options for you',
      bigText2:'Startup Specialist',
      subText:'Acquisition, Acquihire, and other Exit Options for startup entrepreneurs to consider from the get-go',
      status:1,
      imgUrl:'./../../../assets/images/carousel/startup-specialist.png'
    },
    {
      backgroundColor:'#E2D8FD',
      bigText1:'Growth Perspective through acquisitions',
      subText:'Access to experts, analytics capital,markets and resources for you to make the right decision! ',
      status:2,
      imgUrl:'./../../../assets/images/carousel/exit.png'
    },
    {
      backgroundColor:'#FEEDAE',
      bigText1:'Redefining success through Acquihire',
      subText:'Talent, Tech and Timing taken care with acquihire options for enterprises- curated by industry experts',
      status:2,
      imgUrl:'./../../../assets/images/carousel/acquisition.png'
    },
    {
      backgroundColor:'#E2D8FD',
      bigText1:'Identifying smart opportunities for Entrepreneurs',
      subText:'Identifying opportunities and facilitating smart and strategic deals and offers for entrepreneurs',
      status:2,
      imgUrl:'./../../../assets/images/carousel/acquihiring.png'
    },
    {
      backgroundColor:'#FEEDAE',
      bigText1:'Growth, Expansion, Technology and Deals',
      subText:'India’s business landscape has become more conducive to entrepreneurship. Explore our offerings now',
      imgUrl:'./../../../assets/images/carousel/growth.svg',
      status:2
    }
  ]

}
