import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss','./mq-blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  constructor(
    private _router : Router
  ) { }
  latestBlog : any = {};  

  ngOnInit(): void {
    this.latestBlog = this.blogs.reverse()[0];
    this.blogs.shift();
  }

  blogs : any [] = [
    // {
    //   imgUrl:'./../../../assets/images/blogs/blog4-img.png',
    //   aboutBlog:'Unicorn',
    //   date:'12 May 2022',
    //   readTime:'3',
    //   blogTitle:'Unicorns beat the lions in acquisition race',
    //   shortnote:"India's top unicorns seem to have championed the acquisition game. In an attempt to scale up rapidly in a short period of time, they have left traditional tech companies far behind in the game of acquisition."
    // },
    {
      imgUrl:'./../../../assets/images/blogs/blog3-img.png',
      aboutBlog:'Acquisition',
      date:'09 June 2022',
      readTime:'5',
      blogTitle:'Build-Scale-Exit: Navigating A Entrepreneur’s Startup Journey Strategically',
      shortnote:"Every entrepreneur has a story to tell, about how much effort it takes to build startups, make sure they’re running well and plan suitable business exit strategies, all by studying what the market requires. Here’s an outline of an entrepreneur's journey, from planting the seed of innovation to achieving profits till business exit.",
      displayId:'004'
    },
    {
      imgUrl:'./../../../assets/images/blogs/blog2-img.png',
      aboutBlog:'Startup',
      date:'21 May 2022',
      readTime:'6',
      blogTitle:'How can startups turn over the economic heatwave?',
      shortnote:"The Indian startup ecosystem is at a bizarre juncture, emerging as the third largest in the world after the US and China, while simultaneously grappling with a funding winter. Can it come out unscathed, holding on to its newfound place in the global startup map?",
      displayId:'003'
    },
    {
      imgUrl:'./../../../assets/images/blogs/blog1-img.png',
      aboutBlog:'StartUp exits',
      date:'07 July 2022',
      readTime:'6',
      blogTitle:'Your young startup can be worth a fortune!',
      shortnote:"It is possible that anybody can give life to a well intended idea. But this is, by no means, a guarantee of agility. Hence, it is important to make sure that the foundation is strong with no loopholes, and the market is studied well. Here’s how you can make sure that your startup is always acquisition worthy.",
      displayId:'002'
    },
    {
      imgUrl:'./../../../assets/images/blogs/blog-img.png',
      aboutBlog:'Acquisition',
      date:'23 June 2022',
      readTime:'5',
      blogTitle:'How can big companies take cues from startups to rekindle their inner innovator?',
      shortnote:'When there is an absence of creativity, there is always a lack of energy within any organisation. Creating an environment to awaken the innovative sleeping child within is thus mandatory and startups have been doing this since a while. Now, there are indications that big players in the market too are endorsing these startup practices to oil their wheels.',
      displayId:'001'
    }
  ];

  gotoBlogDetail(item){
      this._router.navigate([`/blog/b${item}`]);
      window.scroll({
        top:0,
        left:0,
        behavior:'smooth'
      });
  }
}
