import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ThankYouComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _snackbar : MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close({data:null})
  }

}
