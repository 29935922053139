<section>
    <!-- <div class="deal-heading">Best Deals For Your Success</div> -->
    <div class="deal-cards">
            <div class="card deal-single-card" *ngFor="let deal of bestDeals" (click)="dealDescription(deal)">
                <div class="card-top-sec">
                    <div class="card-left-part">
                        <div class="profile-img">
                            <div class="company-prof-img" *ngIf="deal.profImg === ''; else orginalImg">
                                <img src="./../../../assets/images/deal-details/avatar-icon.svg" alt="" >
                            </div>
                            <ng-template #orginalImg>
                                <div class="origina-prof-img" *ngIf="deal.profImg">
                                    <img [src]="deal.profImg" alt="">
                                </div>
                            </ng-template>
                        </div>
                        <div class="part-description">
                            <div class="deal-descrip-heading">
                                <div class="deal">{{deal.postTitle}}</div>
                                <div class="deal-role" *ngIf="deal.cardHeading === 'ACQUISITION'">Development</div>
                            </div>
                            <div class="location" *ngIf="deal?.cardHeading=== 'TALENTHIRE'">
                                <img src="./../../../assets/images/common/web.svg" alt="" class="sub-icon">
                                <div class="loc-text">99xxxxxx98</div>
                            </div>
                            <div class="location">
                                <img src="./../../../assets/images/common/location.svg" alt="" class="sub-icon">
                                <div class="loc-text">Hyderabad, Telangana</div>
                            </div>
                            <div class="location" *ngIf="deal?.cardHeading=== 'ACQUIHIRE'">
                                <div class="technologies" *ngFor="let item of technologies">
                                    {{item}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-right-part">
                        <div class="card-heading">
                            {{deal?.cardHeading.split('-').join(' ') | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="card-text">{{deal?.cardText | titlecase}}</div>
                </div>
                <!-- <div class="card-bottom" *ngIf="deal?.cardHeading === 'EXIT'">
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/industry.svg" alt="" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Industry</div>
                            <div class="bottom-subText-heading">Consumer, Fintech</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/startup-stage.svg" alt="startup-stage" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Startup Stage</div>
                            <div class="bottom-subText-heading">Seed/Angle</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/experience.svg" alt="Experience" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Experience</div>
                            <div class="bottom-subText-heading">10</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/suitable-industry.svg" alt="Suitable Industry" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Suitable Industry</div>
                            <div class="bottom-subText-heading">Growth, Operations</div>
                        </div>
                    </div>
                </div> -->
                <div class="card-bottom" *ngIf="deal?.cardHeading === 'TALENTHIRE'">
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/industry.svg" alt="" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Industry</div>
                            <div class="bottom-subText-heading">Consumer, Fintech</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/startup-stage.svg" alt="startup-stage" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Startup Stage</div>
                            <div class="bottom-subText-heading">Seed/Angle</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/experience.svg" alt="Experience" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Experience</div>
                            <div class="bottom-subText-heading">10</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/suitable-industry.svg" alt="Suitable Industry" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Suitable Industry</div>
                            <div class="bottom-subText-heading">Growth, Operations</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="card-bottom" *ngIf="deal?.cardHeading === 'ACQUISITION'">
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/gross-revenue.svg" alt="" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Gross Revenue</div>
                            <div class="bottom-subText-heading">&#36; 128 K</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/net-profit.svg" alt="startup-stage" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Net Profit</div>
                            <div class="bottom-subText-heading">&#36; 89.04 K</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/team-size.svg" alt="Experience" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Team Size</div>
                            <div class="bottom-subText-heading">10</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/customers.svg" alt="Suitable Industry" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Customers</div>
                            <div class="bottom-subText-heading">200 - 500</div>
                        </div>
                    </div>
                </div> -->
                <div class="card-bottom" *ngIf="deal?.cardHeading=== 'ACQUIHIRE'">
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/gross-revenue.svg" alt="" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Revenue</div>
                            <div class="bottom-subText-heading">₹ 50 Lac</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/net-profit.svg" alt="startup-stage" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">EBITDA</div>
                            <div class="bottom-subText-heading">20 - 30%</div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <img src="./../../../assets/images/common/team-size.svg" alt="Experience" class="bottom-img" loading="lazy">
                        <div class="text-subText">
                            <div class="bottom-text-heading">Team Size</div>
                            <div class="bottom-subText-heading">10</div>
                        </div>
                    </div>
                </div>
              </div>
    </div>
</section>
<!-- <section>
    <div class="view-more-btn" *ngIf="currentUrl != ''">
        <button>View More Deals</button>
    </div>
</section> -->