<section>
    <div class="heading-cross">
        <div class="sec-heading">Make your move, and we'll get in touch.</div>
        <div class="cross-icon">
            <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="fields">
        <div class="field">
            <div class="label-heading">Name<sup>*</sup></div>
            <mat-form-field class="form-full-width" appearance="outline">
                <input matInput placeholder="Enter Employee Name" [(ngModel)]="name"/>
            </mat-form-field>
        </div>
        <div class="field">
            <div class="label-heading">Startup/Company Name<sup>*</sup></div>
            <mat-form-field class="form-full-width" appearance="outline">
                <!-- <mat-label>--Select--</mat-label>
                <mat-select>
                  <mat-option *ngFor="let item of companyDetails" [value]="item.value">
                    {{item.name}}
                  </mat-option>
                </mat-select> -->
                <input matInput placeholder="Enter Company/Startup Name" [(ngModel)]="companyName"/>
            </mat-form-field>
        </div>
        <div class="field">
            <div class="label-heading">Mail Id<sup>*</sup></div>
            <mat-form-field class="form-full-width" appearance="outline">
                <input matInput placeholder="Enter Mail Id" [(ngModel)]="mailId"/>
            </mat-form-field>
        </div>
        <div class="field">
            <div class="label-heading">Phone Number</div>
            <mat-form-field class="form-full-width" appearance="outline">
                <input matInput placeholder="Enter Phone Number"
                type="number"
                maxlength="10"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                 [(ngModel)]="phoneNumber"/>
            </mat-form-field>
        </div>
        <div class="large-field">
            <div class="label-heading">Anything else you want us to know?</div>
            <textarea rows="5" style="resize:none;width: 100%;" [(ngModel)]="extra"></textarea>
        </div>
        <div class="checkbox">
            <mat-checkbox class="example-margin" [(ngModel)]="newsAlert">I would like to subscribe to the monthly newsletter</mat-checkbox>
        </div>
    </div>
    <div class="submit-btn">
        <button (click)="submit()"
        [disabled]="name === '' || companyName === '' || mailId === ''">
            <!-- Submit -->
            <span *ngIf="!loader">Submit</span>
            <span *ngIf="loader"><i class="fa fa-spinner fa-spin"></i>&nbsp; loading</span>
        </button>
    </div>
</section>
