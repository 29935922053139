<app-top-navbar></app-top-navbar>
    <div class="main-section">
        <section class="image-carousel">
            <app-image-carousel></app-image-carousel>
        </section>

        <!-- professional and specialization -->

        <section class="professional-specialization">
            <div class="professional-left">
                <img src="./../../../assets/images/home/professional-img.png" alt="">
            </div>
            <div class="professional-right">
                <div class="professional-data">
                    <div class="small-lightBlack-text">Who we are</div>
                    <!-- <div class="big-colored-text">Team Of Professionals Who Specialize in Acquisition And Acquihire And Exits For Technology Startups</div> -->
                    <div class="sub-lightBlack-text">
                        With a growth mindset at the centre of everything we do, StartupLogin is here to facilitate business innovation between startups and enterprises. Our expert insights help companies make informed decisions while catering to all the stakeholders involved
                    </div>
                    <div class="sub-lightBlack-text">
                        Actionable intelligence, trend tracking, analytics and insights driven deals overseen by our team of experts. We are first and foremost human driven, and foster innovation and dreams.
                    </div>
                </div>
            </div>
        </section>

        <!-- our solutions  -->

        <section class="our-solutions">
            <div class="black-600-text">Our Solutions</div>
            <div class="big-colored-text color-heading">
                Set A Success Growth With Our Ultimate Solutions 
            </div>
            <div class="solutions">
                <div class="single-solution" *ngFor="let item of solutionsArray" (click)="gotoDeal(item)">
                    <div class="solution-img">
                        <img [src]="item.imgUrl" [alt]="item?.name" loading="lazy">
                    </div>
                    <div class="solution-colored-text">{{item?.name}}</div>
                    <div class="solution-sub-text">
                        {{item?.subText | titlecase}}
                    </div>
                    <div class="know-about-solution cursor-pointer">
                        Learn More
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </section>

        <!-- best deals -->
        <!-- <section class="best-deals">
            <app-best-deals></app-best-deals>
        </section> -->

        <!-- description -->
        <section class="description">
            <div class="description-heading">
                Our Metrics
            </div>
            <!-- <div class="description-img-text" *ngFor="let value of descriptionValues">
                <div class="description-img">
                    <img src="./../../../assets/images/home/description-dot.svg" alt="">
                </div>
                <div class="description-text">
                    {{value?.value | titlecase}}
                </div>
            </div> -->
            <section class="extensions">
                <div class="established-dates">
                    <div class="img-text">
                        <div class="target-img">
                            <img src="./../../../assets/images/about/first.png" alt="target" loading="lazy"/>
                        </div>
                        <div class="target-text">
                            <div class="text1">Access to</div>
                            <div class="text2">250+ Startups</div>
                        </div>
                    </div>
                    <div class="img-text">
                        <div class="target-img2">
                            <img src="./../../../assets/images/about/second.png" alt="target" loading="lazy"/>
                        </div>
                        <div class="target-text">
                            <div class="text1">Offer roll out time</div>
                            <div class="text2">Max. 3 weeks</div>
                        </div>
                    </div>
                    <div class="img-text">
                        <div class="target-img">
                            <img src="./../../../assets/images/about/third.png" alt="target" loading="lazy"/>
                        </div>
                        <div class="target-text">
                            <div class="text1">Startups added every week</div>
                            <div class="text2">10+</div>
                        </div>
                    </div>
                    <div class="img-text">
                        <div class="target-img">
                            <img src="./../../../assets/images/about/fourth.png" alt="target" loading="lazy"/>
                        </div>
                        <div class="target-text">
                            <div class="text1">Leaders evaluating Tech & Teams</div>
                            <div class="text2">40+</div>
                        </div>
                    </div>
                    <div class="img-text">
                        <div class="target-img">
                            <img src="./../../../assets/images/about/fifth.png" alt="target" loading="lazy"/>
                        </div>
                        <div class="target-text">
                            <div class="text1">Tech products on sale</div>
                            <div class="text2">250+</div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- <div class="description-colored-text">Sectors Catered To:</div>
            <div class="description-colored-card">
                <div class="description-white-text">
                    <div class="list1">
                        <div class="sub">Consumer Tech</div>
                        <div class="sub">IT/ITES</div>
                        <div class="sub">Adtech</div>
                        <div class="sub">Retail Tech</div>
                        <div class="sub">EcommerceTech</div>
                        <div class="sub">EPR</div>
                        <div class="sub">Martech</div>
                        <div class="sub">CS & CX</div>
                        <div class="sub">F&B</div>
                        <div class="sub">Manuf. Tech</div>
                        <div class="sub">HRTech</div>
                        <div class="sub">Enterprise SaaS</div>
                        <div class="sub">Fitness Tech</div>
                        <div class="sub">Defence Tech</div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="technologies">Technologies :</div>
            <div class="list-technologies">
                <div class="tech-text-color">
                    <div class="list2">
                        <div class="sub2">BI/DA/DS/Big Data Companies</div>
                        <div class="sub2">Compter Vistion/AI/ML Companies</div>
                        <div class="sub2">IT Services</div>
                        <div class="sub2">Block Chain</div>
                        <div class="sub2">Fintech</div>
                        <div class="sub2">Edtech</div>
                        <div class="sub2">Event Services</div>
                        <div class="sub2">JAVA</div>
                        <div class="sub2">PYTHON</div>
                        <div class="sub2">CSS</div>
                        <div class="sub2">HTML</div>
                        <div class="sub2">Node.js</div>
                        <div class="sub2">Angular.js</div>
                        <div class="sub2">Express.js</div>
                        <div class="sub2">Mongo DB</div>
                        <div class="sub2">Ruby</div>
                        <div class="sub2">Rust</div>
                        <div class="sub2">Lua</div>
                        <div class="sub2">Groovy</div>
                        <div class="sub2">MATLAB</div>
                        <div class="sub2">Delphi</div>
                        <div class="sub2">SQL</div>
                        <div class="sub2">Visual Basic .NET</div>
                        <div class="sub2">Kotlin</div>
                        <div class="sub2">Haskell</div>
                        <div class="sub2">Perl</div>
                        <div class="sub2">Powershell</div>
                        <div class="sub2">Shell</div>
                        <div class="sub2">Scala</div>
                        <div class="sub2">Type Script</div>
                        <div class="sub2">PHP</div>
                        <div class="sub2">Golang (GO)</div>
                        <div class="sub2">R</div>
                        <div class="sub2">C#</div>
                        <div class="sub2">Swift</div>
                        <div class="sub2">Java Script</div>
                    </div>
                </div>
            </div> -->
        </section>

        <!-- result-oriented -->

        <section class="result-oriented">
            <div class="result-oriented-heading">
                Our Process Is Simple, Fast And <br> Result-Oriented
            </div>
            <div class="orientation-image-text">
                <div class="startup-focused">
                    <img src="./../../../assets/images/home/startup-focused1.svg" alt="">
                </div>
            </div>
        </section>
        <div class="connect-now">
            <img src="./../../../assets/images/home/connect-now.png" alt="connect-now" loading="lazy"/>
            <div class="connect-text-btn">
                <div class="connect-text">
                    Ready to meet our experts?
                </div>
                <div class="connect-btn">
                    <button class="cursor-pointer" (click)="connectNow()">Connect Now</button>
                </div>
            </div>
        </div>

        <!-- best strategy -->
        <section class="best-strategy">
            <div class="best-strategy-left">
                <div class="strategy-colored-heading">StartupLogin Can Help Build <br> The Best Strategy For You</div>
                <div class="strategy-sub-text">Be it an Acquisition, Acquihire or even an Exit</div>
                <!-- <div class="learn-more">
                    <button class="cursor-pointer">Learn More</button>
                </div> -->
            </div>
            <div class="best-strategy-right">
                <img src="./../../../assets/images/home/strategy.svg" alt="">
            </div>
        </section>

        <!-- faq section -->
        <section class="faq-section">
            <div class="faq-heading">Frequently Asked Question</div>
            <div class="question-answers">
                <mat-accordion>
                    <div *ngFor="let item of faq; let i =  index">
                        <mat-expansion-panel (opened)="open(item,i)"
                        (closed)="close(item,i)" class="expansion-panel">
                            <mat-expansion-panel-header class="faq-header">
                                <mat-panel-title>
                                    {{item?.question}}
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="showing-answer">
                                    <ul>
                                        <li *ngFor="let ans of item.answer">
                                           <span class="li-heading" *ngIf="ans?.heading">{{ans.heading}}:</span>
                                            &nbsp;<span class="li-answer">{{ans.text}}</span>
                                        </li>
                                    </ul>
                                </div>
                        </mat-expansion-panel>
                    </div>
                    </mat-accordion>
            </div>
        </section>
        <div class="accelerate-growth">
            <img src="./../../../assets/images/home/accelerate-growth.png" alt="connect-now" loading="lazy"/>
            <div class="growth-text-btn">
                <div class="growth-text">
                    Make Your Move Now! Meet Our Experts <br> And Accelerate Your Growth
                </div>
                <div class="connect-now-btn">
                    <button class="cursor-pointer" (click)="connectNow()">Connect Now</button>
                </div>
            </div>
        </div>

        <section class="extra-deal-feeder">
            <div class="deal-feeders">
                <div class="feeder-heading">Be a part of our DealFeeder community</div>
                <div class="know-more-btn">
                    <button (click)="redirectDealFeeders()">Know More</button>
                </div>
            </div>
            <div class="deal-feeders">
                <div class="feeder-heading">Be a part of our LaidOff <br> community</div>
                <div class="know-more-btn">
                    <button (click)="redirectLayOff()">Know More</button>
                </div>
            </div>
            <div class="deal-feeders">
                <div class="subscribe-box">
                    <div class="subscribe-heading">Join our list for growth insights and deal showcase for new startups every week</div>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Your Mail Here" aria-label="your mail here" aria-describedby="basic-addon2"
                        [(ngModel)]="email">
                        <div class="input-group-append" (click)="alertMe()">
                          <span class="input-group-text" id="basic-addon2">Subscribe</span>
                        </div>
                      </div>
                </div>
            </div>
        </section>

        <!-- blog -->
        <section class="our-blogs">
            <div class="blogs-title">Our Blog</div>
            <div class="blogs">
                <div class="single-blog" *ngFor="let blog of blogs">
                    <div class="blog-image">
                        <img [src]="blog.imgUrl" alt="">
                    </div>
                    <div class="about-blog">
                        {{blog?.title}}
                    </div>
                    <div class="date-read">
                        <div class="blog-date">{{blog?.date}}</div>
                        <div class="vl-line"></div>
                        <div class="read-time">{{blog?.readTime}} Min Read</div>
                    </div>
                    <div class="blog-heading">{{blog?.blogHeading}}</div>
                    <div class="read-more-single" (click)="gotoBlogDetail(blog.displayId)">
                        Read More &rarr;
                    </div>
                </div>
            </div>
        </section>
    </div>
<app-footer></app-footer>